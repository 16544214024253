<template>
  <vx-card class="insert-cash-box top-zero-radius" :class="{'in-modal w-full': inModal, 'md:w-1/2 main-box': !inModal}">
    <vs-input class="w-full"
              :label="`${$t('treasury.cashBoxes.labels.name')}`"
              @keydown.enter="sendData"
              v-model="new_cash_box.name"/>

    <vs-button id="insertCashBoxBTN"
               class="useral-action-button"
               @click="sendData()"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import {insertCashBoxes} from '@/http/requests/cashBoxes'

export default {
  name: 'insertCashBox',
  metaInfo () {
    return {
      title: this.$t('treasury.cashBoxes.insert.title')
    }
  },
  props: {
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      new_cash_box: {
        name: ''
      },
      actions: [
        {
          toolbar: [
            {
              id: 'insertCashBoxBTN',
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'treasuryCashBoxes'},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    if (!this.inModal) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      }, 50)
    }
  },
  methods: {
    sendData () {
      insertCashBoxes(this.new_cash_box).then(response => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.cashBoxes.notifications.insert.success'),
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather',
          color: 'success'
        })

        this.$store.dispatch('removePageChanges')
        if (!this.inModal) {
          this.$router.push({name: 'treasuryCashBoxes'})
        } else {
          this.$emit('inserted')
        }
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cashBoxes.notifications.insert.error'),
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      })
    }
  },
  watch: {
    new_cash_box: {
      handler (val, oldVal) {
        this.$store.dispatch('setPageChanges')
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  .insert-cash-box {
    &.in-modal {
      box-shadow: none;

      .vx-card__body {
        padding: 0.5rem;
      }
    }
  }
</style>
