<template>
  <div class="character-profile">
    <profile-main-box :profile="getCharacterProfile"
                      :actionsList="actionsList"
                      @showCharacterUsersList="$emit('showUsersList')"/>
  </div>
</template>

<script>
  import ProfileMainBox from "../../../../components/profileMainBox/profileMainBox2"
  export default {
    name: "characterProfile",
    components: {ProfileMainBox},
    props: {
      selectedCharacter: {}
    },
    data () {
      return {
        term: null,
        actionsList: [
          {
            event: 'showCharacterUsersList',
            header: this.$t('characters.profile.actions.usersList', {name: this.selectedCharacter.name}),
            // preview: {
            //     value: addComma(25000) + ' تومان',
            //     color: 'success'
            // },
            i18n: '',
            icon: 'icon-user',
            iconPack: 'feather'
          },
        ]
      }
    },
    computed: {
      getCharacterProfile () {
        const character = {
          avatar: require('@/assets/images/unnamed.png'),
          name: this.selectedCharacter.name,
          asiderAvatar: [
            {
              content: `${this.selectedCharacter.name}`
            },
          ],
          details: [],
          importantData: []
        }

        return character
      }
    },
  }
</script>

<style scoped>

</style>
