import moment from 'moment-jalaali'
import store from '../../store/store'
import axios from "@/http/axios/index"
import timezonesData from './timezones'
import {getServerTime} from "../../http/requests/setting";

export function getAvatarUrl(url) {
  return axios.defaults.baseURL + '/v1' + url + '?t=0.001'
}

export function getAttachUrl(url) {
  return axios.defaults.baseURL + '/v1' + url
}

export function getStaticFileUrl(url) {
  const baseUrl = axios.defaults.baseURL.split('/api')[0]
  return baseUrl + '/statics' + url + '?t=0.001'
}

export function customParseFloat(value, digits = 4) {
  return parseFloat(parseFloat(value).toFixed(digits))
}

export function showLoading () {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.classList.remove('in-active')
    appLoading.style.display = 'block'

    setTimeout(() => {
      appLoading.classList.add('active')
    }, 10)
  }
}

export function hideLoading () {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.classList.remove('active')
    appLoading.classList.add('in-active')

    setTimeout(() => {
      appLoading.style.display = 'none'
    }, 500)
  }
}

export function convertPersianNumberToEnglish (str) {
  let persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g]
  let arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]

  if (typeof str === 'string') {
    for (let i = 0; i < 10; i++) {
      str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i)
    }
  }
  return str
}

export function formatCurrency(initialvalue, digitNum, blur) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // get input value
  var value = initialvalue.toString();

  // don't validate empty input
  if (value === "") { return "" }

  // original length
  var original_len = value.length;

  // initial caret position
  // var caret_pos = input.prop("selectionStart");

  // check for decimal
  if (value.indexOf(".") >= 0 && digitNum > 0) {

    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = value.indexOf(".");

    // split number by decimal point
    var left_side = value.substring(0, decimal_pos);
    var right_side = value.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side, false);

    // On blur make sure 2 numbers after decimal
    if (blur) {
      for (let i = 0; i < digitNum; i++) {
        right_side += "0";
      }
    }

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, digitNum);

    // join number by .
    value = (left_side || '0') + "." + right_side;

  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    value = customParseFloat(value.toString().split(',').join(''), digitNum)
    value = formatNumber(value);

    // final formatting
    if (blur && digitNum > 0) {
      value = (value || '0').toString() + '.'
      for (let i = 0; i < digitNum; i++) {
        value += "0";
      }
    }
  }

  // send updated string to input
  return value;

  // put caret back in the right position
  // var updated_len = input_val.length;
  // caret_pos = updated_len - original_len + caret_pos;
  // input[0].setSelectionRange(caret_pos, caret_pos);
}

export function addComma (val, blur = false) {
  let price = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: (store.state.setting.clubInfo.club_currency_code || 'IRT').toUpperCase().replace('IRT', 'IRR'),
    currencyDisplay: "code"
  }).formatToParts(0).filter(x => x.type !== "currency")
    .map(x => x.value)
    .join("")
    .trim()
  if (['IRR', 'IRT'].indexOf((store.state.setting.clubInfo.club_currency_code || 'IRT').toUpperCase()) > -1) {
    price = parseInt(price)
  }
  const decimalPos = price.toString().indexOf('.')
  let decimalNum = 0
  if (decimalPos > -1 && !price.toString().endsWith('.')) {
    decimalNum = price.toString().split('.')[1].length
  } else {
    // return val
  }
  return formatCurrency(val.toString(), decimalNum, blur)
}

export function formatNumber (val, hasComma = true) {
  let sign = ''

  if (val < 0)
    sign = '-'

  val = val.toString()

  if (val[0] === '00')
    val = val.substr(1, val.length - 1)

  if (hasComma) {
    if (document.dir === 'rtl') {
      return val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
    } else {
      return sign + val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  } else {
    if (document.dir === 'rtl') {
      return val.toString().replace(/\D/g, '') + sign
    } else {
      return sign + val.toString().replace(/\D/g, '')
    }
  }
}

export function countDecimal (value) {
  if(Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0;
}

export function addSpace (val, limit) {

  val = val.toString()

  if (val[0] === '0')
    val = val.substr(1, val.length - 1)

  if (limit && val.length > limit)
    val = val.substr(0, limit)

  val = val.toString().replace(/\D/g, '')


  let arr = []
  if (val.substr(0, 4) !== undefined)
    arr.push(val.substr(0, 4))

  if (val.substr(4, 4) !== undefined)
    arr.push(val.substr(4, 4))

  if (val.substr(8, 4) !== undefined)
    arr.push(val.substr(8, 4))

  if (val.substr(12, 4) !== undefined)
    arr.push(val.substr(12, 4))

  let copy = ''
  arr.forEach((elm) => {
    if (elm) {
      if (copy)
        copy += ' '

      copy += elm
    }
  })
  val = copy

  return val
}

export function checkDateValue(str, max) {
  if (str.charAt(0) !== '0' || str == '00') {
    let num = parseInt(str)
    if (isNaN(num) || num <= 0 || num > max) num = 1
    str = num > parseInt(max.toString().charAt(0))
    && num.toString().length === 1 ? '0' + num : num.toString()
  }
  return str;
}

export function checkTimeValue(str, max) {
  if (str.charAt(0) !== '0') {
    let num = parseInt(str)
    if (isNaN(num) || num <= 0 || num > max) num = 1
    str = num > parseInt(max.toString().charAt(0))
    && num.toString().length === 1 ? '0' + num : num.toString()
  }
  return str;
}

export function addDateSlash(value) {
  // this.type = 'text';
  let input = value
  if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3)
  let values = input.split('/').map(function(v) {
    return v.replace(/\D/g, '')
  })
  if (values[0]) values[0] = checkDateValue(values[0], 3000)
  if (values[1]) values[1] = checkDateValue(values[1], 12)
  if (values[2]) values[2] = checkDateValue(values[2], 31)
  let output = values.map(function(v, i) {
    if (v.length === 4 && i === 0) {
      return v + ' / '
    }
    return v.length === 2 && i > 0 ? v + ' / ' : v;
  })
  value = output.join('').substr(0, 14);

  return value
}

export function addTimeColon(value) {
  // this.type = 'text';
  let input = value;
  if (/\D\:$/.test(input)) input = input.substr(0, input.length - 3)
  let values = input.split(':').map(function(v) {
    return v.replace(/\D/g, '')
  })
  if (values[0]) values[0] = checkTimeValue(values[0], 23)
  if (values[1]) values[1] = checkTimeValue(values[1], 59)
  let output = values.map(function(v, i) {
    return v.length === 2 && i < 1 ? v + ' : ' : v
  })
  value = output.join('').substr(0, 7)

  return value
}

export function addDateTimeSlash(value) {
  // this.type = 'text';
  let input = value
  if (/\D\-$/.test(input)) input = input.substr(0, input.length - 3)
  let values = input.split( ' - ')
  if (values[0]) values[0] = addDateSlash(values[0])
  if (values[1]) values[1] = addTimeColon(values[1])
  let output = values.map(function(v, i) {
    if (v.length === 14 && i === 0) {
      return v + ' - '
    } else {
      return v;
    }
  })
  value = output.join('').substr(0, 24);

  return value
}

/* check permissions */

export function checkUserPermissions (permission, type) {
  let permissions = store.state.auth.user.permissions

  if (Array.isArray(permission)) {
    let hasPermissions = 0
    // let permissions = JSON.parse(localStorage.getItem('permissions'))
    permission.forEach((permission_item) => {
      if (permission_item.startsWith('!')) {
        if (permissions.indexOf(permission_item.substring(1)) === -1) {
          hasPermissions++
        }
      } else if (permissions.indexOf(permission_item) > -1) {
        hasPermissions++
      }
    })
    switch (type) {
      case 'any':
        return hasPermissions === permission.length ? 2 : hasPermissions > 0 ? 1 : 0

      case 'all':
        return hasPermissions === permission.length ? 1 : 0

      default:
        return hasPermissions === permission.length ? 2 : hasPermissions > 0 ? 1 : 0
    }

  } else {
    // let permissions = JSON.parse(localStorage.getItem('permissions'))
    if (permission.startsWith('!')) {
      return permissions.indexOf(permission.substring(1)) === -1
    } else {
      return permissions.indexOf(permission) > -1
    }
  }
}

/* /check permissions */

/* quick sort algorithm */

function swap (items, leftIndex, rightIndex) {
  var temp = items[leftIndex]
  items[leftIndex] = items[rightIndex]
  items[rightIndex] = temp
}

function partition (items, field, left, right) {
  var pivot = items[Math.floor((right + left) / 2)], //middle element
    i = left, //left pointer
    j = right //right pointer
  while (i <= j) {
    while (items[i][field] < pivot[field]) {
      i++
    }
    while (items[j][field] > pivot[field]) {
      j--
    }
    if (i <= j) {
      swap(items, i, j) //sawpping two elements
      i++
      j--
    }
  }
  return i
}

export function quickSort (items, field, left, right) {
  var index
  if (items.length > 1) {
    index = partition(items, field, left, right) //index returned from partition
    if (left < index - 1) { //more elements on the left side of the pivot
      quickSort(items, field, left, index - 1)
    }
    if (index < right) { //more elements on the right side of the pivot
      quickSort(items, field, index, right)
    }
  }
  return items
}

/* /quick sort algorithm */


/* return false if request was sent to server */
export function requestExist(funcName) {
  // console.log(store.state.helper.runningFunctions)
  if (store.state.helper.requestStatus.indexOf(funcName) === -1) {
    store.dispatch('helper/changeRequestStatus', funcName)
    return false
  } else {
    // console.log(store.state.helper.runningFunctions.indexOf(funcName))
    return true
  }
}

export function getTimeFromServer() {
  let time = getServerTime()
  const timezone = timezonesData.data().locales['IRI'].timezone
  return moment(time).utc().utcOffset(timezone).format('YYYY-MM-DD HH:mm:ss')
}
