import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getDocuments (type = '', page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  let $endPoint = ''
  switch (type) {
  case 'receive':
    $endPoint = '/receive'
    break

  case 'payment':
    $endPoint = '/payment'
    break

  case 'cost':
    $endPoint = '/cost'
    break

  case 'internal-funds-transfer':
    $endPoint = '/internal-fund'
    break
  }

  return axios({
    url: `v1/admin/documents${$endPoint}${queries}`,
    method: 'get'
  })
}

export function printReceive ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/documents/receive?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getReceive (id) {

  return axios({
    url: `v1/admin/documents/receive/${id}`,
    method: 'get'
  })
}

export function confirmReceive (id, payment) {

  return axios({
    url: `v1/admin/documents/receive/${id}/confirm`,
    data: payment,
    method: 'post'
  })
}

export function insertReceive (payment) {

  return axios({
    url: 'v1/admin/documents/receive',
    data: payment,
    method: 'post'
  })
}

export function getPayment (id) {

  return axios({
    url: `v1/admin/documents/payment/${id}`,
    method: 'get'
  })
}

export function printPayment ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/documents/payment?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function insertPayment (payment) {

  return axios({
    url: 'v1/admin/documents/payment',
    data: payment,
    method: 'post'
  })
}

export function getCost (id) {

  return axios({
    url: `v1/admin/documents/cost/${id}`,
    method: 'get'
  })
}

export function printCosts ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/documents/cost?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getCostTypes () {

  return axios({
    url: 'v1/admin/documents/cost-types',
    method: 'get'
  })
}

export function insertCost (payment) {

  return axios({
    url: 'v1/admin/documents/cost',
    data: payment,
    method: 'post'
  })
}

export function getInternalFundsTransfer (id) {

  return axios({
    url: `v1/admin/documents/internal-fund/${id}`,
    method: 'get'
  })
}

export function printInternalFundsTransfer ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/documents/internal-fund?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function insertInternalFundsTransfer (payment) {

  return axios({
    url: 'v1/admin/documents/internal-fund',
    data: payment,
    method: 'post'
  })
}

export function getDocument (id) {

  return axios({
    url: `v1/admin/documents/${id}`,
    method: 'get'
  })
}

export function getInternalTransferPrice (filters = []) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/documents/total-internal-fund${queries}`,
    method: 'get'
  })
}

export function getPaymentPrice (filters = []) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/documents/total-payment${queries}`,
    method: 'get'
  })
}

export function getReceivePrice (filters = []) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/documents/total-receive${queries}`,
    method: 'get'
  })
}

export function getCostPrice (filters = []) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/documents/total-cost${queries}`,
    method: 'get'
  })
}

export function deleteDocument (id, type) {
  let $endPoint = ''
  switch (type) {
    case 'receive':
      $endPoint = '/receive'
      break

    case 'payment':
      $endPoint = '/payment'
      break

    case 'cost':
      $endPoint = '/cost'
      break

    case 'internal-funds-transfer':
      $endPoint = '/internal-fund'
      break
  }
  return axios({
    url: `v1/admin/documents${$endPoint}/${id}`,
    method: 'delete'
  })
}

export function getDocumentTransactions (id, page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/documents/${id}/transactions${queries}`,
    method: 'get'
  })
}
