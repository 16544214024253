import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getEvents (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/events${queries}`,
    method: 'get'
  })
}

export function getEvent (id) {

  return axios({
    url: `v1/events/${id}`,
    method: 'get'
  })
}

export function insertEvent (payment) {

  return axios({
    url: 'v1/admin/events',
    data: payment,
    method: 'post'
  })
}

export function seenEvent (id, subscriber_id) {

  return axios({
    url: `v1/events/${id}/subscribers/${subscriber_id}/seen`,
    method: 'post'
  })
}
