/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  /* event changed */
  CHANGE_EVENT (state) {
    state.eventsChanged = true
  },
  REMOVE_CHANGE_EVENT (state) {
    state.eventsChanged = false
  },

  /* category changed */
  CHANGE_CATEGORY (state) {
    state.categoriesChanged = true
  },
  REMOVE_CHANGE_CATEGORY (state) {
    state.categoriesChanged = false
  },

  /* attribute changed */
  CHANGE_ATTRIBUTE (state) {
    state.attributesChanged = true
  },
  REMOVE_CHANGE_ATTRIBUTE (state) {
    state.attributesChanged = false
  },

  /* attribute values changed */
  CHANGE_ATTRIBUTE_VALUES (state) {
    state.attributeValuesChanged = true
  },
  REMOVE_CHANGE_ATTRIBUTE_VALUES (state) {
    state.attributeValuesChanged = false
  },

  /* product changed */
  CHANGE_PRODUCT (state) {
    state.productsChanged = true
  },
  REMOVE_CHANGE_PRODUCT (state) {
    state.productsChanged = false
  },

  /* account changed */
  CHANGE_ACCOUNT (state) {
    state.accountsChanged = true
  },
  REMOVE_CHANGE_ACCOUNT (state) {
    state.accountsChanged = false
  },

  /* document changed */
  CHANGE_DOCUMENT (state) {
    state.documentsChanged = true
  },
  REMOVE_CHANGE_DOCUMENT (state) {
    state.documentsChanged = false
  },

  /* user changed */
  CHANGE_USER (state) {
    state.usersChanged = true
  },
  REMOVE_CHANGE_USER (state) {
    state.usersChanged = false
  },

  /* user address changed */
  CHANGE_USER_ADDRESS (state, payload) {
    state.userAddressesChanged = payload
  },

  /* roles list changed */
  CHANGE_ROLE (state) {
    state.rolesChanged = true
  },
  REMOVE_CHANGE_ROLE (state) {
    state.rolesChanged = false
  },

  /* payment gateways changed */
  CHANGE_PAYMENT_GATEWAY (state) {
    state.paymentGatewaysChanged = true
  },
  REMOVE_CHANGE_PAYMENT_GATEWAY (state) {
    state.paymentGatewaysChanged = false
  },

  /* wooreceiver payment gateways changed */
  CHANGE_WOORECEIVER_PAYMENT_GATEWAY (state) {
    state.wooreceiverPaymentGatewaysChanged = true
  },
  REMOVE_CHANGE_WOORECEIVER_PAYMENT_GATEWAY (state) {
    state.wooreceiverPaymentGatewaysChanged = false
  },

  /* opening balance locked */
  OPENING_BALANCE_LOCKED (state) {
    state.openingBalanceLocked = true
  },
  OPENING_BALANCE_UNLOCKED (state) {
    state.openingBalanceLocked = false
  },

  /* opening balance changed */
  CHANGE_OPENING_BALANCE (state) {
    state.openingBalanceChanged = true
  },
  REMOVE_CHANGE_OPENING_BALANCE (state) {
    state.openingBalanceChanged = false
  },

  /* opening balance deleted */
  DELETE_OPENING_BALANCE (state) {
    state.openingBalanceDeleted = true
  },
  REMOVE_DELETE_OPENING_BALANCE (state) {
    state.openingBalanceDeleted = false
  },

  /* wooreceiver products changed */
  CHANGE_WOORECEIVER_PRODUCTS (state) {
    state.wooreceiverProductsChanged = true
  },
  REMOVE_WOORECEIVER_PRODUCTS (state) {
    state.wooreceiverProductsChanged = false
  },

  /* wooreceiver users changed */
  CHANGE_WOORECEIVER_USERS (state) {
    state.wooreceiverUsersChanged = true
  },
  REMOVE_WOORECEIVER_USERS (state) {
    state.wooreceiverUsersChanged = false
  },

  /* error occurred */
  ERROR_OCCURRED (state) {
    state.errorOccurred = true
  },
  REMOVE_ERROR_OCCURRED (state) {
    state.errorOccurred = false
  },


  /* change request status */
  CHANGE_REQUEST_STATUS (state, payload) {
    if (state.requestStatus.indexOf(payload) > -1) {
      let func_index = state.requestStatus.indexOf(payload)
      state.requestStatus.splice(func_index, 1)
    } else {
      state.requestStatus.push(payload)
    }
  },

  /* change calendar date of navbar */
  CHANGE_CALENDAR_DATE (state, payload) {
    state.calendarDate = payload
  }
}
