/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/http/axios/index.js'

export default {
  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO (state, payload) {
    state.user = payload
  },

  // remove user info from local storage
  REMOVE_USER_INFO (state) {
    state.user = {}
  },

  // store access token in local storage
  SET_ACCESS_TOKEN (state, payload) {
    localStorage.setItem('accessToken', payload.token)
    localStorage.setItem('expired_at', payload.expired_at)
    // window.Echo.connector.pusher.config.auth.headers['Authorization'] = `Bearer ${payload.token}`;
  },

  // remove access token from local storage
  REMOVE_ACCESS_TOKEN () {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('expired_at')
  },

  SET_BEARER (state, accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }
}
