import axios from '@/http/axios'
import store from '../../../store/store'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

/* Profile */
export function getProfile () {
  return axios({
    url: 'v1/admin/profile',
    method: 'get'
  })
}

export function editProfile (payment) {
  return axios.patch('v1/profile', payment)
}

export function getProfileInvoices (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/profile/sale-invoices${queries}`,
    method: 'get'
  })
}
export function getProfileInvoicesPrice (filters = []) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/profile/total-sale-invoices${queries}`,
    method: 'get'
  })
}

export function getProfileReceivedEvents (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios.get(`v1/admin/profile/received-events${queries}`)
}

export function getProfileSentEvents (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios.get(`v1/admin/profile/sent-events${queries}`)
}

export function editProfileBrowserSettings () {
  const setting = {
      tables: store.state.table.tables,
      // tables: store.state.table.tables.storesListTable ? store.state.table.tables.storesListTable : [],
      theme: store.state.theme
  }
  delete setting.tables.tables
  delete setting.tables.theme
  return axios.patch('v1/admin/profile/browser-settings', { settings: JSON.stringify(setting) })
}

/* Users */

export function getUsers (page, filters = [], sorts = []) {
  let queries = ''
  if (page > 1) {
    queries += `page=${page}`
  }

  if (filters.length) {
    if (queries.length > 0) queries += '&'

    queries += filters.join('&')
  }

  if (sorts.length) {
    if (queries.length > 0) queries += '&'

    queries += sorts.join('&')
  }

  if (queries.length > 0) queries = `?${  queries}`

  return axios({
    url: `v1/admin/users${queries}`,
    method: 'get'
  })
}

export function getUsersTrash (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/users?trashed=true${queries}`,
    method: 'get'
  })
}

export function getUser (id) {
  return axios({
    url: `v1/admin/users/${id}`,
    method: 'get'
  })
}

export function getUserSaleAddons (id) {
  return axios({
    url: `v1/admin/users/${id}/active-sale-addons`,
    method: 'get'
  })
}

export function getUserBrowserSettings (id) {
  return axios({
    url: `v1/admin/users/${id}/browser-settings`,
    method: 'get'
  })
}

export function insertUserBrowserSettings (setting) {
  const userId = setting.id
  delete setting.id

  return axios({
    url: `v1/admin/users/${userId}/browser-settings`,
    data: setting,
    method: 'post'
  })
}

export function editUserBrowserSettings (setting) {
  const userId = setting.id
  delete setting.id

  return axios.patch(`v1/admin/users/${userId}/browser-settings`, setting)
}

export function getUserActivitiesLog (id) {
  return axios({
    url: `v1/admin/users/${id}/activity-log`,
    method: 'get'
  })
}

export function insertUser (payment) {
  return axios({
    url: 'v1/admin/users',
    data: payment,
    method: 'post'
  })
}

export function editUser (id, payment) {
  return axios.patch(`v1/admin/users/${id}`, payment)
}

export function deleteUser (id, force = false) {
  return axios({
    url: `v1/admin/users/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreUser (id) {
  return axios({
    url: `v1/admin/users/${id}/restore`,
    method: 'put'
  })
}

export function receiveFromUser (payment) {
  return axios({
    url: `v1/admin/users/${payment.id}/receive`,
    data: payment,
    method: 'post'
  })
}

export function paymentToUser (payment) {
  return axios({
    url: `v1/admin/users/${payment.id}/payment`,
    data: payment,
    method: 'post'
  })
}

export function getUserTransaction (userId, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${userId}/transactions${queries}`,
    method: 'get'
  })
}

export function printUserTransactions (userId, filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${userId}/transactions?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getUserInvoices (userId, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${userId}/sale-invoices${queries}`,
    method: 'get'
  })
}
export function getUserInvoicesPrice (userId, filters = []) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${userId}/total-sale-invoices${queries}`,
    method: 'get'
  })
}

export function getUserSeasonSchedules (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${id}/season-schedules${queries}`,
    method: 'get'
  })
}

export function getUserClasses (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${id}/classes${queries}`,
    method: 'get'
  })
}
export function getUserClassesPrice (id, filters = []) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${id}/total-classes${queries}`,
    method: 'get'
  })
}

/* User Addresses */

export function getUserAddresses (userId) {
  return axios({
    url: `v1/admin/users/${userId}/addresses`,
    method: 'get'
  })
}

export function insertUserAddress (userId, address) {
  return axios({
    url: `v1/admin/users/${userId}/addresses`,
    data: address,
    method: 'post'
  })
}

export function editUserAddress (userId, addressId, address) {
  return axios.patch(`v1/admin/users/${userId}/addresses/${addressId}`, address)
}

export function deleteUserAddress (userId, addressId) {
  return axios({
    url: `v1/admin/users/${userId}/addresses/${addressId}`,
    method: 'delete'
  })
}

/* User Groups */

export function getUserGroups (page = 1) {
  return axios({
    url: `v1/admin/user-groups?page=${page}`,
    method: 'get'
  })
}

export function getUserGroup (id) {
  return axios({
    url: `v1/admin/user-groups/${id}`,
    method: 'get'
  })
}

export function insertUserGroup (payment) {
  return axios({
    url: 'v1/admin/user-groups',
    data: payment,
    method: 'post'
  })
}

export function editUserGroup (payment) {
  return axios.patch(`v1/admin/user-groups/${payment.id}`, payment)
}

export function deleteUserGroup (id) {
  return axios({
    url: `v1/admin/user-groups/${id}`,
    method: 'delete'
  })
}


// avatars
export function setAvatar(data) {
  return axios({
    url: 'v1/upload-avatar',
    data,
    method: 'post'
  })
}
