<template>
  <div class="user-max-debt">
    <custom-price-input :label="$t('users.user.labels.maxDebt')"
                        :is-empty="true"
                        :has-comma="true"
                        :auto-focus="true"
                        :classes="{'w-full': true}"
                        v-model="maxDebt"
                        @pressEnter="sendData"/>

    <button v-show="false" id="setUserMaxDebtBTN" @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomNumberInput from '../../../../components/customInput/customNumberInput'
  import {editUser} from '../../../../http/requests/users/users'
  import {checkUserPermissions} from "../../../../assets/js/functions";
  import CustomPriceInput from "../../../../components/customInput/customPriceInput";

  export default {
    name: 'setUserMaxDebt',
    components: {CustomPriceInput, CustomNumberInput},
    props: {
      userId: 0,
      userMaxDebt: 0
    },
    data() {
      return {
        maxDebt: {}
      }
    },
    created() {
      this.maxDebt = {
        value: this.userMaxDebt,
        isValid: true
      }
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      sendData() {
        if (this.checkUserPermissions('user.set_max_debt')) {
          const user = {
            max_debt: parseInt(this.maxDebt.value.toString().replaceAll(',', '')) || 0
          }
          editUser(this.userId, user).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('users.user.notifications.edit.success'),
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400,
              color: 'success'
            })

            this.$emit('setMaxDebt')
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('users.user.notifications.edit.error'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'danger'
              })
            }
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('users.user.notifications.noAccessMaxDebt'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
        }
      }
    }
  }
</script>

<style scoped>

</style>
