<template>
  <div class="cash-boxes-opening-balance">
    <draggable-dynamic-table ref="cashBoxesOpeningBalanceList"
                             :in-modal="true"
                             :fix-screen="true"
                             :active-grid="true"
                             :options="options"
                             :columns="columnsLabel"
                             @row:clicked="handleRowClicked($event)"
                             @load:more="getCashBoxesOpeningBalance"
                             v-model="data"/>

    <!-- insert cash boxes opening balance prompt -->
    <vs-prompt
      class="big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertOpeningInventoryPromptStatus"
      @close="insertOpeningInventoryPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('saveBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.cashBoxes.openingBalance.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertOpeningInventoryPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <insert-cash-boxes-opening-balance/>
      </div>

    </vs-prompt>

    <!-- edit cash boxes opening balance prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editOpeningInventoryPromptStatus"
      @close="editOpeningInventoryPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('saveBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.cashBoxes.openingBalance.edit.title', {cashBox: selectedCashBox.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editOpeningInventoryPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <edit-cash-boxes-opening-balance :cash-box="selectedCashBox"/>
      </div>

    </vs-prompt>

    <button id="InsertOpeningInventoryBTN"
            @click="insertOpeningInventoryPromptStatus = true"
            v-show="false"/>
  </div>
</template>

<script>
import axios from 'axios'
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {
  getCashBoxesOpeningInventories
} from '@/http/requests/openingInventories'
import {addComma} from '@/assets/js/functions'
import SelectCashBoxes from '@/views/admin/treasury/cashBoxes/select/selectCashBoxes'
import InsertCashBox from '@/views/admin/treasury/cashBoxes/insert/insertCashBox'
import InsertCashBoxesOpeningBalance from './insertCashBoxesOpeningBalance'
import {
  deleteCashBoxesOpeningInventories,
  getBankOpeningInventories, getCashBoxOpeningInventories
} from '../../../../../http/requests/openingInventories'
import EditCashBoxesOpeningBalance from './editCashBoxesOpeningBalance'
import CustomIcon from '../../../../../components/customIcon/customIcon'

export default {
  name: 'cashBoxesOpeningBalance',
  components: {
    CustomIcon,
    EditCashBoxesOpeningBalance,
    InsertCashBoxesOpeningBalance,
    InsertCashBox,
    SelectCashBoxes,
    DraggableDynamicTable},
  data () {
    return {
      requestSent: false,
      insertOpeningInventoryPromptStatus: false,
      editOpeningInventoryPromptStatus: false,
      page: 1,
      endedList: false,
      loadingTimer: 0,
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        /*{
          field: 'delete',
          headerName: 'حذف',
          width: '70px',
          minWidth: 70,
          actions: true,
          showAction: 'always',
          action: {
            color: 'danger',
            icon: 'icon-trash',
            iconPack: 'feather',
            type: 'button'
          },
          event: 'row:deleted'
        },*/
        {
          field: 'price',
          i18n: 'treasury.cashBoxes.openingBalance.table.header.price',
          width: 'calc(100% / 2)',
          minWidth: 200,
          align: 'center',
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'name',
          i18n: 'treasury.cashBoxes.openingBalance.table.header.name',
          align: 'center',
          width: 'calc(100% / 2)',
          minWidth: 200
        },
        {
          field: 'rowNumber',
          i18n: 'treasury.cashBoxes.openingBalance.table.header.row',
          align: 'center',
          width: '70px',
          minWidth: 70,
          footer: {}
        }
      ],
      data: [],
      selectedCashBox: {}
    }
  },
  created () {
    this.getCashBoxesOpeningBalance()
    this.checkOpeningBalanceStatus()
  },
  methods: {
    getCashBoxesOpeningBalance () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.cashBoxesOpeningBalanceList && this.data.length === 0) this.$refs.cashBoxesOpeningBalanceList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.cashBoxesOpeningBalanceList && this.data.length > 0) this.$refs.cashBoxesOpeningBalanceList.loadMoreStatus = 'Loading'

            getCashBoxesOpeningInventories(this.page).then(response => {
              const cashBoxes = response.data.data

              this.total_count = response.data.pagination.total

              cashBoxes.forEach((cashBox) => {
                this.data.push({
                  rowNumber: this.total_count - this.data.length,
                  id: cashBox.id,
                  name: cashBox.name,
                  price: addComma(cashBox.price),
                  flag: 'SAVED'
                })
              })
              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
              }

              if (this.$refs.cashBoxesOpeningBalanceList) this.$refs.cashBoxesOpeningBalanceList.loadMoreStatus = ''

              this.requestSent = false
            }).catch((error) => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {
                if (this.$refs.cashBoxesOpeningBalanceList) this.$refs.cashBoxesOpeningBalanceList.loadMoreStatus = 'Danger'

                this.requestSent = false
              }
            })
          } else {
            this.requestSent = false
          }
        }, 400)
      }
    },
    handleDeleteRow (row) {
      if (row) {
        const index = this.data.map((elm) => {
          return elm.id
        }).indexOf(row.id)
        if (index > -1 && row.id !== 0) {
          deleteCashBoxesOpeningInventories(row.id).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('treasury.cashBoxes.openingBalance.notifications.delete.success'),
              color: 'success',
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather'
            })

            this.data.splice(index, 1)
            this.$store.dispatch('helper/changeOpeningBalance')
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('treasury.cashBoxes.openingBalance.notifications.delete.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
        }
      }
    },
    handleRowClicked (row) {
      if (!this.$store.state.helper.openingBalanceLocked) {
        this.selectedCashBox = row
        this.editOpeningInventoryPromptStatus = true
      }
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    checkOpeningBalanceStatus () {
      if (this.$store.state.helper.openingBalanceLocked) {
        const mapper = this.columnsLabel.map(elm => { return elm.field })

        const name_index = mapper.indexOf('name')
        this.columnsLabel[name_index].editable = false

        const price_index = mapper.indexOf('price')
        this.columnsLabel[price_index].editable = false

        const action_index = mapper.indexOf('delete')
        this.columnsLabel.splice(action_index, 1)
      }
    },
    refreshCashBoxDetails () {
      getCashBoxOpeningInventories(this.selectedCashBox.id).then(response => {
        const cashBox = response.data.data
        const cashBox_index = this.data.map(elm => { return elm.id }).indexOf(cashBox.id)
        if (cashBox_index >= 0) {
          this.data[cashBox_index] = {
            rowNumber: this.data[cashBox_index].rowNumber,
            id: cashBox.id,
            name: cashBox.name,
            price: addComma(cashBox.price),
            flag: 'SAVED'
          }

          if (this.$refs.cashBoxesOpeningBalanceList) this.$refs.cashBoxesOpeningBalanceList.setTableData()
        }
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    deleteCashBoxDetails () {
      const cashBox_index = this.data.map(elm => { return elm.id }).indexOf(this.selectedCashBox.id)
      if (cashBox_index >= 0) this.data.splice(cashBox_index, 1)

      getCashBoxesOpeningInventories(1).then(response => {
        if (response.data.pagination.current_page === 1) {
          const row_index = this.columnsLabel.map((e) => {
            return e.field
          }).indexOf('rowNumber')
          this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
        }
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })

      if (this.$refs.cashBoxesOpeningBalanceList) this.$refs.cashBoxesOpeningBalanceList.setTableData()
    }
  },
  watch: {
    '$store.state.helper.openingBalanceChanged': {
      handler (val) {
        if (val) {
          if (this.insertOpeningInventoryPromptStatus) {
            this.page = 1
            this.data = []
            this.getCashBoxesOpeningBalance()
          }

          if (this.editOpeningInventoryPromptStatus && this.selectedCashBox) this.refreshCashBoxDetails()

          this.insertOpeningInventoryPromptStatus = false
          this.editOpeningInventoryPromptStatus = false
        }
      },
      deep: true
    },
    '$store.state.helper.openingBalanceDeleted': {
      handler (val) {
        if (val) {
          if (this.editOpeningInventoryPromptStatus && this.selectedCashBox) this.deleteCashBoxDetails()
          this.editOpeningInventoryPromptStatus = false
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.cash-boxes-opening-balance {
  height: 100%;
}
</style>
