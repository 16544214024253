<template>
  <vx-card class="user-profile-page"
           :class="{'main-box top-zero-radius md:w-1/2': !userId, 'no-box-shadow': userId > 0}"
           v-if="user && Object.keys(user).length > 0">

    <profile-main-box :profile="getUserProfile"
                      :actionsList="actionsList"
                      @showUserAddresses="showUserAddressesPromptStatus = true"
                      @showUserTransactions="showUserTransactionsPromptStatus = true"
                      @showUserInvoices="showUserInvoicesPromptStatus = true"
                      @receiveMoneyFromCustomer="receiveFromCustomerPromptStatus = true"
                      @setUserMaxDebt="setUserMaxDebtPromptStatus = true"
                      @coachClasses="showCoachClassesListPromptStatus = true"/>



    <!-- show user transactions prompt -->
    <vs-prompt
            class="very-big-prompt p-0"
            :buttons-hidden="true"
            title=""
            :active.sync="showUserTransactionsPromptStatus"
            @close="showUserTransactionsPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <!--            <div @click="editUserPromptStatus = true">
                          <custom-icon icon="EDIT" color="warning"/>
                        </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.transactions.dynamicTitle', {name: `${user.general.gender === 1 ? $t('users.genderTypes.dynamicMan') : $t('users.genderTypes.woman')} ${user.general.name.value} ${user.general.lastName.value}`}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer flex">
<!--            <div class="mx-2" @click="handleClick('printUserTransactions')">-->
<!--              <custom-icon icon="PRINT"/>-->
<!--            </div>-->
            <div @click="showUserTransactionsPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <user-transactions :user-id="userId || $route.params.id"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!-- show user invoices prompt -->
    <vs-prompt
            class="very-big-prompt p-0"
            :buttons-hidden="true"
            title=""
            :active.sync="showUserInvoicesPromptStatus"
            @close="showUserInvoicesPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <!--            <div @click="editUserPromptStatus = true">
                          <custom-icon icon="EDIT" color="warning"/>
                        </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.invoices.dynamicTitle', {name: `${user.general.gender === 1 ? $t('users.genderTypes.dynamicMan') : $t('users.genderTypes.woman')} ${user.general.name.value} ${user.general.lastName.value}`}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showUserInvoicesPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <user-invoices-list :user-id="userId"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!-- set user max debt prompt -->
    <vs-prompt
            class="prompt p-0"
            :buttons-hidden="true"
            title=""
            :active.sync="setUserMaxDebtPromptStatus"
            @close="setUserMaxDebtPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('setUserMaxDebtBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.user.maxDebt.dynamicTitle', {name: `${user.general.gender === 1 ? $t('users.genderTypes.dynamicMan') : $t('users.genderTypes.woman')} ${user.general.name.value} ${user.general.lastName.value}`}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="setUserMaxDebtPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-3">
        <template>
          <keep-alive>
            <set-user-max-debt :user-id="userId || $route.params.id" :user-max-debt="user.financial.maxDebt" @setMaxDebt="setUserMaxDebtPromptStatus = false, getUser(), $emit('edited')"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- receive from customer -->
    <vs-prompt
            color="danger"
            :buttons-hidden="true"
            title="دریافت وجه از مشتری"
            :active.sync="receiveFromCustomerPromptStatus"
            @close="receiveFromCustomerPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <!--          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                      <div @click="handleClick('fastReceiveBTN')">
                        <custom-icon icon="CHECK" color="success"/>
                      </div>
                    </vs-col>-->

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.receive.insert.dynamicTitle', {name: `${user.general.name.value} ${user.general.lastName.value}`}) }}
          </vs-col>

          <vs-spacer/>

          <!--          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                      <router-link :to="{name: 'saleInvoice', params: {id: this.$route.params.id}}">
                        <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                      </router-link>
                    </vs-col>-->
        </vs-row>
      </div>

      <div class="prompt-content p-3">
        <insert-fast-receive :user="{id: userId, name: {value: `${user.general.name.value} ${user.general.lastName.value}`}}"
                              :user-transactions="getUserTransactions"/>

        <div class="action-buttons">
          <button class="payment" @click="handleClick('fastReceiveBTN')">{{ $t('users.user.labels.receive') }}</button>

          <button @click="receiveFromCustomerPromptStatus = false" class="cancel">{{ $t('users.user.labels.cancel') }}</button>
        </div>
      </div>
    </vs-prompt>
    <!-- /receive from customer -->


    <!--  show coach classes list prompt  -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showCoachClassesListPromptStatus"
      @close="showCoachClassesListPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('coaches.classes.dynamicTitle', {name: getUserProfile.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showCoachClassesListPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <coach-classes-list :coach-id="userId"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>
  </vx-card>
</template>

<script>
import {getUser} from '@/http/requests/users/users'
import ProfileMainBox from '@/components/profileMainBox/profileMainBox2'
import {addComma, checkUserPermissions} from '@/assets/js/functions'
import CustomIcon from '../../../../components/customIcon/customIcon'
import {getAvatarUrl, getStaticFileUrl} from "../../../../assets/js/functions";
import InsertFastReceive from "../../treasury/receive/insert/insertFastReceive";
import UserTransactions from "../transactions/userTransactions";
import SetUserMaxDebt from '../maxDebt/setUserMaxDebt'
import UserInvoicesList from "../invoices/userInvoices";
import CoachClassesList from "../classes/coachClassesList";

export default {
  name: 'userProfile',
  components: {
    CoachClassesList,
    UserInvoicesList, UserTransactions, SetUserMaxDebt, InsertFastReceive, CustomIcon, ProfileMainBox},
  props: {
    userId: 0
  },
  metaInfo () {
    return {
      title: this.user ? this.$t('users.user.profile.dynamicTitle', {name: `${this.user.general.name.value} ${this.user.general.lastName.value}`}) : this.$t('users.user.profile.title')
    }
  },
  data () {
    return {
      setUserMaxDebtPromptStatus: false,
      showUserAddressesPromptStatus: false,
      showUserTransactionsPromptStatus: false,
      showUserInvoicesPromptStatus: false,
      receiveFromCustomerPromptStatus: false,
      showCoachClassesListPromptStatus: false,
      actions: {
        toolbar: [
          {
            id: {name: 'editUser', params: {id: this.$route.params.id}},
            type: 'link',
            icon: 'icon-edit-2',
            iconPack: 'feather',
            color: 'warning'
          }
        ],
        leftToolbar: [
          {
            id: {name: 'users'},
            type: 'link',
            icon: 'icon-chevron-left',
            iconPack: 'feather'
          }
        ]
      },
      user: null,
      actionsList: [
        {
          event: 'showUserTransactions',
          params: {id: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.transactions'),
          preview: {
            value: addComma(25000) + this.$t('currency'),
            color: 'success'
          },
          i18n: '',
          icon: 'icon-layers',
          iconPack: 'feather'
        },
        {
          event: 'setUserMaxDebt',
          // params: {id: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.maxDebt'),
          preview: {
            value: addComma(13000) + this.$t('currency'),
            // color: 'danger'
          },
          i18n: '',
          icon: 'icon-dollar-sign',
          permission: 'user.set_max_debt',
          iconPack: 'feather'
        },
        {
          event: 'showUserInvoices',
          params: {id: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.invoices'),
          // preview: {
          //   value: addComma(25000) + this.$t('currency'),
          //   color: 'success'
          // },
          i18n: '',
          icon: 'INVOICE',
          colorCode: '#000',
          iconPack: 'useral'
        },
        {
          name: 'insertSaleInvoice',
          query: {user: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.reserve'),
          i18n: '',
          icon: 'icon-clock',
          iconPack: 'feather',
          permission: 'invoice.create'
        },
        {
          name: 'customerSeasonSchedulesList',
          params: {userId: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.reserveSeason'),
          i18n: '',
          icon: 'CALENDER',
          colorCode: '#000',
          iconPack: 'useral',
          permission: 'invoice.create'
        },
        {
          event: 'receiveMoneyFromCustomer',
          // query: {user: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.receive'),
          i18n: '',
          // color: 'success',
          icon: 'icon-dollar-sign',
          iconPack: 'feather'
        },
      ]
    }
  },
  created () {
    //check permissions
    if (!checkUserPermissions('user.update')) {
      delete this.actions.toolbar
    }

    if (!this.userId) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      }, 50)
    }

    this.getUser()
  },
  computed: {
    getUserProfile () {
      const user = {
        avatar: this.user.avatar ? getAvatarUrl(this.user.avatar) : this.user.general.gender === 1 ? getStaticFileUrl('/images/man-avatar.png') : getStaticFileUrl('/images/woman-avatar.png'),
        name: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
        general: this.user.general,
        asiderAvatar: [
          {
            content: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
            preContent: this.user.general.gender === 1 ? this.$t('users.genderTypes.dynamicMan') : this.user.general.gender === 2 ? this.$t('users.genderTypes.woman') : ''
          },
          {
            content: this.user.general.phoneNumber.value,
            preContent: this.$t('users.user.profile.asiderAvatar.phoneNumber')
          },
          // {
          //   content: this.user.general.character ?  this.user.general.character.name : '',
          //   preContent: this.$t('users.user.profile.asiderAvatar.character')
          // },
          {
            content: this.user.general.dailyDebt > 0 ? `${addComma(this.user.general.dailyDebt)} ${this.$t('currency')}` : this.$t('users.user.labels.notHave'),
            preContent: this.$t('users.user.profile.asiderAvatar.todayDebt'),
            color: this.user.general.dailyDebt > 0 ? 'danger' : 'success'
          }
        ],
        details: [],
      }

      return user
    },
    getUserTransactions () {
      return {
        value: this.user.financial.balance || 0
      }
    }
  },
  methods: {
    getUser () {

      getUser(this.userId || this.$route.params.id).then(res => {
        const user = res.data.data

        this.user = {
          general: {
            name: {
              value: user.name,
              isInvalid: ''
            },
            lastName: {
              value: user.family,
              isInvalid: ''
            },
            gender: user.gender || '',
            character: user.character,
            companyName: {
              value: user.company ? user.company : '',
              isInvalid: ''
            },
            email: {
              value: user.email ? user.email : '',
              isInvalid: ''
            },
            phoneNumber: {
              value: user.phone_number,
              isInvalid: ''
            },
            dailyDebt: user.daily_debt || 0
            // type: type,
          },
          coachId: user.coach_id,
          avatar: user.avatar || '',
          access: user.role,
          group: user.group,
          financial: {
            maxDebt: user.max_debt ? user.max_debt : 0,
            balance: user.balance ? user.balance : 0
          }
        }

        const balance_index = this.actionsList.map(e => e.event).indexOf('showUserTransactions')
        let actionsList = JSON.parse(JSON.stringify(this.actionsList))
        if (balance_index > -1) {
          actionsList[balance_index].preview = {
            value: `${addComma(Math.abs(this.user.financial.balance)) || 0} ${this.$t('currency')}`,
            color: this.user.financial.balance < 0 ? 'success' : this.user.financial.balance > 0 ? 'danger' : ''
          }
        }

        const maxDebt_index = this.actionsList.map(e => e.event).indexOf('setUserMaxDebt')
        if (maxDebt_index > -1) {
          actionsList[maxDebt_index].preview.value = `${addComma(Math.abs(this.user.financial.maxDebt)) || 0} ${this.$t('currency')}`
        }

        if (user.is_coach) {
          const coachClasses = {
            event: 'coachClasses',
            i18n: 'users.user.profile.actions.coachClasses',
            icon: 'CALENDER',
            colorCode: '#000',
            iconPack: 'useral',
          }

          actionsList.splice(2, 0, coachClasses)
        }

        this.actionsList = []
        setTimeout(() => {
          this.actionsList = actionsList
        }, 50)


        if (!this.user.access) {
          this.actionsList.splice(2, 1)
        }

        if (!this.userId) {
          this.$store.dispatch('setPageTitle', this.$t('users.user.profile.dynamicTitle', {name: `${this.user.general.name.value} ${this.user.general.lastName.value}`}))
        }
      })
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.documentsChanged': {
      handler (val) {
        if (val) {
          this.receiveFromCustomerPromptStatus = false
          this.getUser()
          this.$emit('edited')
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.user-profile-page {

  .vx-card__body {
    padding: 0 !important;

    .profile-main-box2 {
      padding: 0;
    }
  }
}

.action-buttons {
  display: flex;

  button {
    flex: 1;
    line-height: 40px;
    border-radius: .5rem;
    border: none;
    color: #ffffff;
    transition: all .3s ease;
    cursor: pointer;

    &.payment {
      margin-right: 10px;
      background: #33b000cf;

      &:hover {
        background: #33b000;
      }
    }

    &.cancel {
      background: #b00000af;

      &:hover {
        background: #b00000;
      }
    }
  }
}
</style>
