<template>
  <div class="edit-cash-boxes-opening-balance">

    <div class="edit-cash-boxes-opening-balance-box">

      <vs-row class="my-2">
        <vs-col class="w-full">
          <vs-input class="number-style w-full"
                    v-model="updatedCashBox.price"
                    :label="`${$t('treasury.cashBoxes.openingBalance.labels.price')}`"/>
        </vs-col>
      </vs-row>

      <vs-row class="my-2">
        <vs-col class="w-full">
          <vs-button class="w-full"
                     color="danger"
                     @click="$refs.deleteOpeningBalanceConfirmation.showDialog()">
            {{ $t('treasury.cashBoxes.openingBalance.labels.delete') }}
          </vs-button>
        </vs-col>
      </vs-row>
    </div>

    <custom-dialog ref="deleteOpeningBalanceConfirmation"
                   :title="`${$t('treasury.cashBoxes.openingBalance.confirmations.delete.title')}`"
                   :body="`${$t('treasury.cashBoxes.openingBalance.confirmations.delete.body')}`"
                   @accept="handleDeleteCashBoxOpeningBalance"/>

    <button v-show="false" id="saveBTN" @click="sendData"/>
  </div>
</template>

<script>
import axios from 'axios'
import {addComma} from '@/assets/js/functions'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import {
  deleteCashBoxesOpeningInventories,
  setCashBoxesOpeningInventories
} from '../../../../../http/requests/openingInventories'
import CustomDialog from '../../../../../components/customDialog/customDialog'

export default {
  name: 'editCashBoxesOpeningBalance',
  components: {CustomDialog, CustomIcon},
  props: {
    cashBox: {}
  },
  data () {
    return {
      updatedCashBox: {}
    }
  },
  created () {
    this.updatedCashBox = JSON.parse(JSON.stringify(this.cashBox))
  },
  methods: {
    sendData () {
      const cashBox = {
        id: this.updatedCashBox.id,
        price: parseInt(this.updatedCashBox.price.toString().replaceAll(',', ''))
      }
      setCashBoxesOpeningInventories([cashBox]).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.cashBoxes.openingBalance.notifications.edit.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeOpeningBalance')
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cashBoxes.openingBalance.notifications.edit.error'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    },
    handleDeleteCashBoxOpeningBalance () {
      if (this.cashBox && this.cashBox.id) {
        deleteCashBoxesOpeningInventories(this.cashBox.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('treasury.cashBoxes.openingBalance.notifications.delete.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$store.dispatch('helper/deleteOpeningBalance')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.cashBoxes.openingBalance.notifications.delete.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      }
    }
  },
  watch: {
    'updatedCashBox.price': {
      handler (val, oldVal) {
        this.updatedCashBox.price = addComma(val)
      }
    }
  }
}
</script>

<style lang="scss">
.select-file-input {
  width: 100%;
  background: #28c76f;
  display: block;
  padding: 10px;
  border-radius: .5rem;

  span {
    color: white !important;

    div.icon {
      background-color: white !important;
    }
  }

  input {
    display: none;
  }
}
</style>
