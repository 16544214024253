<template>
  <div class="draggable-dynamic-table-setting">

    <!--    <div class="navbar-shadow"></div>-->

    <vs-row class="draggable-dynamic-table-setting-wrapper"
            :class="[!box_status ? 'closed' : 'opened p-5', classObj]"
            v-for="(key, key_index) in Object.keys(settings)"
            :key="key_index">

      <vs-col class="useral-font-weight-medium mb-5">
        {{ $t(settings[key].i18n) || settings[key].title }}
      </vs-col>

      <vs-col v-if="settings[key].type === 'checkbox'">
        <div class="setting-item w-full md:w-auto md:inline-block"
             v-for="(option, option_index) in settings[key].options.slice().reverse()"
             :key="option_index">

          <custom-check-box v-if="!option.locked"
                            :value="option"
                            class="md:inline-block"
                            @input="$emit('input', activeSettings)"
                            v-model="activeSettings[settings[key].field]">

            <span class="text-md">
              {{ $t(option.i18n) || option.headerName || option.field }}
            </span>
          </custom-check-box>

          <span v-else>

            <span class="text-md">
              {{ $t(option.i18n) || option.headerName || option.field }}
            </span>

            <vs-icon color="danger"
                     icon="icon-lock"
                     icon-pack="feather"
                     style="margin-right: 13px;"/>
        </span>
        </div>
      </vs-col>
    </vs-row>

    <div class="draggable-dynamic-table-setting-overlay"
         v-if="box_status"
         @click="$emit('setting:closed'), box_status = false"></div>
  </div>
</template>

<script>
import CustomCheckBox from '@/components/customCheckBox/customCheckBox'

export default {
  name: 'draggingDynamicTableSetting',
  components: {CustomCheckBox},
  model: {
    prop: 'activeSettings',
    event: 'input'
  },
  props: {
    status: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: () => { return {} }
    },
    activeSettings: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      box_status: false,
      active_settings: {}
    }
  },
  created () {
    this.box_status = this.status
    Object.keys(this.settings).forEach((key) => {
      if (this.settings[key].type === 'checkbox') {
        this.activeSettings[this.settings[key].field] = this.settings[key].options
      }
    })
  },
  computed: {
    verticalNavMenuWidth () {
      return this.$store.state.verticalNavMenuWidth
    },
    classObj () {
      if (this.verticalNavMenuWidth === 'default') return 'table-setting-default'
      else if (this.verticalNavMenuWidth === 'reduced') return 'table-setting-reduced'
      else if (this.verticalNavMenuWidth) return 'table-setting-full'
    }
  },
  watch: {
    status: {
      handler (val) {
        this.box_status = val
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.draggable-dynamic-table-setting {

  .navbar-shadow {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), transparent);
    z-index: 50000;
  }

  .draggable-dynamic-table-setting-wrapper {
    direction: rtl;
    position: relative;
    width: 100%;
    z-index: 10000;
    overflow: hidden;
    transition: all 0.2s ease;
    background: #131314 !important;
    border-bottom: 0 solid #333333;

    &::-webkit-scrollbar {
      display: block;
      border-radius: 4px;
      width: 4px !important;
      background: #222222;
      float: left;
    }

    &::-webkit-scrollbar-thumb {
      display: block;
      border-radius: 5px;
      background: #555555;
    }

    * {
      text-align: left;
    }

    &.closed {
      max-height: 0;
    }

    &.opened {
      max-height: 150px;
      overflow-y: auto;
      scrollbar-width: 1px;
      border-bottom: 1px solid #333333;

      &::-webkit-scrollbar {
        width: 1px;
      }
    }

    /*&.table-setting-default {
      width: calc(100% - 260px) !important;
    }

    &.table-setting-reduced {
      width: calc(100% - 80px) !important;
    }

    &.table-setting-full {
      width: calc(100%) !important;
    }*/

    .setting-item {
      line-height: 30px;
      float: left;

      i {
        font-size: 15px;
        vertical-align: middle;
      }
    }

    .custom-check-box {
      direction: ltr;

      .check-box {
        margin-top: 5px;
      }

      .check-box-label {
        line-height: 30px;
      }
    }
  }

  .custom-check-box {
    padding: 0 5px;
  }

  @media (max-width: 576px) {
    .icon-lock {
      margin-left: 20px !important;
      font-size: 21px !important;
      margin-right: 2px !important;
    }
  }

  .draggable-dynamic-table-setting-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
  }
}
</style>
