export default {
  currency: ' تومان',
  preSheba: 'IR',
  whatsAppPreNumber: '98',

  // navMenuItems Words
  dashboard: 'داشبورد',

  shortcuts: {
    calendar: 'تقویم',
    reserve: 'رزرو تایم',
    receive: 'دریافت'
  },


  beginning: 'اول دوره',
  beginningUsers: ' اولیه اشخاص',
  beginningCashs: 'اولیه صندوق',
  beginningBanks: 'اولیه بانک',
  beginningWarehouse: 'اولیه انبار',

  purchasesInvoice: 'فاکتور خرید',
  purchaseReternsInvoice: 'فاکتور خرید برگشتی',

  warehousing: 'انبارداری',
  inventoryValuation: 'مرور مبلغی انبار',

  banks: 'بانک',
  cash: 'صندوق',
  transaction: 'تراکنش ها',
  transference: 'انتقال وجه داخلی',
  accountingTable: 'جدول حساب ها',
  income: 'درامد',
  cost: 'هزینه',

  accounting: 'حسابداری',

  save: 'ثبت',
  cancel: 'انصراف',

  logout: 'خروج',

  // gender
  genderTypes: {
    all: 'همه',
    man: 'آقا',
    dynamicMan: 'آقای ',
    woman: 'خانم '
  },

  // alert title
  alert: {
    duplicateRequest: {
      title: 'هشدار',
      message: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید'
    },
    error: {
      title: 'خطا',

      accessDenied: 'شما دسترسی به این فعالیت ندارید'
    },
    warning: {
      title: 'هشدار',
      repeatedRequestText: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید'
    },
    message: {
      title: 'پیغام',
      pleaseWait: 'درخواست ارسال شد. لطفا منتظر بمانید...'
    }
  },

  // navbar actions
  navbar: {
    back: 'بازگشت',
    save: 'ذخیره',
    from: 'از ',
    until: ' تا ',
    dynamicFinancialYear: 'سال مالی {start} تا {end}',

    notification: {
      newMessage: 'پیام جدید',
      showAll: 'مشاهده همه پیام ها'
    }
  },

  // table statics
  draggableTable: {

    filter: {

      types: {
        search: 'شامل شود',
        equals: 'برابر باشد',
        notEqual: 'برابر نباشد',
        lessThanEquals: 'کوچکتر مساوی',
        greaterThanEquals: 'بزرگتر مساوی'
      }
    }
  },

  // login page
  login: {
    title: 'ورود',

    labels: {
      codeValidate: 'اعتبار کد',
      second: 'ثانیه',
      resendCode: 'ارسال دوباره کد',
      sendOtp: 'ارسال کد فعال سازی'
    },

    clubTitle: 'پنل ادمین {name}',
    confirmPhoneNumber: 'تایید تلفن همراه',
    getPhoneNumberMessage: 'لطفا شماره همراه خود را وارد کنید تا کد <b> احراز هویت </b> برای شما ارسال شود',
    otpSend: 'کد تایید به شماره همراه شما پیامک شد.',

    getPhoneNumberStepMessage1: 'لطفا شماره تلفن همراه خود را وارد کنید. رمز پیامکی ',
    getPhoneNumberStepMessage2: '(کد پنج رقمی)',
    getPhoneNumberStepMessage3: ' برای تایید شماره شما ارسال خواهد شد.',
    phoneNumber: 'شماره همراه',
    phoneNumberIsNotValid: 'شماره همراه به صورت صحیح وارد نشده است',
    phoneNumberIsNull: 'شماره همراه وارد نشده است',

    otpCodeSentStepMessage1: 'برای شماره تلفن ',
    otpCodeSentStepMessage2: ' یک کد ۵ رقمی ارسال شد. لطفا کد را وارد کنید',
    otpCodeIsNotValid: 'کد اعتبارسنجی به صورت صحیح وارد نشده است',
    otpCodeIsNull: 'کد اعتبارسنجی وارد نشده است',
    retrieveTheCode: 'دریافت مجدد کد',

    loginToPanel: 'ورود به پنل {name}',
    resendOTPMessage: 'برای دریافت مجدد کد یکی از روش های زیر را انتخاب کنید',
    otpCall: 'تا لحظاتی دیگر تماس با شماره {number} برقرار خواهد شد',
    resendOTPTypes: {
      sms: 'پیامک',
      tel: 'تماس'
    },

    otp: 'یکبار رمز',
    otpSendDynamic: 'کد اعتبارسنجی به شماره {number} ارسال شد',

    getSuperAdminTokenStepMessage: 'لطفا توکن خود را وارد کنید.',
    token: 'توکن مدیر سامانه',

    buttons: {
      getOTPCode: 'دریافت کد',
      login: 'ورود'
    },

    notification: {
      userNotFound: 'کاربری با این شماره پیدا نشد!',
      accessError: 'دسترسی شما به سامانه مسدود شده است!',

      login: {
        title: 'پیغام',
        message: 'شما با موفقیت وارد سامانه شدید'
      },

      review: {
        title: 'بررسی هویت',
        message: 'سیستم در حال بررسی هویت شما است!'
      },

      logout: {
        title: 'پیغام',
        message: 'شما با موفقیت از سامانه خارج شدید'
      },

      forceLogout: {
        title: 'پیغام',
        message: 'به علت عدم استفاده طولانی مدت از سامانه ,دوباره وارد شوید'
      }
    },

    validators: {
      phoneStart: 'شماره همراه با 09 شروع میشود.',
      phoneNumberLength: 'شماره همراه باید 11 رقمی باید'
    }

  },

  // profile page
  profile: {
    title: 'پروفایل',
    dynamicTitle: 'پروفایل {name}',

    details: {
      dynamicCompany: 'شرکت {company}'
    },

    asiderAvatar: {
      phoneNumber: 'شماره تماس: ',
      character: 'گروه مشتری: ',
      access: 'دسترسی: '
    },

    importantData: {
      balance: 'کیف پول',
      score: 'امتیاز',
      character: 'گروه کاربری'
    },

    actions: {
      invoices: 'فاکتورهای من',
      transactions: 'گردش مالی',
      addresses: 'آدرس ها',
      receivedEvents: 'پیام های سامانه',
      sentEvents: 'پیام های ارسالی'
    },

    notifications: {
      edit: {
        success: 'اطلاعات پروفایل با موفقیت بروزرسانی شد',
        error: 'ویرایش اطلاعات پروفال با خطا مواجه شد'
      },

      parseError: {
        required: 'مقادیر الزامی وارد نشده است',
        gender: 'جنسیت انتخاب شده معتبر نیست!',
        name: 'نام انتخاب شده معتبر نیست!',
        family: 'نام خانوادگی انتخاب شده معتبر نیست!',
        avatar: 'تصویر انتخاب شده معتبر نیست!',
        phoneNumber: 'شماره همراه وارد شده معنبر نیست!'
      }
    },

    edit: {
      title: 'ویرایش پروفایل',

      labels: {
        gender: 'جنسیت',
        name: 'نام',
        family: 'نام خانوادگی',
        phoneNumber: 'شماره همراه'
      },

      invalidTexts: {
        name: 'نام باید فارسی باشد!',
        family: 'نام خانوادگی باید فارسی باشد!'
      },

      validators: {
        name: 'نام به صورت صحیح وارد نشده است',
        family: 'نام خانوادگی به صورت صحیح وارد نشده است',
        companyName: 'نام شرکت نمی تواند خالی باشد',
        phoneNumber: 'فرمت شماره تماس اشتباه است',
        email: 'فرمت ایمیل اشتباه است'
      }
    },

    address: {
      title: 'آدرس ها',

      labels: {
        name: 'نام آدرس',
        province: 'استان',
        city: 'شهر',
        address: 'نشانی پستی',
        plaque: 'پلاک',
        postalCode: 'کدپستی',
        delete: 'حذف',
        save: 'ثبت',

        receiver: {
          info: 'اطلاعات گیرنده',
          name: 'نام گیرنده',
          family: 'نام خانوادگی گیرنده',
          phoneNumber: 'شماره تماس گیرنده'
        }
      },

      description: {
        noAddress: 'هیچ آدرسی ثبت نشده است!'
      },

      validators: {
        city: 'شهر نمی تواند انتخاب نشود',
        province: 'استان نمی تواند انتخاب نشود',
        name: 'نام به صورت صحیح وارد نشده است',
        nameCantEmpty: 'نام نمی تواند خالی باشد',
        address: 'نشانی به صورت صحیح وارد نشده است',
        addressCantEmpty: 'نشانی نمی تواند خالی باشد',
        plaque: 'فرمت پلاک اشتباه است',
        postalCode: 'فرمت کدپستی اشتباه است',
        phoneNumber: 'فرمت شماره تماس اشتباه است',
        receiver: {
          name: 'نام به صورت صحیح وارد نشده است',
          nameCantEmpty: 'نام گیرنده نمی تواند خالی باشد',
          family: 'نام خانوادگی به صورت صحیح وارد نشده است',
          familyCantEmpty: 'نام خانوادگی گیرنده نمی تواند خالی باشد',
          phoneNumber: 'شماره تماس گیرنده نمی تواند خالی باشد'
        }
      },

      notifications: {
        insert: {
          success: 'آدرس جدید با موفقیت ایجاد شد',
          error: 'افزودن آدرس جدید با خطا همراه شد'
        },
        edit: {
          success: 'ویرایش آدرس با موفقیت انجام شد',
          error: 'ویرایش آدرس با خطا همراه شد'
        },
        delete: {
          success: 'آدرس با موفقیت حذف شد',
          error: 'حذف آدرس با خطا همراه شد'
        }
      },

      insert: {
        title: 'افزودن آدرس جدید'
      },

      edit: {
        title: 'ویرایش آدرس'
      }
    },

    events: {
      seenTypes: {
        all: 'همه',
        seen: 'مشاهده شده',
        unSeen: 'مشاهده نشده'
      },

      list: {
        table: {
          header: {
            rowNumber: 'ردیف',
            creator: 'ارسال کننده',
            receiver: 'دریافت کننده',
            subject: 'موضوع',
            content: 'محتوا',
            seen: 'وضعیت مشاهده',
            type: 'نوع',
            priority: 'اولویت',
            createdAt: 'تاریخ ارسال'
          }
        }
      },

      receive: {
        title: 'پیام های سامانه'
      },

      send: {
        title: 'پیام های ارسالی'
      }
    },

    transactions: {
      title: 'گردش مالی',

      table: {
        header: {
          docNumber: 'شماره سند',
          date: 'تایخ',
          documentation: 'مستندات',
          price: 'مبلغ',
          balance: 'موجودی'
        }
      }
    },

    buttons: {
      save: 'ذخیره تغییرات',
      reset: 'برگرداندن تغییرات',
      removeAvatar: 'حذف عکس',
      uploadAvatar: 'بارگزاری عکس'
    },

    General: {
      title: 'عمومی',
      avatar: {
        rule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم عکس 800 کیلوبایت'
      },
      user: {
        name: 'نام',
        lastName: 'نام خانوادگی',
        role: 'مسئولیت',
        phoneNumber: 'شماره تماس',
        address: 'آدرس',
        maxDebt: 'حداکثر میزان بدهی',
        balance: 'میزان اعتبار'
      }
    },

    MyFinancialReports: {
      title: 'گزارشات مالی من'
    },

    invoices: {
      title: 'لیست فاکتورهای من'
    }
  },

  smsPanel: 'پنل اس ام اس',

  // opening inventories
  opening: {
    users: {
      title: 'اول دوره اشخاص'

    },

    cashBoxes: {
      title: 'اول دوره صندوق ها'

    },

    banks: {
      title: 'اول دوره بانک ها'

    },

    stores: {
      title: 'اول دوره انبار ها'

    }
  },

  sale: {
    title: 'فروش'
  },

  club: {
    setting: {
      title: 'پیکربندی باشگاه'
    }
  },

  // coaches
  coaches: {
    title: 'مربی های قراردادی',

    paymentType: {
      daily: 'روزانه',
      weekly: 'هفتگی',
      monthly: 'ماهانه'
    },

    labels: {
      user: 'طرف حساب',
      paymentType: 'دوره تسویه با مربی',
      contractPrice: 'مبلغ خرید مربی',
      reservePrice: 'مبلغ فروش مربی',
      delete: 'حذف'
    },

    table: {
      header: {
        row: 'ردیف',
        image: 'عکس پرسنلی',
        gender: 'جنسیت',
        name: 'نام و نام خانوادگی',
        phoneNumber: 'تلفن همراه',
        contractPrice: 'مبلغ خرید مربی',
        reservePrice: 'مبلغ فروش مربی',
        balance: 'موجودی',
      }
    },

    notifications: {
      insert: {
        success: 'افزودن مربی قراردادی با موفقیت انجام شد.',
        error: 'افزودن مربی قراردادی با خطا همراه شد.'
      },

      edit: {
        success: 'ویرایش مربی قراردادی با موفقیت انجام شد.',
        error: 'ویرایش مربی قراردادی با خطا همراه شد.'
      },

      receive: {
        success: 'تسویه مربی با موفقیت انجام شد.',
        error: 'تسویه مربی با خطا همراه شد.'
      },

      delete: {
        success: 'حذف مربی قراردادی با موفقیت انجام شد.',
        error: 'حذف مربی قراردادی با خطا همراه شد.'
      },

      parseError: {
        user: 'طرف حساب انتخاب شده معتبر نیست!',
        contractPrice: 'مبلغ قرارداد وارد شده معتبر نیست!',
        reservePrice: 'مبلغ رزرو وارد شده معتبر نیست!'
      }
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف مربی قراردادی',
        body: 'آیا از حذف {name} به عنوان مربی قراردادی اطمینان دارید؟'
      }
    },

    list: {
      title: 'لیست مربی های قراردادی',
    },

    insert: {
      title: 'افزودن مربی قراردادی جدید'
    },

    edit: {
      title: 'ویرایش مربی قراردادی',
      dynamicTitle: 'ویرایش اطلاعات مربی {name}'
    },

    profile: {
      title: 'پروفایل مربی قراردادی',
      dynamicTitle: 'پروفایل مربی {name}',

      asiderAvatar: {
        paymentType: 'دوره تسویه: ',
        contractPrice: 'مبلغ خرید مربی: ',
        reservePrice: 'مبلغ فروش مربی: '
      },

      actions: {
        receive: 'تسویه با مربی',
        classes: 'کلاس های مربی'
      }
    },

    select: {
      title: 'لیست مربی های قراردادی'
    },

    classes: {
      title: 'کلاس های مربی قراردادی',
      dynamicTitle: 'کلاس های مربی {name}'
    },

    receive: {
      title: 'تسویه حساب با مربی',
      dynamicTitle: 'تسویه حساب با {name}',

      labels: {
        receivedBalance: 'مبلغ تسویه شده',
        transferId: 'شماره پیگیری',
        totalBalance: 'موجودی کل',
        receivedPayableBalance: 'موجودی قابل تسویه'
      },

      notifications: {
        balanceIsNotEnough: 'موجودی شما برای تسویه کافی نیست!',
        incorrectPriceOrId: 'مبلغ تسویه و یا شماره پیگیری وارد شده معتبر نیست'
      }
    }
  },

  // courts
  courts: {
    title: 'زمین ها',

    labels: {
      image: 'تصویر موقعیت مکانی زمین در باشگاه',
      name: 'نام',
      type: 'نوع زمین',
      delete: 'حذف زمین',
    },

    types: {
      all: 'همه',
      hard: 'هارد',
      ground: 'خاک',
      grass: 'چمن'
    },

    roofTypes: {
      always: 'دائم',
      season: 'فصلی',
      without: 'سرباز'
    },

    notifications: {
      insert: {
        success: 'اطلاعات زمین با موفقیت ثبت شد',
        error: 'ثبت اطلاعات زمین با خطا مواجه شد'
      },

      edit: {
        success: 'اطلاعات زمین با موفقیت بروزرسانی شد',
        error: 'ویرایش اطلاعات زمین با خطا مواجه شد'
      },

      delete: {
        success: 'اطلاعات زمین مورد نظر حذف شد',
        error: 'حذف اطلاعات زمین با خطا همراه شد',
        isUsedError: 'زمین مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
      },

      restore: {
        success: 'زمین با موفقیت بازگردانده شد',
        error: 'بازگردانی زمین با خطا مواجه شد'
      },

      parseError: {
        name: 'نام انتخاب شده معتبر نیست!',
        type: 'نوع زمین معتبر نیست!',
        image: 'تصویر انتخاب شده معتبر نیست!'
      }
    },

    validators: {
      name: 'نام وارد شده صحیح نمی باشد',
      type: 'نوع زمین انتخاب شده صحیح نمی باشد'
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف زمین',
        body: 'آیا از حذف موقت {name} اطمینان دارید؟'
      },

      forceDelete: {
        title: 'تاییدیه حذف زمین',
        body: 'آیا از حذف دائم {name} اطمینان دارید؟'
      }
    },

    list: {
      title: 'لیست زمین ها',

      table: {
        header: {
          row: 'ردیف',
          image: 'موقعیت زمین',
          name: 'نام زمین',
          type: 'نوع زمین',
          createdAt: 'تاریخ ایجاد',
          delete: 'حذف',
          restore: 'بازگردانی'
        }
      }
    },

    insert: {
      title: 'افزودن زمین جدید'
    },

    edit: {
      title: 'ویرایش زمین',
      dynamicTitle: 'ویرایش اطلاعات {name}'
    },

    profile: {
      title: 'پروفایل زمین',
      dynamicTitle: 'پروفایل {name}',

      actions: {
        price: 'قیمت گذاری زمین'
      },

      asiderAvatar: {
        type: 'نوع زمین: '
      }
    },

    trash: {
      title: 'سطل زباله زمین'
    },

    price: {
      table: {
        header: {
          termName: 'نام سانس',
          saturday: 'شنبه',
          sunday: 'یک شنبه',
          monday: 'دوشنبه',
          tuesday: 'سه شنبه',
          wednesday: 'چهارشنبه',
          thursday: 'پنج شنبه',
          friday: 'جمعه'
        }
      },

      notifications: {
        edit: {
          success: 'ویرایش لیست قیمت ها با موفقیت انجام شد',
          error: 'ویرایش لیست قیمت ها با خطا مواجه شد'
        }
      },

      list: {
        title: 'لیست قیمت زمین',
        dynamicTitle: 'قیمت گذاری {name}'
      },

      edit: {
        title: 'ویرایش لیست قیمت زمین',
        dynamicTitle: 'ویرایش لیست قیت {name}'
      }
    }
  },

  times: {
    title: 'تایم ها',

    labels: {
      edit: 'تغییر تایم',
      timeLength: 'بازه هر تایم',
      startTime: 'ساعت شروع',
      endTime: 'ساعت پایان',
      minute: 'دقیقه'
    },

    notifications: {
      notAllowed: 'شما دسترسی ایجاد و تغییر تایم را ندارید!',

      insert: {
        success: 'لیست تایم شما ایجاد شد',
        error: 'ایجاد تایم با خطا مواجه شد'
      },

      edit: {
        success: 'اطلاعات تایم باشگاه با موفقیت بروز شد',
      }
    },

    validators: {
      timeLength: 'ساعت شروع و پایان با بازه هر تایم مطابقت ندارد'
    },

    confirmations: {
      edit: {
        title: 'تاییدیه تغییر تایم',
        body: 'لیست تایم برای شما قبلا ایجاد شده است. آیا از تغییر لیست تایم ها اطمینان دارید؟'
      }
    },

    list: {
      title: 'لیست تایم ها',

      table: {
        header: {
          row: 'ردیف',
          name: 'نام تایم',
          startTime: 'زمان شروع تایم',
          endTime: 'زمان پایان تایم'
        }
      }
    },

    insert: {
      title: 'تایم'
    }
  },

  // سانس ها
  terms: {
    title: 'سانس ها',

    labels: {
      name: 'نام',
      startTime: 'ساعت شروع',
      endTime: 'ساعت پایان',
      delete: 'حذف سانس',
    },

    notifications: {
      wrongValues: 'اطلاعات وارد شده صحیح نمی باشد',
      startTimeIsEqualEndTime: 'ساعت شروع و پایان با هم برابرند!',

      insert: {
        success: 'اطلاعات سانس با موفقیت ثبت شد',
        error: 'ثبت اطلاعات سانس با خطا مواجه شد'
      },

      edit: {
        success: 'اطلاعات سانس با موفقیت بروزرسانی شد',
        error: 'ویرایش اطلاعات سانس با خطا مواجه شد'
      },

      delete: {
        success: 'اطلاعات سانس مورد نظر حذف شد',
        error: 'حذف اطلاعات سانس با خطا همراه شد',
        isUsedError: 'سانس مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
      },

      restore: {
        success: 'سانس مورد نظر با موفقیت بازگردانده شد',
        error: 'بازگردانی سانس مورد نظر با خطا مواجه شد'
      },

      parseError: {
        name: 'نام انتخاب شده معتبر نیست یا قبلا وارد شده است!',
        startTime: 'ساعت شروع انتخاب شده معتبر نیست!',
        endTime: 'ساعت پایان انتخاب شده معتبر نیست!',
        startTimeConflict: 'ساعت شروع با سانس {name} تداخل دارد!',
        endTimeConflict: 'ساعت پایان با سانس {name} تداخل دارد!',
        termRange: 'بازه سانس مورد نظر با بازه تایم ها تداخل دارد!'
      }
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف سانس',
        body: 'آیا از حذف موقت {name}  اطمینان دارید؟'
      },
      forceDelete: {
        title: 'تاییدیه حذف سانس',
        body: 'آیا از حذف دائم {name}  اطمینان دارید؟'
      }
    },

    list: {
      title: 'لیست سانس ها',

      table: {
        header: {
          row: 'ردیف',
          name: 'نام سانس',
          startTime: 'زمان شروع سانس',
          endTime: 'زمان پایان سانس',
          createdAt: 'تاریخ ایجاد'
        }
      }
    },

    insert: {
      title: 'افزودن سانس جدید'
    },

    edit: {
      title: 'ویرایش سانس',
      dynamicTitle: 'ویرایش اطلاعات {name}'
    },

    profile: {
      title: 'پروفایل سانس',
      dynamicTitle: 'پروفایل {name}',

      actions: {
        price: 'قیمت گذاری سانس'
      },

      asiderAvatar: {
        term: 'سانس: ',
        until: ' تا '
      }
    },

    trash: {
      title: 'سطل زباله سانس'
    },

    price: {
      title: '',
      dynamicTitle: 'قیمت گذاری {name}',

      table: {
        header: {
          courtName: 'نام زمین',
          saturday: 'شنبه',
          sunday: 'یک شنبه',
          monday: 'دوشنبه',
          tuesday: 'سه شنبه',
          wednesday: 'چهارشنبه',
          thursday: 'پنج شنبه',
          friday: 'جمعه'
        }
      },

      notifications: {
        edit: {
          success: 'ویرایش لیست قیمت ها با موفقیت انجام شد',
          error: 'ویرایش لیست قیمت ها با خطا مواجه شد'
        }
      },

      list: {
        title: 'لیست قیمت سانس'
      },

      edit: {
        title: 'ویرایش لیست قیت سانس'
      }
    }
  },

  characters: {
    title: 'گروه اشخاص',

    labels: {
      name: 'نام گروه',
      reservableDate: 'حداکثر تاریخ رزرو',
      disable: 'غیر فعال',
      day: 'روز',
      week: 'هفته',
      month: 'ماه',
      delete: 'حذف گروه'
    },

    notifications: {
      insert: {
        success: 'اطلاعات گروه با موفقیت ثبت شد',
        error: 'ثبت اطلاعات گروه با خطا مواجه شد'
      },

      edit: {
        success: 'اطلاعات گروه با موفقیت به روزرسانی شد',
        error: 'ویرایش اطلاعات گروه با خطا مواجه شد'
      },

      delete: {
        success: 'اطلاعات گروه مورد نظر حذف شد',
        error: 'حذف اطلاعات گروه با خطا همراه شد',
        isUsedError: 'گروه مورد نظر به دلیل داشتن داشتن سابقه اسناد، قابل حذف نمی باشد!'
      },

      restore: {
        success: 'گروه مورد نظر با موفقیت بازگردانده شد',
        error: 'بازگردانی گروه مورد نظر با خطا همراه شد'
      },

      parseError: {
        name: 'نام انتخاب شده معتبر نیست!',
        reservableDays: 'تعداد روزهای قابل رزرو درست وارد نشده است!'
      }
    },

    validators: {
      nameHelper: 'نام باید فارسی باشد!',
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف گروه',
        body: 'آیا از حذف موقت {name}  اطمینان دارید؟'
      },

      forceDelete: {
        title: 'تاییدیه حذف گروه',
        body: 'آیا از حذف دائم {name} اطمینان دارید؟'
      }
    },

    table: {
      header: {
        row: 'ردیف',
        name: 'نام گروه',
        createdDate: 'تاریخ ایجاد',
        reservableDate: 'حداکثر تاریخ رزرو',
        delete: 'حذف',
        restore: 'بازگردانی'
      }
    },

    list: {
      title: 'لیست گروه اشخاص'
    },

    insert: {
      title: 'افزودن گروه اشخاص جدید'
    },

    edit: {
      dynamicTitle: 'ویرایش گروه {name}'
    },

    profile: {
      title: '',
      dynamicTitle: 'پروفایل گروه {name}',

      actions: {
        usersList: 'لیست اشخاص گروه {name}'
      }
    },

    select: {
      title: 'لیست گروه های مشتری'
    },

    user: {
      title: '',
      dynamicTitle: 'لیست اشخاص گروه {name}',

      labels: {
        delete: 'حذف شخص از گروه مشتری'
      },

      table: {
        header: {
          row: 'ردیف',
          name: 'نام و نام خانوادگی',
          phoneNumber: 'شماره تلفن',
          delete: 'حذف'
        }
      },

      notifications: {
        notAllowed: 'حذف شخص از گروه مشتری مجاز نیست',
        userExist: 'این شخص در لیست وجود دارد',
        dynamicUserExist: '{name} در لیست وجود دارد',

        insert: {
          success: 'اشخاص در گروه مورد نظر با موفقیت ثبت شدند',
          error: 'ثبت اشخاص در گروه مورد نظر با خظا همراه شد'
        },

        delete: {
          success: 'مشتری از این گروه با موفقیت حذف شد',
          error: 'حذف شخص در گروه مشتری با خظا همراه شد'
        }
      },

      confirmations: {
        delete: {
          title: 'تاییدیه حذف شخص از گروه مشتری',
          body: 'آیا از حذف {name} اطمینان دارید؟'
        }
      },

      insert: {
        title: 'افزودن اشخاص به گروه'
      }
    }
  },

  refunds: {
    title: 'قوانین استرداد وجه',

    timeLabels: {
      all: 'بدون محدودیت',
      from: 'از {time} ساعت مانده به تایم تا شروع تایم',
      fromTo: 'از {from} تا {to} ساعت مانده به تایم',
      to: ' تا {time} ساعت مانده به تایم'
    },

    notifications: {
      wrongValue: 'قیمت تایم {name} درست وارد نشده است',

      edit: {
        success: 'اطلاعات قوانین استرداد وجه با موفقیت ویرایش شد',
        error: 'ویرایش اطلاعات قوانین استرداد وجه با خطا مواجه شد'
      }
    },

    validators: {
      percentRange: 'درصد باید بین 0 و 100 باشد!',
      value: 'درصد باید بزرگتر یا مساوی مقادیر فیلدهای بالاتر باشد'
    },

    list: {
      title: 'قوانین استرداد وجه',

      actions: {
        invoice: 'استرداد وجه فاکتور',
        saleAddon: 'استرداد وجه افزودنی',
        coach: 'استراداد وجه مربی'
      }
    },

    edit: {
      title: '',
      dynamicTitle: 'ویرایش {name}'
    },

    profile: {
      table: {
        header: {
          refund: 'درصد وجه بازگشتی',
          time: 'تایم'
        }
      }
    }
  },

  // companies
  companies: {
    title: 'لیست شرکت ها',

    table: {
      header: {
        row: 'ردیف',
        code: 'کد',
        name: 'نام شرکت',
        equity: 'سرمایه',
        totalDebt: 'بدهی کل',
        totalAsset: 'دارایی کل'
      }
    },

    labels: {
      generalInfo: 'اطلاعات عمومی شرکت',
      telInfo: 'اطلاعات تماس شرکت',
      financialInfo: 'اطلاعات مالی',
      uploadLogo: 'آپلود لوگو',
      preview: 'پیشنمایش:',
      code: 'کد شرکت',
      name: 'نام شرکت',
      managerName: 'نام و نام خانوادگی مدیر',
      address: 'آدرس',
      phoneNumber: 'شماره تماس',
      managerPhoneNumber: 'شماره تماس مدیر',
      financialFrom: 'شروع سال مالی',
      financialTo: 'انتهای سال مالی'
    },

    description: {
      logoRule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت',
      generalInfo: 'در این باکس اطلاعات عمومی شرکت نمایش داده می شود.',
      telInfo: 'در این باکس اطلاعات تماس شرکت نمایش داده می شود.',
      financialInfo: 'در این باکس اطلاعات مالی شرکت نمایش داده می شود.'
    },

    buttons: {
      save: 'ذخیره تغییرات',
      reset: 'برگرداندن تغییرات',
      removeLogo: 'حذف لوگو',
      uploadLogo: 'بارگزاری لوگو'
    },

    validators: {
      imageSize: 'حجم فایل انتخاب شده بیش از حد مجاز است',
      fileFormat: 'فرمت فایل انتخاب شده مجاز نیست'
    },

    notifications: {
      logo: {
        upload: {
          success: 'لوگو با موفقیت آپلود شد'
        },
        delete: {
          success: 'لوگو با موفقیت حذف شد'
        }
      },

      insert: {
        success: 'اطلاعات فروشگاه با موفقیت اضافه شد'
      },

      edit: {
        success: 'اطلاعات فروشگاه با موفقیت بروز شد'
      }
    },

    company: {
      title: 'باشگاه'
    },

    insert: {
      title: 'افزودن باشگاه'
    },

    edit: {
      title: 'ویرایش اطلاعات باشگاه'
    }
  },

  // backup page
  backup: {
    title: 'پشتیبان گیری',

    status: {
      all: 'همه',
      saving: 'در حال ذخیره',
      saved: 'ذخیره شده',
      retrieved: 'بازیابی شده'
    },

    notifications: {
      insert: {
        success: 'فایل پشتیبان با موفقیت ایجاد شد',
        error: 'ایجاد فایل پشتیبان با خطا مواجه شد'
      },

      recovery: {
        success: 'درخواست با موفقیت ارسال شد',
        error: 'این پشتیبان قابل بازیابی نمی باشد',
        cantSend: 'ارسال درخواست با خطا مواجه شد'
      }
    },

    confirmations: {
      insert: {
        title: 'ایجاد پشتیبان',
        body: 'آیا از ایجاد پشتیبان اطمینان دارید؟'
      },

      recovery: {
        title: 'ایجاد پشتیبان',
        body: 'آیا از بازیابی پشتیبان اطمینان دارید؟'
      }
    },

    list: {
      table: {
        header: {
          rowNumber: 'ردیف',
          date: 'تاریخ',
          status: 'وضعیت',
          recovery: 'بازیابی'
        }
      }
    }
  },

  // setting page
  setting: {
    title: 'تنظیمات',

    asiderAvatar: {
      support: 'پشتیبانی: ',
      whatsApp: 'واتس آپ: '
    },

    actions: {
      address: 'آدرس',
      tel: 'تماس',
      treasury: 'خزانه',
      language: 'زبان',
      message: 'پنل پیامکی',
      faq: 'پرسش های متداول',
      rules: 'قوانین و مقررات'
    },

    notifications: {
      editRules: {
        success: 'قوانین و مقررات باشگاه با موفقیت بروز شد'
      },

      editAddress: {
        success: 'اطلاعات آدرس باشگاه با موفقیت بروز شد'
      },

      editTel: {
        success: 'اطلاعات تماس باشگاه با موفقیت بروز شد'
      },

      editTreasury: {
        success: 'اطلاعات خزانه با موفقیت بروز شد',
        negativeBalance: 'به دلیل منفی بودن موجودی تعدادی از بانک ها و یا صندوق ها، ذخیره تنظیمات مورد نظر با خطا همراه شد'
      },

      editMessage: {
        success: 'اطلاعات پنل پیامکی باشگاه با موفقیت بروز شد'
      },

      editFaq: {
        success: 'سوالات متداول با موفقیت بروز شد'
      },

      editClub: {
        success: 'اطلاعات باشگاه با موفقیت بروز شد'
      }
    },

    buttons: {
      save: 'ذخیره تغییرات',
      reset: 'برگرداندن تغییرات',
      removeLogo: 'حذف لوگو',
      uploadLogo: 'بارگزاری لوگو'
    },

    clubInfo: {
      title: 'شرکت',

      labels: {
        generalInfo: 'اطلاعات عمومی باشگاه',
        telInfo: 'اطلاعات تماس',
        financialInfo: 'اطلاعات مالی',
        uploadLogo: 'آپلود لوگو',
        preview: 'پیشنمایش:',
        name: 'نام شرکت',
        managerName: 'نام و نام خانوادگی مدیر',
        country: 'کشور',
        province: 'استان',
        city: 'شهر',
        address: 'آدرس',
        phoneNumber: 'شماره تماس',
        whatsAppSupport: 'شماره پشتیبان واتساپ',
        managerPhoneNumber: 'شماره تماس مدیر'
      },

      notifications: {
        logoUpload: {
          success: 'لوگو با موفقیت آپلود شد'
        },

        logoDelete: {
          success: 'لوگو با موفقیت حذف شد'
        }
      },

      validators: {
        logoRule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت',
        imageSize: 'حجم فایل انتخاب شده بیش از حد مجاز است',
        fileFormat: 'فرمت فایل انتخاب شده مجاز نیست',
      },

      description: {
        generalInfo: 'در این باکس اطلاعات عمومی باشگاه نمایش داده می شود.'
      },

      edit: {
        title: 'ویرایش باشگاه'
      }
    },

    address: {
      title: 'آدرس',

      labels: {
        province: 'استان',
        city: 'شهر',
        address: 'آدرس'
      }
    },

    tel: {
      title: 'اطلاعات تماس',

      labels: {
        support: 'شماره تماس پشتیبانی',
        whatsApp: 'شماره واتس اپ پشتیبانی',
        sale: 'شماره فروش'
      }
    },

    message: {
      title: 'پنل پیامکی',

      labels: {
        messengerKey: 'کد احراز هویت',
        messengerUsername: 'نام کاربری',
        messengerPassword: 'رمز عبور',
        messengerSenderNumber: 'شماره فرستنده پیام',
        templates: 'قالب ها',
        login: 'قالب پیامک لاگین',
        signup: 'قالب پیامک ثبت نام',
        confirmInfo: 'قالب پیامک تایید اطلاعات',
        enableCancelReserve: 'فعال سازی پیام لغو رزرو',
        enableDailyReport: 'فعال سازی پیام گزارش روزانه',
        enableNewReceiveByClub: 'فعال سازی پیام دریافت جدید توسط باشگاه',
        enableNewReceiveByCustomer: 'فعال سازی پیام دریافت جدید توسط مشتری',
        enableNewReserveTime: 'فعال سازی پیام رزرو تایم',
        newReserveTimeMessagePattern: 'الگوی پیامک رزرو',
        enableNewReserveCoach: 'فعال سازی پیام رزرو مربی',
        enableWelcome: 'فعال سازی پیام خوش آمد گویی'
      },

      invalidTexts: {
        onlyEnglish: 'فقط حروف انگلیسی مجاز است!',
      }
    },

    faq: {
      title: 'پرسش های متداول',

      labels: {
        question: 'سوال',
        answer: 'جواب',
        delete: 'حذف سوال'
      },

      table: {
        header: {
          question: 'سوال',
          answer: 'جواب'
        }
      },

      confirmations: {
        delete: {
          title: 'تاییدیه حذف سوال',
          body: 'آیا از حذف موقت {name} اطمینان دارید؟'
        }
      },

      insert: {
        title: 'افزودن سوالات متداول'
      },

      edit: {
        title: 'ویرایش سوالات متداول'
      }
    },

    rules: {
      title: 'قوانین و مقررات',

      labels: {
        rules: 'قوانین و مقررات'
      }
    },

    access: {
      title: 'سطوح دسترسی',

      notifications: {
        insert: {
          success: 'گروه کاربری با موفقیت اضافه شد',
          error: 'افزودن گروه کاربری با خطا همراه شد'
        },

        edit: {
          success: 'گروه کاربری با موفقیت ویرایش شد',
          error: 'ویرایش گروه کاربری با خطا همراه شد'
        },

        delete: {
          success: 'گروه کاربری با موفقیت حذف شد',
          error: 'گروه کاربری مورد نظر یافت نشد.',
          cantDelete: 'گروه کاربری مورد نظر به دلیل داشتن زیر گروه, قابل حذف نمی باشد.'
        }
      },

      confirmations: {
        delete: {
          title: 'حذف گروه کاربری',
          body: 'آیا از حذف  {name} اطمینان دارید؟'
        }
      },

      insert: {
        title: 'افزودن گروه کاربری جدید',

        labels: {
          name: 'عنوان گروه کاربری ',
          access: 'دسترسی',
          insert: 'افزودن',

          accessList: {
            manager: 'دسترسی مدیرکل',

            users: {
              totalAccess: 'دسترسی کامل اشخاص',
              show: 'مشاهده اشخاص',
              insert: 'افزودن شخص',
              update: 'ویرایش کامل',
              delete: 'حذف موقت  شخص',
              setRole: 'اختصاص نقش به شخص'
            },

            roles: 'دسترسی کامل نقش ها',
            treasury: {
              title: 'دسترسی کامل خزانه داری',

              bank: 'دسترسی کامل بانک ها',
              cash: 'دسترسی کامل صندوق ها'
            },

            reports: {
              title: 'دسترسی کامل گزارشات',
              logs: 'دسترسی لاگ ها'
            },

            delete: {
              title: 'حذف دائم',

              user: 'اشخاص',
              banks: 'بانک ها',
              cash: 'صندوق ها'
            }
          }
        },

        validators: {
          name: 'نام گروه به صورت صحیح وارد نشده است',
          nameCantEmpty: 'نام نمی تواند  خالی باشد',
          access: 'هیچ دسترسی انتخاب نشده است'
        }
      },

      edit: {
        title: 'ویرایش گروه کاربری'
      },

      table: {

        header: {
          row: 'ردیف',
          name: 'عنوان دسترسی'
        }
      },

      actions: {
        insert: 'افزودن',
        save: 'ثبت'
      }
    },

    users: {
      title: 'تنظیمات اشخاص',

      labels: {
        gender: 'اجباری بودن جنسیت'
      }
    },

    security: {
      title: 'امنیت',

      labels: {
        disActive: 'مدت زمان خروج اجباری در صورت نداشتن فعالیت',
        minute: 'دقیقه'
      }
    },

    customize: {
      title: 'شخصی سازی سامانه',

      labels: {
        theme: 'انتخاب تم مورد نظر'
      }
    },

    financialYear: {
      title: 'سال مالی',

      statusTypes: {
        all: 'همه',
        active: 'فعال',
        disActive: 'غیر فعال'
      },

      table: {
        header: {
          rowNumber: 'ردیف',
          name: 'نام',
          startDate: 'تاریخ شروع',
          endDate: 'تاریخ پایان',
          status: 'وضعیت',
          show: 'مشاهده'
        }
      },

      notifications: {
        isActived: 'سال مالی مورد نظر قبلا فعال شده است',

        insert: {
          success: 'سال مالی جدید با موفقیت ثبت شد',
          error: 'ایجاد سال مالی جدید با خطا همراه شد'
        },

        edit: {
          success: 'ویرایش سال مالی با موفقیت انجام شد',
          error: 'ویرایش سال مالی با خطا همراه شد'
        },

        switch: {
          success: 'مشاهده سال مالی با موفقیت انجام شد',
          error: 'مشاهده سال مالی مورد نظر با خطا همراه شد'
        },

        parseError: {
          period: 'بازه زمانی سال مالی انتخاب شده معتبر نیست',
          paymentGateways: 'موجودی تعدادی از درگاه های پرداخت اعلام وصول نشده است!',
          storeroom: 'تعدادی از حواله های انبار تایید نشده اند!',
          periodNotEnded: 'سال مالی فعلی به اتمام نرسیده است!'
        }
      },

      confirmations: {
        insert: {
          title: 'تاییدیه ایجاد سال مالی جدید',
          body: 'آیا از ایجاد سال مالی جدید اطمینان دارید؟'
        },

        show: {
          title: 'تاییدیه مشاهده سال مالی',
          body: 'آیا از مشاهده سال مالی {name} اطمینان دارید؟'
        }
      },

      edit: {
        title: 'پیکره بندی سال مالی',

        periodTypes: {
          3: '3 ماهه',
          6: '6 ماهه',
          12: '1 ساله'
        },

        labels: {
          name: 'نام',
          startDate: 'تاریخ شروع',
          endDate: 'تاریخ پایان',
          period: 'مدت بازه سال مالی'
        }
      }
    },

    financial: {
      title: 'مالی',

      wage: 'کارمزد',
      NegativeValue: 'بانک و صندوق قابلیت منفی شدن داشته باشد',

      labels: {
        cardToCardWage: 'کارمزد کارت به کارت',
        ibanWage: 'کارمزد شبا'
      },

      notifications: {
        negativeBalanceError: 'به دلیل منفی بودن موجودی تعدادی از بانک ها و یا صندوق ها، ذخیره تنظیمات مورد نظر با خطا همراه شد',
        wrongValues: 'اطلاعات وارد شده معتبر نمیباشد',

        edit: {
          success: 'اطلاعات مالی با موفقیت بروز شد',
          error: 'بروزرسانی اطلاعات مالی با خطا همراه شد'
        }
      }
    },

    General: {
      title: 'عمومی',
      logo: {
        rule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت'
      },
      club: {
        code: 'کد باشگاه',
        name: 'نام باشگاه',
        managerName: 'نام و نام خانوادگی مدیر',
        address: 'آدرس',
        phoneNumber: 'شماره تماس',
        telSupport: 'شماره تماس پشتیبانی',
        whatsAppSupport: 'شماره واتس اپ پشتیبانی',
        managerPhoneNumber: 'شماره تماس مدیر'
      }
    },

    Treasury: {
      title: 'اطلاعات خزانه',

      labels: {
        rule: 'قوانین',
        treasuryCanNegative: 'خزانه قابلیت منفی شدن دارد.',
        NegativeValue: 'بانک و صندوق قابلیت منفی شدن داشته باشد'
      }
    },

    Locale: {
      title: 'زبان ها',
      language: 'زبان',

      labels: {
        basicSetting: 'تنظیمات پایه',
        defaultLanguage: 'زبان پیشفرض شرکت'
      }
    },

    Store: {
      title: 'انبار'
    },

    Buy: {
      title: 'خرید'
    },

    Sale: {
      title: 'فروش'
    }
  },

  // Accountancy Pages
  accountancy: {
    title: 'حسابداری',

    types: {
      title: 'درختواره حساب ها',

      labels: {
        name: 'عنوان سرفصل'
      },

      notifications: {
        insert: {
          success: 'سرفصل موردنظر با موفقیت ایجاد شد',
          error: 'ثبت اطلاعات سرفصل جدید با خطا همراه شد'
        },

        edit: {
          success: 'سرفصل موردنظر با موفقیت ایجاد شد',
          error: 'بروزرسانی اطلاعات سرفصل با خطا همراه شد'
        },

        delete: {
          success: 'سرفصل با موفقیت حذف شد',
          error: 'حذف سرفصل با خطا همراه شد'
        },

        parseError: {
          parent: 'سرفصل والد انتخاب نشده است',
          name: 'عنوان سرفصل وارد نشده است'
        }
      },

      confirmations: {
        delete: {
          title: 'حذف سرفصل',
          body: 'آیا از حذف سرفصل  {name} اطمینان دارید؟'
        }
      },

      insert: {
        title: 'افزودن سرفصل جدید'
      },

      edit: {
        title: 'ویرایش سرفصل'
      },

      users: {
        title: '',
        dynamicTitle: '  لیست اعضای سطح دسترسی {name}'
      }
    },

    openingBalance: {
      title: 'تراز افتتاحیه',

      labels: {
        assets: 'دارایی اول دوره',
        liabilities: 'بدهی اول دوره',
        equity: 'سرمایه اول دوره'
      },

      notifications: {
        locked: 'اول دوره قفل می باشد',
        unLocked: 'اول دوره قفل نمی باشد',
        lock: {
          success: 'ترازنامه با موفقیت قفل شد',
          error: 'قفل شدن ترازنامه با خطا مواجه شد'
        },
        unlock: {
          success: 'ترازنامه با موفقیت باز شد',
          error: 'باز شدن ترازنامه با خطا مواجه شد'
        }
      },

      confirmations: {
        unlock: {
          title: 'تائیدیه بازکردن تراز افتتاحیه',
          body: 'آیا از باز کزدن تراز افتتاحیه اطمینان دارید؟'
        }
      }
    },

    documents: {
      title: 'لیست اسناد',

      notifications: {
        delete: {
          success: 'اطلاعات سند مورد نظر با موفقیت حذف شد',
          error: 'حذف اطلاعات سند مورد نظر با موفقیت حذف شد'
        }
      },

      document: {
        title: 'سند',

        table: {
          header: {
            rowNumber: 'ردیف',
            description: 'شرح',
            debt: 'بدهکار',
            credit: 'بستانکار'
          }
        }
      },

      print: {
        cost: {
          costReceipt: 'رسید هزینه',
          date: 'تاریخ:',
          docNumber: 'شماره سند:',
          nameAndSignature: 'نام و امضاء',
          receiver: 'دریافت کننده',
          payer: 'پرداخت کننده',
          confirmer: 'تایید کننده',
          print: 'پرینت',
          back: 'بازگشت',
          bank: 'بانک',
          cashBox: 'صندوق',
          storeroom: 'انبار',
          paymentGateway: 'درگاه پرداخت',

          description: {
            confirm: 'بدینوسیله تایید میگیردد',
            price: 'مبلغ (به عدد): ',
            toText: '(به حروف): ',
            account: 'به سرفصل ',
            inDate: 'در تاریخ ',
            description: 'بابت ',
            payer: 'توسط ',
            payed: 'پرداخت گردید'
          }
        },

        receive: {
          receiveReceipt: 'رسید دریافت وجه',
          date: 'تاریخ:',
          docNumber: 'شماره سند:',
          nameAndSignature: 'نام و امضاء',
          receiver: 'دریافت کننده',
          payer: 'پرداخت کننده',
          confirmer: 'تایید کننده',
          print: 'پرینت',
          back: 'بازگشت',
          bank: 'بانک',
          cashBox: 'صندوق',
          storeroom: 'انبار',
          paymentGateway: 'درگاه پرداخت',
          transactionId: 'شماره تراکنش:',
          paymentConfirm: 'تاییدیه پرداخت:',

          description: {
            confirm: 'بدینوسیله تایید میگیردد',
            price: 'مبلغ (به عدد): ',
            toText: '(به حروف): ',
            payee: 'از آقا/خانم ',
            inDate: 'در تاریخ ',
            description: 'بابت ',
            payer: 'توسط ',
            payed: 'دریافت گردید'
          }
        },

        payment: {
          paymentReceipt: 'رسید پرداخت وجه',
          date: 'تاریخ:',
          docNumber: 'شماره سند:',
          nameAndSignature: 'نام و امضاء',
          receiver: 'دریافت کننده',
          payer: 'پرداخت کننده',
          confirmer: 'تایید کننده',
          print: 'پرینت',
          back: 'بازگشت',
          bank: 'بانک',
          cashBox: 'صندوق',
          storeroom: 'انبار',
          paymentGateway: 'درگاه پرداخت',

          description: {
            confirm: 'بدینوسیله تایید میگیردد',
            price: 'مبلغ (به عدد): ',
            toText: '(به حروف): ',
            payee: 'به آقا/خانم ',
            inDate: 'در تاریخ ',
            description: 'بابت ',
            payer: 'توسط ',
            payed: 'پرداخت گردید'
          }
        },

        internalTransfer: {
          internalTransferReceipt: 'رسید انتقالی خزانه',
          date: 'تاریخ:',
          docNumber: 'شماره سند:',
          nameAndSignature: 'نام و امضاء',
          receiver: 'دریافت کننده',
          payer: 'پرداخت کننده',
          confirmer: 'تایید کننده',
          print: 'پرینت',
          back: 'بازگشت',
          bank: 'بانک',
          cashBox: 'صندوق',
          storeroom: 'انبار',
          paymentGateway: 'درگاه پرداخت',

          description: {
            confirm: 'بدینوسیله تایید میگیردد',
            price: 'مبلغ (به عدد): ',
            toText: '(به حروف): ',
            inDate: 'در تاریخ ',
            description: 'بابت ',
            payer: 'توسط ',
            payee: 'به ',
            payed: 'پرداخت گردید'
          }
        }
      },

      list: {
        title: 'لیست اسناد',

        table: {
          header: {
            id: 'شماره سند',
            createdAt: 'تاریخ',
            creator: 'ایجاد کننده',
            description: 'مستندات',
            type: 'نوع'
          },

          filters: {
            type: {
              all: 'همه',
              cash: 'دستی',
              receive: 'دریافت',
              payment: 'پرداخت',
              withdrawal: 'برداشت',
              transfer: 'انتقال',
              income: 'درامد',
              cost: 'هزینه',
              openingInventory: 'اول دوره',
              salary: 'حقوق',
              purchase: 'خرید',
              returnPurchase: 'مرجوع خرید',
              sale: 'فروش',
              returnSale: 'مرجوع فروش',
              storeReceipt: 'رسید انبار',
              storeSend: 'خروج انبار'
            }
          }
        },

        confirmation: {
          deleteDocument: {
            title: 'تائیدیه حذف سند',
            body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
          }
        }
      },

      insert: {
        title: 'افزودن سند',
        dynamicTitle: 'افزودن سند دریافت/پرداخت',

        labels: {
          documentType: 'نوع سند',
          receive: 'دریافت',
          payment: 'پرداخت',
          paymentTo: 'پرداخت به',
          receiveFrom: 'دریافت از',
          withdrawalFrom: 'برداشت از',
          depositTo: 'واریز به',
          price: 'مبلغ',
          cashBox: 'صندوق',
          bank: 'بانک',
          depositType: 'نوع واریز',
          choose: 'انتخاب کنید',
          pos: 'POS',
          cardToCard: 'کارت به کارت',
          shaba: 'شبا'
        },

        notifications: {

          receive: {
            insert: {
              success: 'سند دریافت با موفقیت ایجاد شد',
              error: 'ایجاد سند دریافت با خطا همراه شد'
            }
          },

          payment: {
            insert: {
              success: 'سند پرداخت با موفقیت ایجاد شد',
              error: 'ایجاد سند پرداخت با خطا همراه شد'
            }
          },

          parseError: {
            user: 'کاربر انتخاب نشده است',
            cashBox: 'صندوق انتخاب نشده است',
            bank: 'بانک انتخاب نشده است',
            price: 'مبلغ وارد نشده است',
            depositTo: 'واریز به بانک یا صندوق مشخص نشده است',
            depositType: 'نوع واریز مشخص نشده است',
            withdrawalFrom: 'برداشت از بانک یا صندوق مشخص نشده است'
          }
        }
      },

      payment: {
        title: 'پرداخت به تامین کننده'

      },

      receive: {
        title: 'دریافت از تامین کننده'

      },

      transactions: {
        title: 'گزارشات تامین کننده'

      },

      actions: {
        insert: 'افزودن',
        edit: 'ویرایش',
        save: 'ثبت',
        delete: 'حذف'
      }
    },

    employees: {
      title: 'کارکنان',

      insert: {
        title: 'افزودن کارمند',
        gender: 'جنسیت',
        man: 'مرد',
        woman: 'زن',
        name: 'نام',
        lastName: 'نام خانوادگی',
        phoneNumber: 'شماره تماس',
        group: 'گروه',
        balance: 'اعتبار (تومان)',
        save: 'ثبت',
        delete: 'حذف'
      },

      profile: {
        title: 'پروفایل کارمند',
        creditAmount: 'میزان اعتبار',
        debtAmount: 'میزان بدهی',
        userContactTitle: 'اطلاعات تماس کاربر',
        userContactContent: 'در این باکس اطلاعات تماس کاربر نمایش داده می شود.',
        phoneNumber: 'شماره تماس',
        address: 'آدرس',
        userResponsibilityTitle: 'اطلاعات مسئولیت کاربر',
        userResponsibilityContent: 'در این باکس اطلاعات مسئولیت[های] کاربر نمایش داده می شود.',
        ResponsibilityGroup: 'گروه مسئولیت',
        reports: 'گزارشات',
        paymentToEmployee: 'پرداخت به کارمند',
        receiveFromEmployee: 'دریافت از کارمند',
        salaryReceipt: 'فیش حقوقی کارمند'
      },

      edit: {
        title: 'ویرایش کارمند'

      },

      payment: {
        title: 'پرداخت به کارمند'

      },

      receive: {
        title: 'دریافت از کارمند'

      },

      transactions: {
        title: 'گزارشات کارمند'

      },

      salaryReceipt: {
        title: 'فیش حقوقی کارمند',
        price: 'مبلغ(تومان)',
        description: 'توضیحات'
      },

      actions: {
        insert: 'افزودن',
        edit: 'ویرایش',
        save: 'ثبت',
        delete: 'حذف'
      }
    },

    shareHolders: {
      title: 'سهامداران',

      insert: {
        title: 'افزودن سهامدار',
        gender: 'جنسیت',
        man: 'مرد',
        woman: 'زن',
        name: 'نام',
        lastName: 'نام خانوادگی',
        phoneNumber: 'شماره تماس',
        group: 'گروه',
        balance: 'اعتبار (تومان)',
        save: 'ثبت',
        delete: 'حذف'
      },

      profile: {
        title: 'پروفایل سهامدار',
        creditAmount: 'میزان اعتبار',
        debtAmount: 'میزان بدهی',
        userContactTitle: 'اطلاعات تماس کاربر',
        userContactContent: 'در این باکس اطلاعات تماس کاربر نمایش داده می شود.',
        phoneNumber: 'شماره تماس',
        address: 'آدرس',
        userResponsibilityTitle: 'اطلاعات مسئولیت کاربر',
        userResponsibilityContent: 'در این باکس اطلاعات مسئولیت[های] کاربر نمایش داده می شود.',
        ResponsibilityGroup: 'گروه مسئولیت',
        reports: 'گزارشات',
        paymentToShareHolder: 'پرداخت به سهامدار',
        receiveFromShareHolder: 'دریافت از سهامدار'
      },

      edit: {
        title: 'ویرایش سهامدار'

      },

      payment: {
        title: 'پرداخت به سهامدار'

      },

      receive: {
        title: 'دریافت از سهامدار'

      },

      transactions: {
        title: 'گزارشات سهامدار'

      },

      actions: {
        insert: 'افزودن',
        edit: 'ویرایش',
        save: 'ثبت',
        delete: 'حذف'
      }
    },

    table: {

      header: {
        row: 'ردیف',
        select: 'انتخاب',
        image: 'عکس پرسنلی',
        nationalCode: 'کد ملی',
        sportInsurance: 'بیمه ورزشی',
        email: 'ایمیل',
        city: 'استان / شهر',
        registryDate: 'تاریخ عضویت',
        phoneNumber: 'تلفن همراه',
        gender: 'جنسیت',
        name: 'نام و نام خانوادگی',
        creator: 'ایجاد کننده',
        firstName: 'نام',
        lastName: 'نام خانوادگی',
        score: 'امتیاز',
        group: 'گروه',
        company: 'شرکت',
        accessGroup: 'گروه کاربری',
        maxDebt: 'سقف بدهکاری',
        balance: 'کیف پول',
        address: 'آدرس ها',
        access: 'دسترسی ها',
        status: 'وضعیت'
      }
    },

    transactions: {
      title: 'گزارشات شخص',

      table: {

        header: {
          docNumber: 'شماره سند',
          date: 'تاریخ',
          documentation: 'مستندات',
          price: 'مبلغ',
          balance: 'موجودی کیف پول'
        }
      }
    },

    actions: {
      insert: 'افزودن',
      edit: 'ویرایش',
      save: 'ثبت',
      delete: 'حذف'
    }
  },

  // Users Page
  users: {
    title: 'مشتریان',

    table: {
      header: {
        row: 'ردیف',
        select: 'انتخاب',
        image: 'عکس پرسنلی',
        email: 'ایمیل',
        city: 'استان / شهر',
        registryDate: 'تاریخ عضویت',
        phoneNumber: 'تلفن همراه',
        gender: 'جنسیت',
        name: 'نام و نام خانوادگی',
        creator: 'ایجاد کننده',
        firstName: 'نام',
        lastName: 'نام خانوادگی',
        score: 'امتیاز',
        group: 'گروه مشتری',
        company: 'شرکت',
        accessGroup: 'گروه کاربری',
        maxDebt: 'سقف بدهکاری',
        balance: 'مانده کیف پول',
        address: 'آدرس ها',
        access: 'دسترسی ها',
        status: 'وضعیت',
        restore: 'بازگردانی',
        delete: 'حذف'
      }
    },

    genderTypes: {
      all: 'همه',
      man: 'آقا',
      dynamicMan: 'آقای ',
      woman: 'خانم '
    },

    statusTypes: {
      noSignUp: 'ثبت نام نشده',
      signUp: 'شروع ثبت نام',
      confirmPhone: 'تایید شماره همراه',
      complete: 'تکمیل شده'
    },

    user: {
      title: 'اشخاص',

      labels: {
        all: 'همه',
        unknown: 'نامشخص',
        bySystem: 'سیستمی',
        role: 'سطح دسترسی',
        none: 'هیچکدام',
        character: 'گروه مشتری',
        gender: 'جنسیت',
        name: 'نام',
        family: 'نام خانوادگی',
        phoneNumber: 'شماره همراه',
        delete: 'حذف کاربر',
        customer: 'مشتری',
        maxDebt: 'حداکثر بدهی کاربر',
        receive: 'دریافت',
        cancel: 'انصراف',
        notHave: 'ندارد'
      },

      notifications: {
        wrongValues: 'اطلاعات وارد شده صحیح نمی باشد',
        hasBalance: 'شخص دارای مانده کیف پول می باشد!',
        noAccessMaxDebt: 'شما دسترسی اختصاص اعتبار به اشخاص را ندارید!',

        insert: {
          success: 'اطلاعات کاربر با موفقیت ثبت شد',
          error: 'ثبت اطلاعات کاربر با خطا مواجه شد'
        },

        edit: {
          success: 'اطلاعات کاربر با موفقیت بروزرسانی شد',
          error: 'ویرایش اطلاعات کاربر با خطا مواجه شد'
        },

        delete: {
          success: 'اطلاعات کاربر مورد نظر حذف شد',
          error: 'حذف اطلاعات کاربر با خطا همراه شد',
          isUsedError: 'کاربر مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
        },

        restore: {
          success: 'کاربر با موفقیت بازگردانده شد',
          error: 'بازگردانی مشتری با خطا همراه شد'
        },

        parseError: {
          name: 'نام انتخاب شده معتبر نیست!',
          family: 'نام خانوادگی انتخاب شده معتبر نیست!',
          nationalCode: 'کد ملی وارد شده معتبر نیست!',
          birthDate: 'تاریخ تولد انتخاب شده معتبر نیست!',
          gender: 'جنسیت انتخاب شده معتبر نیست!',

          // todo
          phoneNumber: 'شماره تلفن وارد شده معتبر نیست و یا قبلا استفاده شده است!',
          email: 'ایمیل وارد شده معتبر نیست!',
          character: 'گروه مشتری انتخاب شده معتبر نیست!',
          role: 'سطح دسترسی انتخاب شده معتبر نیست!'
        }
      },

      validators: {
        name: 'نام باید فارسی باشد!',
        family: 'نام خانوادگی باید فارسی باشد!',
      },

      confirmations: {
        delete: {
          title: 'تاییدیه حذف کاربر',
          body: 'آیا از حذف موقت {name} اطمینان دارید؟'
        },

        forceDelete: {
          title: 'تاییدیه حذف کاربر',
          body: 'آیا از حذف دائم {name} اطمینان دارید؟'
        }
      },

      list: {
        title: 'لیست اشخاص'
      },

      insert: {
        title: 'افزودن کاربر جدید',

        gender: 'جنسیت',
        man: 'مرد',
        woman: 'زن',
        name: 'نام',
        lastName: 'نام خانوادگی',
        phoneNumber: 'شماره تماس',
        group: 'گروه',
        balance: 'اعتبار (تومان)',
        save: 'ثبت',
        delete: 'حذف'
      },

      edit: {
        dynamicTitle: 'ویرایش اطلاعات {name}'
      },

      profile: {
        title: 'پروفایل شخص',
        dynamicTitle: 'پروفایل {name}',

        actions: {
          transactions: 'گردش کیف پول',
          maxDebt: 'سقف بدهکاری',
          invoices: 'فاکتورهای شخص',
          coachClasses: 'کلاس های مربی',
          reserve: 'رزرو تایم',
          reserveSeason: 'رزرو فصلی',
          receive: 'دریافت وجه از مشتری'
        },

        asiderAvatar: {
          phoneNumber: 'شماره تماس: ',
          character: 'گروه مشتری: ',
          todayDebt: 'بدهی امروز: '
        }
      },

      maxDebt: {
        title: '',
        dynamicTitle: 'تعیین حداکثر بدهی {name}'
      }
    },

    select: {
      title: 'لیست اشخاص'
    },

    customers: {
      title: 'اشخاص',

      list: {
        title: 'لیست اشخاص'
      },

      insert: {
        title: 'افزودن مشتری',
      },

      profile: {
        title: 'پروفایل مشتری',
      },

      edit: {
        title: 'ویرایش مشتری'

      }
    },

    openingBalance: {
      type: {
        debtor: 'بدهکاران به شرکت',
        creditor: 'بستانکاران از شرکت'
      },

      insert: {
        title: 'افزودن اول دوره {type}',

        notifications: {
          debtor: {
            success: {
              title: 'پیغام',
              message: 'اول دوره اشخاص بدهکار با موفقیت ثبت شد'
            },

            error: {
              title: 'خطا',
              message: 'ثبت اطلاعات اول دوره کاربران بدهکار با خظا همراه شد'
            }
          },

          creditor: {
            success: {
              title: 'پیغام',
              message: 'اول دوره اشخاص بستانکار با موفقیت ثبت شد'
            },

            error: {
              title: 'خطا',
              message: 'ثبت اطلاعات اول دوره کاربران بستانکار با خظا همراه شد'
            }
          },

          locked: {
            title: 'خطا',
            message: 'اول دوره قفل است'
          },

          isExists: {
            title: 'خطا',
            message: 'این مقدار در لیست وجود دارد',
            dynamicMessage: '{name} در لیست وجود دارد'
          }
        }
      },

      edit: {
        title: 'ویرایش اول دوره {user}',

        labels: {
          price: 'قیمت',
          delete: 'حذف اول دوره'
        },

        confirmations: {
          delete: {
            title: 'تائیدیه حذف',
            body: 'آیا از حذف اول دوره شخص مورد نظر اطمینان دارید؟'
          }
        },

        notifications: {
          debtor: {
            update: {
              success: {
                title: 'پیغام',
                message: 'اول دوره شخص بدهکار مورد نظر با موفقیت بروزرسانی شد'
              },

              error: {
                title: 'خطا',
                message: 'بروزرسانی اول دوره شخص بدهکار مورد نظر با خطا همراه شد'
              }
            },

            delete: {
              success: {
                title: 'پیغام',
                message: 'اول دوره شخص بدهکار مورد نظر با موفقیت حذف شد'
              },

              error: {
                title: 'خطا',
                message: 'حذف اول دوره شخص بدهکار مورد نظر با خطا همراه شد'
              }
            }
          },

          creditor: {
            update: {
              success: {
                title: 'پیغام',
                message: 'اول دوره شخص بستانکار مورد نظر با موفقیت بروزرسانی شد'
              },

              error: {
                title: 'خطا',
                message: 'بروزرسانی اول دوره شخص بستانکار مورد نظر با خطا همراه شد'
              }
            },

            delete: {
              success: {
                title: 'پیغام',
                message: 'اول دوره شخص بستانکار مورد نظر با موفقیت حذف شد'
              },

              error: {
                title: 'خطا',
                message: 'حذف اول دوره شخص بستانکار مورد نظر با خطا همراه شد'
              }
            }
          }
        }
      },

      list: {
        table: {
          header: {
            delete: 'حذف',
            price: 'مبلغ',
            name: 'نام و نام خانوادگی',
            rowNumber: 'ردیف'
          }
        }
      }
    },

    transactions: {
      title: 'گردش کیف پول شخص',
      dynamicTitle: 'گردش کیف پول {name}',

      table: {
        header: {
          docNumber: 'شماره سند',
          date: 'تاریخ',
          documentation: 'مستندات',
          price: 'مبلغ',
          balance: 'موجودی کیف پول'
        }
      },

      types: {
        sale: 'فروش',
        purchase: 'خرید',
        receive: 'دریافت'
      },

      notifications: {
        print: {
          error: 'درخواست پرینت با خطا شد'
        }
      }
    },

    invoices: {
      title: 'لیست فاکتورهای شخص',
      dynamicTitle: 'لیست فاکتورهای {name}',

      labels: {
        until: ' تا ',
        active: 'فعال',
        passed: 'گذشته',
        reserved: 'رزرو شده',
        canceled: 'لغو شده',
        fromNow: 'امروز به بعد'
      },

      table: {
        header: {
          docNumber: 'شماره فاکتور',
          creator: 'ایجادکننده',
          reserveDate: 'تاریخ رزرو',
          date: 'تاریخ',
          time: 'ساعت رزرو',
          courtName: 'زمین رزرو',
          finalPrice: 'مبلغ نهایی',
          status: 'وضعیت',
        }
      },

      statusTypes: {
        all: 'همه',
        active: 'رزرو فعال',
        activePassed: 'رزرو استفاده شده',
        canceled: 'لغو شده',
        canceledBySystem: 'لغو شده سیستمی'
      },

      processTypes: {
        preInvoice: 'پیش فاکتور فروش',
        invoice: 'فاکتور فروش'
      },

      financialStatusTypes: {
        debtor: 'بدهکار',
        checkout: 'تسویه'
      },

      actionTypes: {
        present: 'فروش حضوری',
        tel: 'فروش تلفنی',
        internet: 'فروش اینترنتی'
      }
    },

    seasonSchedules: {
      title: 'لیست رزرو های فصلی',
      dynamicTitle: 'لیست رزرو های فصلی {gender} {name}',

      table: {
        header: {
          row: 'ردیف',
          creator: 'ایجادکننده',
          quantity: 'تعداد فاکتورها',
          startDate: 'بازه شروع',
          endDate: 'بازه پایان',
          createdAt: 'تاریخ ایجاد'
        }
      }
    },

    actions: {
      insert: 'افزودن',
      edit: 'ویرایش',
      save: 'ثبت',
      delete: 'حذف'
    }
  },

  // documents page
  documents: {
    title: 'اسناد',

    document: {
      title: 'سند',

      table: {

        header: {
          row: 'ردیف',
          docNumber: 'شماره سند',
          date: 'تاریخ',
          reference: 'مرجع',
          details: 'تفصیل',
          price: 'مبلغ',
          type: 'نوع'
        }
      }
    }
  },

  //sales
  sales: {
    title: 'فروش',

    addons: {
      title: 'افزودنی های رزرو',

      labels: {
        all: 'همه',
        until: ' تا ',
        required: 'اجباری است',
        price: 'قیمت',
        isRequired: 'است',
        notRequired: 'نیست',
        name: 'نام',
        maxSelect: 'حداکثر تعداد انتخاب (درصورت خالی بودن نامحدود است)',
        priceBaseCharacter: 'قیمت گذاری براساس گروه مشتری',
        delete: 'حذف افزودنی رزرو'
      },

      invalidTexts: {
        name: 'نام باید فارسی باشد!'
      },

      notifications: {
        addonIsUsed: 'اطلاعات افزودنی رزرو در جایی استفاده شده است!',

        insert: {
          success: 'اطلاعات افزودنی رزرو با موفقیت ثبت شد',
          error: 'ثبت اطلاعات افزودنی رزرو با خطا مواجه شد'
        },

        edit: {
          success: 'اطلاعات افزودنی رزرو با موفقیت ویرایش شد',
          error: 'ویرایش اطلاعات افزودنی رزرو با خطا مواجه شد'
        },

        delete: {
          success: 'اطلاعات افزودنی رزرو مورد نظر حذف شد',
          error: 'حذف اطلاعات افزودنی رزرو با خطا همراه شد',
          isUsedError: 'افزودنی مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
        },

        restore: {
          success: 'افزودنی رزرو با موفقیت بازگردانده شد',
          error: 'بازگردانی افزودنی رزرو با خطا همراه شد'
        },

        parseError: {
          name: 'نام انتخاب شده معتبر نیست!',
          price: 'مبلغ انتخاب شده معتبر نیست!',
          character: 'گروه مشتری انتخاب شده معتبر نیست!',
          maxSelect: 'حداکثر تعداد انتخاب معتبر نیست!'
        }
      },

      validators: {
        name: 'نام وارد شده صحیح نمی باشد',
        maxSelect: 'حداکثر تعداد انتخاب وارد شده صحیح نمی باشد',
        noCharacter: 'گروه مشتری انتخاب نشده است',
        price: 'قیمت گروه {name} درست وارد نشده است'
      },

      confirmations: {
        delete: {
          title: 'تاییدیه حذف افزودنی رزرو',
          body: 'آیا از حذف موقت {name} اطمینان دارید؟'
        },

        forceDelete: {
          title: 'تاییدیه حذف افزودنی رزرو',
          body: 'آیا از حذف دائم {name} اطمینان دارید؟'
        }
      },

      list: {
        title: 'لیست خدمات',

        table: {
          header: {
            row: 'ردیف',
            name: 'نام',
            character: 'گروه مشتری',
            price: 'مبلغ',
            delete: 'حذف',
            restore: 'بازگردانی'
          }
        }
      },

      insert: {
        title: 'افزودن افزودنی رزرو جدید',
      },

      edit: {
        title: '',
        dynamicTitle: 'ویرایش اطلاعات {name}'
      },

      profile: {
        title: '',
        dynamicTitle: 'حساب درآمد {name}',

        table: {
          header: {
            name: 'نام',
            required: 'اجباری',
            price: 'مبلغ',
          }
        }
      }
    },

    discount: {
      title: 'تخفیفات',

      labels: {
        discount: 'تخفیف',
        percent: 'درصد'
      },

      table: {
        header: {
          name: 'نام گروه'
        }
      },

      notifications: {
        wrongValue: 'مقدار وارد شده معتبر نیست',

        edit: {
          success: 'تخفیفات با موفقیت ویرایش شدند',
          error: 'ویرایش تخفیفات با خطا همراه شد!'
        }
      },

      edit: {
        title: 'ویرایش تخفیفات',
        dynamicModalTitle: 'میزان تخفیف برای {character} در سانس {term}'
      }
    },

    invoices: {
      title: 'لیست رزرو ها',

      labels: {
        all: 'همه',
        id: 'شناسه فاکتور',
        user: 'طرف حساب',
        status: 'وضعیت',
        actionType: 'روش فروش',
        date: 'تاریخ ثبت',
        userInfo: 'اطلاعات طرف حساب',
        reserveInfo: 'اطلاعات رزرو',
        addonInfo: 'افزودنی های رزرو',
        totalPrice: 'مبلغ کل',
        totalDiscount: 'تخفیف کل',
        discount: 'تخفیف',
        reject: 'عودت',
        finalPrice: 'مبلغ نهایی',
        until: ' تا ',
        active: 'فعال',
        passed: 'گذشته',
        reserved: 'رزرو شده',
        canceled: 'لغو شده',
        fromNow: 'امروز به بعد',
        gender: 'جنسیت: ',
        fullName: 'نام و نام خانوادگی: ',
        phoneNumber: 'تلفن همراه: ',
        courtName: 'نام زمین: ',
        reserveTime: 'زمان رزرو: ',
        price: 'قیمت: ',
        required: '(اجباری)',
        lastWeek: 'هفته قبل',
        futureWeek: 'هفته بعد',
        timeCourt: 'تایم / زمین',
        confirm: 'لیست تایید رزرو',
        selectTime: 'انتخاب تایم',

        // todo
        coach: 'مربی',
        coachInfo: 'اطلاعات مربی',
        coachPrice: 'حق الزحمه مربی'
      },

      placeholder: {
        id: 'اتوماتیک'
      },

      notifications: {
        coachCantSelect: 'مربی انتخاب شده در تایم های مشخص شده با رنگ قرمز، قابل رزرو نمی باشد',
        coachCantSelectEdit: 'مربی انتخاب شده در تایم مورد نظر قابل رزرو نمی باشد',
        lowMoney: 'موجودی شما برای رزرو کافی نیست!',
        lowMoneyForAddon: 'موجودی شما برای رزرو افزودنی کافی نیست!',
        noTimeExist: 'تعدادی از تایم های مورد نظر موجود نمی باشد!',
        timesReserved: 'تعدادی از تایم های انتخاب شده قبلا رزرو شده اند!',
        timesCanceled: 'تعدادی از تایم های انتخاب شده کنسل شده اند!',
        addonCanceled: 'افزودنی مورد نظر کنسل شده است!',
        cantDeleteAddon: 'اضافات اجباری رزرو قابل حذف نیست!',
        cantDeleteCourt: 'زمین رزرو قابل حذف نیست!',
        addonExist: 'افزودنی {name} قبلا انتخاب شده است!',
        maxAddon: 'تعداد {name} حداکثر میتواند {count} باشد.',
        discountIsBigger: 'تخفیف {name} از مبلغ کل بیشتر است!',
        cantDelete: 'این فاکتور قابل حذف نیست!',
        userGroup: 'برای {name} هیچ گروهی ست نشده است!',
        discountsError: 'دریافت تخفیف های کاربر با خطا همراه شد!',

        insert: {
          success: 'فاکتور با موفقیت ثبت شد',
          error: 'ثبت فاکتور با خطا همراه شد!'
        },

        edit: {
          success: 'اطلاعات فاکتور رزرو با موفقیت بروزرسانی شد',
          error: 'ثبت فاکتور با خطا همراه شد!'
        },

        delete: {
          success: 'فاکتور با موفقیت حذف شد',
          error: 'حذف فاکتور رزرو با خطا همراه شد'
        },

        complete: {
          success: 'فاکتور با موفقیت ثبت شد',
          error: 'ثبت فاکتور با خطا همراه شد!'
        },

        insertAddon: {
          success: 'افزودنی با موفقیت اضافه شد!',
          error: 'ثبت فاکتور با خطا همراه شد!'
        },

        cancel: {
          success: 'فاکتور با موفقیت بازگردانی شد',
          error: 'درخواست بازگردانی فاکتور با خطا مواجه شد!'
        },

        sendMessage: {
          success: 'پیامک اطلاع رسانی رزرو به مشتری با موفقیت انجام شد',
          error: 'ارسال پیامک اطلاع رسانی رزرو به مشتری با خطا مواجه شد!'
        },

        cancelSaleAddon: {
          success: 'افزودنی با موفقیت لغو شد!',
          error: 'درخواست حذف افزودنی با خطا مواجه شد!'
        },

        parseError: {
          date: 'تاریخ انتخابی معتبر نیست!'
        }
      },

      validators: {
        user: 'طرف حساب انتخاب نشده است!',
        noTime: 'تایمی برای رزرو انتخاب نشده است!',
        emptyTime: 'با حذف این سفارش لیست رزرو شما خالی میشود!',
      },

      confirmations: {
        forceDelete: {
          title: 'تاییدیه حذف فاکتور رزرو',
          body: 'آیا از حذف دائم {name} اطمینان دارید؟'
        }
      },

      statusTypes: {
        all: 'همه',
        active: 'رزرو فعال',
        activePassed: 'رزرو استفاده شده',
        canceled: 'لغو شده',
        canceledBySystem: 'لغو شده سیستمی',
        confirmed: 'تایید شده نهایی'
      },

      processTypes: {
        preInvoice: 'پیش فاکتور فروش',
        invoice: 'فاکتور فروش'
      },

      financialStatusTypes: {
        debtor: 'بدهکار',
        checkout: 'تسویه'
      },

      actionTypes: {
        present: 'فروش حضوری',
        shortPresent: 'حضوری',
        tel: 'فروش تلفنی',
        shortTel: 'تلفنی',
        internet: 'فروش اینترنتی',
        shortInternet: 'اینترنتی'
      },

      calendar: {
        title: 'تقویم'
      },

      list: {
        title: 'لیست رزروها',

        table: {
          header: {
            docNumber: 'شماره فاکتور',
            creator: 'ایجادکننده',
            registryDate: 'تاریخ ایجاد',
            user: 'طرف حساب',
            reserveDate: 'تاریخ رزرو',
            time: 'ساعت رزرو',
            courtName: 'زمین رزرو',
            finalPrice: 'مبلغ نهایی',
            totalPrice: 'مبلغ کل',
            status: 'وضعیت',
            coach: 'مربی',
            delete: 'حذف',
            restore: 'بازگردانی'
          }
        }
      },

      insert: {
        title: 'افزودن رزرو',

        past: {
          title: 'افزودن فاکتور رزرو برای گذشته'
        },

        table: {
          header: {
            row: 'ردیف',
            name: 'رزرو',
            reservePrice: 'مبلغ رزرو',
            saleAddons: 'خدمات',

            // todo
            coachPrice: 'حق الزحمه مربی',
            totalPrice: 'مبلغ کل',
            discount: 'تخفیف کل',
            finalPrice: 'مبلغ نهایی',
            delete: 'حذف',
          }
        }
      },

      invoice: {
        title: 'فاکتور رزرو',
        dynamicTitle: 'فاکتور رزرو {type}',
        dynamicTitleWithId: 'فاکتور رزرو {type} شماره {id}',

        labels: {
          cancelReserve: 'لغو رزرو',
          cancelAddon: 'لغو افزودنی'
        },

        table: {
          header: {
            row: 'ردیف',
            name: 'نام',
            quantity: 'تعداد',
            unitPrice: 'مبلغ واحد',
            finalPrice: 'مبلغ کل'
          }
        },

        cancel: {
          title: 'روش کنسل کردن',

          byCustomer: 'از طرف مشتری',
          bySystem: 'از طرف سامانه',

          labels: {
            timeUntilReserve: 'کمتراز {time} ساعت مانده تا شروع تایم',
            timeRefund: 'هزینه عودت تایم: ',
            coachRefund: 'هزینه عودت مربی: ',
            addonRefund: 'هزینه عودت خدمات:',
            totalRefundPrice: 'جمعا: '
          }
        },

        confirmations: {
          delete: {
            title: 'تائیدیه حذف فاکتور رزرو',
            body: 'در صورت حذف فاکتور رزرو تمامی اسناد مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
          },

          cancelReserve: {
            title: 'تاییدیه لغو رزرو',
            body: 'آیا از لغو کردن رزرو مورد نظر اطمینان دارید ؟'
          },

          cancelAddon: {
            title: 'تاییدیه لغو افزودنی',
            body: 'آیا از لغو کردن افزودنی مورد نظر اطمینان دارید ؟'
          },

          sendMessage: {
            title: 'ارسال پیامک رزرو',
            body: 'آیا از ارسال پیامک اطلاع رسانی رزرو به مشتری اطمینان دارید؟'
          }
        }
      },

      edit: {
        title: 'ویرایش فاکتور رزرو',
        dynamicTitle: 'ویرایش فاکتور رزرو {type}',
      },

      receive: {
        title: 'افزودنی فاکتور رزرو',
        dynamicTitle: 'افزودنی فاکتور رزرو شماره {id}',

        labels: {
          payment: 'پرداخت',
          receiveAndReserve: 'دریافت و رزرو',
          cancel: 'انصراف'
        }
      }
    },


    // todo
    seasonSchedules: {
      labels: {
        id: 'شناسه فاکتور',
        user: 'طرف حساب',
        timeWeek: 'تایم / هفته',
        confirm: 'لیست تایید رزرو',
        selectDateRange: 'انتخاب بازه زمانی',
        startDate: 'تاریخ شروع',
        endDate: 'تاریخ پایان',
        quantity: 'تعداد تایم ها',
        until: ' تا '
      },

      notifications: {
        lowMoney: 'موجودی شما برای رزرو کافی نیست!',
        timesReserved: 'تعدادی از تایم های انتخاب شده قبلا رزرو شده اند!',
        seasonDateRange: 'بازه شروع و پایان رزرو فصلی معتبرنیست!',
        startDate: 'تاریخ شروع وارد شده معتبر نیست!',
        endDate: 'تاریخ پایان وارد شده معتبر نیست!',
        requestSend: 'درخواست شما ارسال شد، لطفا منتظر بمانید!',
        cantDelete: 'این فاکتور قابل حذف نیست!',
        mustGTEWeek: 'طول بازه باید بیشتر از یک هفته باشد!',

        insert: {
          success: 'فاکتور رزرو فصلی با موفقیت ثبت شد',
          error: 'ثبت فاکتور رزرو فصلی با خطا همراه شد!'
        },

        delete: {
          success: 'فاکتور با موفقیت حذف شد',
          error: 'حذف فاکتور رزرو فصلی با خطا همراه شد'
        },


        parseError: {
          date: 'تاریخ انتخابی معتبر نیست!',
          getTimes: 'دریافت تایم ها با خطا همراه شد',
          startDate: 'تاریخ شروع وارد شده معتبر نیست، تاریخ شروع باید از فردا به بعد باشد!',
          endDate: 'تاریخ پایان وارد شده معتبر نیست!'
        }
      },

      validators: {
        user: 'طرف حساب معتبر نیست!',
        noTime: 'تایمی برای رزرو انتخاب نشده است!',
        emptyTime: 'با حذف این سفارش لیست رزرو شما خالی میشود!',
      },

      confirmations: {
        delete: {
          title: 'تائیدیه حذف فاکتور رزرو فصلی',
          body: 'در صورت حذف فاکتور رزرو فصلی تمامی اسناد مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
        },
      },

      insert: {
        title: 'افزودن رزرو فصلی',
        dynamicTitle: 'افزودن رزرو فصلی برای {gender} {name}',

        labels: {
          user: 'طرف حساب',
          totalPrice: 'مبلغ کل',
          finalPrice: 'مبلغ نهایی',
          totalDiscount: 'تخفیف کل',
          totalQuantity: 'تعداد کل'
        },

        table: {
          header: {
            row: 'ردیف',
            court: 'زمین',
            quantity: 'تعداد',
            totalPrice: 'مبلغ کل',
            discount: 'تخفیف',
            finalPrice: 'مبلغ نهایی',
            delete: 'حذف'
          }
        },
      },

      invoice: {
        title: 'فاکتور رزرو فصلی'
      },

      disableTimes: {
        title: 'لیست فاکتور های موجود برای تایم انتخاب شده',

        labels: {
          dynamicSaleInvoice: 'رزرو شماره {id}',
          dynamicClosedInvoice: 'اعلامیه تعطیلات شماره {id}'
        },

        table: {
          header: {
            row: 'ردیف',
            user: 'طرف حساب',
            description: 'توضیحات',
            date: 'تاریخ'
          }
        }
      },
      receive: {
        title: 'افزودن فاکتور رزرو فصلی',
        dynamicTitle: 'افزودن فاکتور رزرو فصلی شماره {id}',

        labels: {
          payment: 'پرداخت',
          receiveAndReserve: 'دریافت و رزرو',
          cancel: 'انصراف'
        }
      }
    },

    detail: {
      title: 'گزارشات فروش'
    },

    actions: {
      insert: 'افزودن',
      edit: 'ویرایش',
      filters: 'فیلترها'
    }
  },

  closed: {
    title: 'اعلامیه تعطیلات',

    labels: {
      bySystem: 'سیستمی',
      until: ' تا ',
      description: 'توضیحات',
      edit: 'ویرایش زمان تعطیلی',
      lastWeek: 'هفته قبل',
      futureWeek: 'هفته بعد',
      timeCourt: 'تایم / زمین'
    },

    table: {
      header: {
        row: 'ردیف',
        courtName: 'نام زمین',
        date: 'تاریخ رزرو',
        timeReserve: 'زمان رزرو',
        delete: 'حذف'
      }
    },

    notifications: {
      noTime: 'تایمی برای تعطیلات انتخاب نشده است!',
      noTimeAfterDelete: 'با حذف این تایم هیچ تایمی باقی نمی ماند!',
      descriptionWrong: 'توضیحات تعطیلی صحیح وارد نشده است!',
      cantDelete: 'این اعلامیه تعطیلی قابل حذف نیست!',

      edit: {
        success: 'تعطیلات مورد نظر با موفقیت ثبت شد',
        error: 'ثبت تعطیلی با خطا همراه شد!'
      },

      delete: {
        success: 'اعلامیه تعطیلی با موفقیت حذف شد',
        error: 'حذف اعلامیه تعطیلی با خطا همراه شد'
      },

      restore: {
        success: 'اعلامیه تعطیلی با موفقیت بازگردانده شد',
        error: 'بازگردانی اعلامیه تعطیلی با خطا مواجه شد'
      }
    },

    confirmations: {
      delete: {
        title: 'تائیدیه حذف اعلامیه تعطیلی',
        body: 'در صورت حذف اعلامیه تعطیلی تمامی تایم های مربوط به این اعلامیه فعال خواهند شد. آیا اطمینان دارید؟'
      },

      forceDelete: {
        title: 'تاییدیه حذف اعلامیه تعطیلی',
        body: 'آیا از حذف دائم {name} اطمینان دارید؟'
      }
    },

    list: {
      table: {
        header: {
          description: 'توضیحات',
          createdAt: 'تاریخ ثبت',
          creator: 'ایجاد کننده',
          timesCount: 'تعداد تایم تعطیل شده',
          delete: 'حذف',
          restore: 'بازگردانی'
        }
      }
    },

    profile: {
      title: 'پروفایل اعلامیه تعطیلات',
    },

    insert: {
      title: 'افزودن اعلامیه تعطیلات',

      confirms: {
        title: 'لیست تایید تعطیلات'
      }
    },

    edit: {
      title: 'ویرایش اعلامیه تعطیلات'
    }
  },

  //treasury
  treasury: {
    title: 'خزانه داری',

    banks: {
      title: 'بانک ها',

      insert: {
        title: 'افزودن بانک',

        notifications: {
          insert: {
            success: 'اطلاعات بانک جدید با موفقیت ثبت شد',
            error: 'ثبت اطلاعات بانک جدید با خطا همراه شد'
          }
        }
      },

      list: {
        title: 'بانک ها'
      },

      trash: {
        title: 'سطل زباله بانک ها',

        confirmations: {
          delete: {
            title: 'تاییدیه حذف بانک',
            body: 'آیا از حذف دائم بانک {bank} اطمینان دارید؟'
          }
        },

        notifications: {
          restore: {
            success: 'اطلاعات بانک با موفقیت بازگردانی شد',
            error: 'بازگردانی اطلاعات بانک با خطا همراه شد'
          },

          delete: {
            success: 'حذف دائم اطلاعات بانک با موفقیت انجام شد',
            error: 'حذف دائم اطلاعات بانک با خطا همراه شد',
            isUsedError: 'بانک مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
          }
        }
      },

      edit: {
        title: 'ویرایش بانک',
        dynamicTitle: 'ویرایش بانک {bank}',

        confirmations: {
          delete: {
            title: 'تاییدیه حذف بانک',
            body: 'آیا از حذف موقت بانک {bank} اطمینان دارید؟'
          }
        },

        notifications: {
          edit: {
            success: 'اطلاعات بانک با موفقیت ویرایش شد',
            error: 'ویرایش اطلاعات بانک با خطا همراه شد'
          },

          delete: {
            success: 'حذف اطلاعات بانک با موفقیت انجام شد',
            error: 'حذف اطلاعات بانک با خطا همراه شد'
          }
        }
      },

      bank: {
        title: 'بانک',
        dynamicTitle: 'بانک {bank}',

        importantData: {
          balance: 'موجودی',
          openingBalance: 'اول دوره',
          transactionsCount: 'تعداد تراکنش ها'
        },

        actions: {
          transactions: 'گردش بانک'
        }
      },

      transactions: {
        title: 'گردش مالی بانک',
        dynamicTitle: 'گردش مالی بانک {bank}',

        notifications: {
          print: {
            error: 'درخواست پرینت با خطا شد'
          },
        },

        table: {
          header: {
            balance: 'موجودی',
            price: 'مبلغ',
            description: 'مستندات',
            date: 'تاریخ',
            docNumber: 'شماره سند'
          }
        }
      },

      openingBalance: {
        title: '',

        table: {
          header: {
            row: 'ردیف',
            name: 'نام بانک',
            price: 'مبلغ',
            delete: 'حذف'
          }
        },

        notifications: {
          locked: 'اول دوره قفل می باشد',

          isExisted: {
            message: 'این مقدار در لیست وجود دارد',
            dynamicMessage: 'بانک {bank} در لیست وجود دارد'
          },

          insert: {
            success: 'اول دوره بانک با موفقیت ثبت شد',
            error: 'ثبت اول دوره بانک با خطا همراه شد'
          },

          edit: {
            success: 'اول دوره بانک موردنظر با موفقیت بروزرسانی شد',
            error: 'بروزرسانی اول دوره بانک موردنظر با خطا همراه شد'
          },

          delete: {
            success: 'حذف اول دوره بانک موردنظر با موفقیت انجام شد',
            error: 'حذف اول دوره بانک موردنظر با خطا همراه شد'
          }
        },

        insert: {
          title: 'افزودن اول دوره بانک'
        },

        edit: {
          title: 'ویرایش اول دوره بانک {bank}',

          confirmations: {
            delete: {
              title: 'تائیدیه حذف',
              body: 'آیا از حذف اول دوره بانک مورد نظر اطمینان دارید؟'
            }
          },

          labels: {
            price: 'مبلغ',
            delete: 'حذف اول دوره'
          }
        }
      },

      table: {
        header: {
          select: 'انتخاب',
          row: 'ردیف',
          name: 'نام',
          cardNumber: 'شماره کارت',
          accountNumber: 'شماره حساب',
          balance: 'موجودی',
          delete: 'حذف',
          restore: 'بازگردانی'
        }
      },

      labels: {
        name: 'نام بانک',
        accountNumber: 'شماره حساب',
        cardNumber: 'شماره کارت',
        shebaNumber: 'شماره شبا',
        delete: 'حذف بانک'
      },

      validators: {
        name: 'نام بانک به صورت صحیح وارد نشده است',
        accountNumber: 'شماره حساب به صورت صحیح وارد نشده است',
        cardNumber: 'شماره کارت به صورت صحیح وارد نشده است',
        shebaNumber: 'شماره شبا به صورت صحیح وارد نشده است'
      }
    },

    paymentGateways: {
      title: 'درگاه های پرداخت',

      labels: {
        status: 'وضعیت',
        type: 'نوع',
        bank: 'بانک تسویه',
        title: 'عنوان درگاه',
        endTime: 'ساعت پایان روز کاری درگاه',
        merchantCode: 'کد پذیرنده',
        terminalCode: 'کد پایانه',
        description: 'توضیحات',
        provider: 'ارائه دهنده',
        delete: 'حذف درگاه'
      },

      table: {
        header: {
          restore: 'بازگردانی',
          delete: 'حذف',
          balance: 'موجودی',
          bank: 'بانک تسویه',
          status: 'وضعیت',
          title: 'عنوان درگاه',
          type: 'نوع درگاه',
          row: 'ردیف'
        }
      },

      types: {
        all: 'همه',
        pos: 'کارتخوان',
        online: 'درگاه پرداخت اینترنتی'
      },

      status: {
        all: 'همه',
        enable: 'فعال',
        disable: 'غیرفعال'
      },

      confirmations: {
        delete: {
          title: 'تاییدیه حذف درگاه پرداخت',
          body: 'آیا از حذف دائم {paymentGateway} اطمینان دارید؟',
          body1: 'آیا از حذف موقت {paymentGateway} اطمینان دارید؟'
        }
      },

      notifications: {
        incorrectData: 'اطلاعات به صورت صحیح وارد نشده است',

        insert: {
          success: 'درگاه پرداخت با موفقیت ایجاد شد',
          error: 'ایجاد درگاه پرداخت با خطا همراه شد'
        },

        edit: {
          success: 'درگاه پرداخت با موفقیت بروزرسانی شد',
          error: 'بروزرسانی درگاه پرداخت با خطا همراه شد'
        },

        delete: {
          success: 'درگاه پرداخت با موفقیت حذف شد',
          error: 'حذف درگاه پرداخت با خطا همراه شد',
          isUsedError: 'درگاه پرداخت مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
        },

        restore: {
          success: 'درگاه پرداخت با موفقیت بازگردانی شد',
          error: 'بازگردانی درگاه پرداخت با خطا همراه شد'
        },

        print: {
          error: 'درخواست پرینت با خطا شد'
        },
      },

      insert: {
        title: 'افزودن درگاه پرداخت',
        suggestTitle: '{type} بانک {bank}'
      },

      list: {
        title: 'درگاه های پرداخت'
      },

      trash: {
        title: 'سطل زباله درگاه های پرداخت'
      },

      edit: {
        title: 'ویرایش درگاه پرداخت',
        dynamicTitle: 'ویرایش {paymentGateway}'
      },

      paymentGateway: {
        title: 'درگاه پرداخت',
        dynamicTitle: '{paymentGateway}',

        importantData: {
          type: 'نوع',
          bank: 'بانک تسویه',
          balance: 'موجودی',
          transactionsCount: 'تعداد تراکنش'
        },

        actions: {
          transactions: 'گردش مالی',
          receive: 'اعلام وصول'
        }
      },

      transactions: {
        title: 'گردش مالی درگاه پرداخت',
        dynamicTitle: 'گردش مالی {paymentGateway}',

        table: {
          header: {
            balance: 'موجودی',
            price: 'مبلغ',
            description: 'مستندات',
            date: 'تاریخ',
            docNumber: 'شماره سند'
          }
        }
      },

      receive: {
        title: 'اعلام وصول درگاه پرداخت',
        dynamicTitle: 'اعلام وصول {paymentGateway}',

        labels: {
          receivedBalance: 'مبلغ وصول شده',
          wage: 'کارمزد کسر شده',
          receiveBalanceWithWage: 'جمع مبلغ وصول شده و کارمزد',
          receivedBalanceTransferId: 'شماره پیگیری',
          totalBalance: 'موجودی کل',
          receivedYesterdayBalance: 'موجودی کل قابل وصول'
        },

        notifications: {
          balanceIsNotEnough: 'مبلغ وارد شده از موجودی درگاه بیشتر است',
          incorrectPriceOrId: 'مبلغ و یا شماره پیگیری به طور صحیح وارد نشده است',

          receive: {
            success: 'اعلام وصول با موفقیت ثبت شد',
            error: 'اعلام وصول با خطا همراه شد'
          }
        }
      }
    },

    cashBoxes: {
      title: 'صندوق ها',

      labels: {
        name: 'نام صندوق',
        delete: 'حذف صندوق'
      },

      confirmations: {
        delete: {
          title: 'تاییدیه حذف صندوق',
          body: 'آیا از حذف موقت صندوق {cashBox} اطمینان دارید؟'
        },
        forceDelete: {
          title: 'تاییدیه حذف صندوق',
          body: 'آیا از حذف دائم صندوق {cashBox} اطمینان دارید؟'
        }
      },

      notifications: {
        insert: {
          success: 'اطلاعات صندوق جدید با موفقیت ایجاد شد',
          error: 'ثبت اطلاعات صندوق جدید با خطا همراه شد'
        },

        edit: {
          success: 'اطلاعات صندوق با موفقیت بروزرسانی شد',
          error: 'بروزرسانی اطلاعات صندوق جدید با خطا همراه شد'
        },

        delete: {
          success: 'اطلاعات صندوق با موفقیت حذف شد',
          error: 'حذف اطلاعات صندوق جدید با خطا همراه شد',
          isUsedError: 'صندوق مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
        },

        restore: {
          success: 'اطلاعات صندوق با موفقیت بازگردانی شد',
          error: 'بازگردانی اطلاعات صندوق جدید با خطا همراه شد'
        },

        print: {
          error: 'درخواست پرینت با خطا شد'
        },
      },

      table: {
        header: {
          select: 'انتخاب',
          row: 'ردیف',
          name: 'نام',
          balance: 'موجودی',
          delete: 'حذف',
          restore: 'بازگردانی'
        }
      },

      list: {
        title: 'صندوق ها'
      },

      trash: {
        title: 'سطل زباله صندوق ها'
      },

      insert: {
        title: 'افزودن صندوق'
      },

      edit: {
        title: 'ویرایش صندوق',
        dynamicTitle: 'ویرایش صندوق {cashBox}'
      },

      cashBox: {
        title: 'صندوق',
        dynamicTitle: 'صندوق {cashBox}',

        importantData: {
          balance: 'موجودی',
          openingBalance: 'اول دوره',
          transactionsCount: 'تعداد تراکنش ها'
        },

        actions: {
          transactions: 'گردش صندوق'
        }
      },

      transactions: {
        title: 'گردش مالی صندوق',
        dynamicTitle: 'گردش مالی صندوق {cashBox}',

        table: {
          header: {
            balance: 'موجودی',
            price: 'مبلغ',
            description: 'مستندات',
            date: 'تاریخ',
            docNumber: 'شماره سند'
          }
        }
      },

      openingBalance: {

        labels: {
          price: 'مبلغ',
          delete: 'حذف اول دوره'
        },

        confirmations: {
          delete: {
            title: 'تاییدیه حذف',
            body: 'آیا از حذف اول دوره صندوق مورد نظر اطمینان دارید؟'
          }
        },

        notifications: {
          locked: 'اول دوره قفل می باشد',

          isExisted: {
            message: 'این مقدار در لیست وجود دارد',
            dynamicMessage: 'صندوق {cashBox} در لیست وجود دارد'
          },

          insert: {
            success: 'اول دوره صندوق مورد نظر با موفقیت ثبت شد',
            error: 'ثبت اول دوره صندوق مورد نظر با خطا همراه شد'
          },

          edit: {
            success: 'اول دوره صندوق مورد نظر با موفقیت بروزرسانی شد',
            error: 'بروزرسانی اول دوره صندوق مورد نظر با خطا همراه شد'
          },

          delete: {
            success: 'اول دوره صندوق مورد نظر با موفقیت حذف شد',
            error: 'حذف اول دوره صندوق مورد نظر با خطا همراه شد'
          }
        },

        table: {
          header: {
            delete: 'حذف',
            price: 'مبلغ',
            name: 'نام صندوق',
            row: 'ردیف'
          }
        },

        insert: {
          title: 'افزودن اول دوره صندوق'
        },

        edit: {
          title: 'ویرایش اول دوره صندوق {cashBox}'
        }
      }
    },

    cost: {
      title: 'هزینه',

      paymentTypes: {
        cash: 'دریافت وجه نقد',
        bank: 'دریافت کارت‌به‌کارت یا شبا',
        paymentGateway: 'دریافت پوز یا درگاه'
      },

      bankTypes: {
        pos: 'POS',
        card: 'کارت به کارت',
        sheba: 'شبا'
      },

      types: {
        cash: 'صندوق',
        bank: 'بانک',
        user: '',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت'
      },

      labels: {
        all: 'همه',
        account: 'سرفصل هزینه',
        cash: 'پرداخت از صندوق',
        bank: 'پرداخت از بانک',
        payer: 'پرداخت کننده',
        paymentType: 'نوع پرداخت',
        price: 'مبلغ',
        description: 'توضیحات',
        date: 'تاریخ واریز',
        paymentFrom: 'پرداخت از',
        bankWage: 'کارمزد بانک',
        maxPayment: 'حداکثر قابل پرداخت',
        bankBalance: 'موجودی بانک',
        cashBoxBalance: 'موجودی صندوق'
      },

      placeholder: {
        date: 'اتوماتیک'
      },

      table: {
        header: {
          price: 'مبلغ',
          account: 'سرفصل هزینه',
          payer: 'پرداخت کننده',
          creator: 'ایجاد کننده',
          date: 'تاریخ',
          docNumber: 'شماره سند'
        }
      },

      notifications: {
        print: {
          success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
          error: 'درخواست پرینت با خطا شد'
        }
      },

      validators: {
        dateHelp: 'ورودی باید به صورت {date} وارد شود.',
        date: 'تاریخ واریز درست وارد نشده است!',
        type: 'نوع پرداخت مشخص نشده است',
        price: 'مبلغ وارد شده بیشتراز حداکثر میزان قابل پرداخت است!',
        future: 'نمیتوان برای آینده هزینه ثبت کرد'
      },

      insert: {
        title: 'هزینه جدید',

        notifications: {
          invalidAccount: 'سرفصل هزینه انتخاب نشده است',
          invalidCash: 'صندوق انتخاب نشده است',
          invalidBank: 'بانک انتخاب نشده است',
          invalidPrice: 'مبلغ وارد نشده است',
          invalidPaymentType: 'نوع پرداخت مشخص نشده است',
          invalidDescription: 'توضیحات هزینه وارد نشده است',
          lowBalance: 'موجودی بانک یا صندوق کافی نمیباشد!',

          insert: {
            success: 'سند هزینه با موفقیت ایجاد شد',
            error: 'ایجاد سند هزینه با خطا همراه شد'
          }
        },

        suggests: {
          description: 'دریافت از {user} بابت {subject}'
        }
      },

      list: {
        title: 'لیست هزینه ها'
      },

      document: {
        title: 'سند هزینه',
        dynamicTitle: 'سند هزینه شماره {docNumber}',

        confirmations: {
          delete: {
            title: 'تائیدیه حذف سند',
            body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
          }
        },

        notifications: {
          delete: {
            success: 'اطلاعات سند هزینه با موفقیت حذف شد',
            error: 'حذف اطلاعات سند هزینه با خطا همراه شد'
          }
        },

        labels: {
          payer: 'پرداخت کننده',
          account: 'سرفصل هزینه',
          details: 'سایر اطلاعات',
          creator: 'ایجادکننده',
          date: 'تاریخ ثبت',
          paymentType: 'نوع پرداخت',
          wage: 'کارمزد',
          description: 'توضیحات'
        }
      }
    },

    payment: {
      title: 'پرداخت',

      bankTypes: {
        pos: 'POS',
        card: 'کارت به کارت',
        sheba: 'شبا'
      },

      types: {
        cash: 'صندوق',
        bank: 'بانک',
        user: '',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت'
      },

      paymentTypes: {
        customer: 'پرداخت به مشتری',
        supplier: 'پرداخت به تامین کننده'
      },

      labels: {
        all: 'همه',
        type: 'نوع',
        paymentType: 'نوع پرداخت',
        user: 'پرداخت به',
        source: 'پرداخت از',
        price: 'مبلغ',
        description: 'توضیحات',
        transactionsReferenceId: 'شماره پیگیری',
        date: 'تاریخ پرداخت',
        maxPayment: 'حداکثر قابل پرداخت',
        dynamicBalance: 'اعتبار {balance}',
        bankWage: 'کارمزد بانک',
        bankBalance: 'موجودی بانک',
        cashBoxBalance: 'موجودی صندوق'
      },

      placeholder: {
        date: 'اتوماتیک'
      },

      table: {
        header: {
          price: 'مبلغ',
          destination: 'دریافت کننده',
          source: 'پرداخت کننده',
          description: 'مستندات',
          creator: 'ایجادکننده',
          date: 'تاریخ',
          docNumber: 'شماره سند'
        }
      },

      notifications: {
        print: {
          success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
          error: 'درخواست پرینت با خطا شد'
        }
      },

      validators: {
        dateHelp: 'ورودی باید به صورت {date} وارد شود.',
        date: 'تاریخ پرداخت درست وارد نشده است!',
        future: 'نمیتوان برای آینده پرداخت ثبت کرد'
      },

      insert: {
        title: 'پرداخت جدید',

        notifications: {
          invalidUser: 'شخص انتخاب نشده است',
          invalidPrice: 'مبلغ وارد نشده است',
          invalidPaymentFrom: 'مبدا پرداخت انتخاب نشده است',
          invalidPaymentType: 'نوع پرداخت مشخص نشده است',
          invalidDescription: 'توضیحات پرداخت وارد نشده است',
          price: 'مبلغ وارد شده بیشتراز حداکثر میزان قابل پرداخت است!',
          lowBalance: 'موجودی بانک یا صندوق کافی نمیباشد!',

          insert: {
            success: 'سند پرداخت با موفقیت ایجاد شد',
            error: 'ایجاد سند پرداخت با خطا همراه شد'
          }
        },

        suggests: {
          description: 'دریافت از {user} بابت {subject}'
        }
      },

      list: {
        title: 'لیست پرداخت ها'
      },

      document: {
        title: 'سند پرداخت',
        dynamicTitle: 'سند پرداخت شماره {docNumber}',

        confirmations: {
          delete: {
            title: 'تائیدیه حذف سند',
            body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
          }
        },

        notifications: {
          delete: {
            success: 'اطلاعات سند پرداخت با موفقیت حذف شد',
            error: 'حذف اطلاعات سند پرداخت با خطا همراه شد'
          }
        },

        labels: {
          sources: 'پرداخت کننده/پرداخت کنندگان',
          destinations: 'دریافت کننده/دریافت کنندگان',
          details: 'سایر اطلاعات',
          creator: 'ایجادکننده',
          date: 'تاریخ ثبت',
          paymentType: 'نوع پرداخت',
          wage: 'کارمزد',
          description: 'توضیحات'
        }
      }
    },

    receive: {
      title: 'دریافت',

      statusTypes: {
        all: 'همه',
        confirm: 'تایید شده',
        cancel: 'لغو شده'
      },

      receiveTypes: {
        cash: 'دریافت وجه نقد',
        bank: 'دریافت کارت‌به‌کارت یا شبا',
        paymentGateway: 'دریافت پوز یا درگاه'
      },

      types: {
        cash: 'صندوق',
        bank: 'بانک',
        user: '',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت'
      },

      labels: {
        all: 'همه',
        receiveType: 'نوع دریافت وجه',
        user: 'دریافت از',
        cash: 'واریز به صندوق',
        bank: 'واریز به بانک',
        paymentGateway: 'واریز به درگاه',
        price: 'مبلغ',
        date: 'تاریخ واریز',
        description: 'توضیحات',
        transactionsReferenceId: 'شماره پیگیری',
        balance: 'موجودی کیف پول'
      },

      placeholder: {
        date: 'اتوماتیک'
      },

      table: {
        header: {
          status: 'وضعیت',
          price: 'مبلغ',
          paymentConfirm: 'تاییدیه پرداخت',
          transactionId: 'شماره تراکنش',
          destination: 'دریافت کننده',
          source: 'پرداخت کننده',
          description: 'مستندات',
          creator: 'ایجادکننده',
          date: 'تاریخ',
          docNumber: 'شماره سند'
        }
      },

      notifications: {
        print: {
          success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
          error: 'درخواست پرینت با خطا شد'
        }
      },

      validators: {
        dateHelp: 'ورودی باید به صورت {date} وارد شود.',
        date: 'تاریخ دریافت درست وارد نشده است!',
        future: 'نمیتوان برای آینده دریافت ثبت کرد'
      },

      insert: {
        title: 'دریافت جدید',
        dynamicTitle: 'دریافت وجه از {name}',

        notifications: {
          invalidUser: 'شخص انتخاب نشده است',
          invalidPrice: 'مبلغ وارد نشده است',
          lessPrice: 'مبلغ وارد شده نباید کم تر از {price} {currency} باشد',
          invalidDescription: 'توضیحات دریافت وارد نشده است',
          invalidTransactionsReferenceId: 'شماره پیگیری اشتباه وارد شده است',
          invalidCash: 'صندوق انتخاب نشده است',
          invalidBank: 'بانک انتخاب نشده است',
          invalidPaymentGateway: 'درگاه انتخاب نشده است',

          insert: {
            success: 'سند دریافت با موفقیت ایجاد شد',
            error: 'ایجاد سند دریافت با خطا همراه شد'
          },

          parseError: {
            name: 'نام انتخاب شده معتبر نیست!',
            price: 'مبلغ انتخاب شده معتبر نیست!',
            referenceId: 'شماره پیگیری وارد شده قبلا استفاده شده است!',
            paymentGateway: 'درگاه پرداخت انتخابی معتبر نیست!'
          }
        },

        suggests: {
          description: 'دریافت از {user} بابت {subject}'
        }
      },

      list: {
        title: 'لیست دریافت ها'
      },

      document: {
        title: 'سند دریافت',
        dynamicTitle: 'سند دریافت شماره {docNumber}',

        confirmations: {
          confirm: {
            title: 'تایید سند دریافت وجه',
          },

          delete: {
            title: 'تائیدیه حذف سند',
            body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
          }
        },

        notifications: {
          confirm: {
            success: 'تایید سند دریافت وجه با موفقیت انجام شد',
            error: 'تایید سند دریافت وجه با خطا همراه شد'
          },

          delete: {
            success: 'اطلاعات سند دریافت با موفقیت حذف شد',
            error: 'حذف اطلاعات سند دریافت با خطا همراه شد'
          }
        },

        labels: {
          status: 'وضعیت',
          sources: 'پرداخت کننده',
          destinations: 'دریافت کننده',
          details: 'سایر اطلاعات',
          creator: 'ایجادکننده',
          date: 'تاریخ ثبت',
          transactionId: 'شماره تراکنش',
          paymentConfirm: 'تاییدیه پرداخت',
          description: 'توضیحات'
        }
      }
    },

    internalFundsTransfer: {
      title: 'انتقالی خزانه',

      types: {
        cash: 'صندوق',
        bank: 'بانک',
        user: '',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت'
      },

      bankTypes: {
        pos: 'POS',
        card: 'کارت به کارت',
        sheba: 'شبا'
      },

      labels: {
        sourceType: 'برداشت از',
        source: '{type} مبدا',
        destinationType: 'واریز به',
        destination: '{type} مقصد',
        price: 'مبلغ',
        date: 'تاریخ انتقال',
        paymentType: 'نوع پرداخت',
        bankWage: 'کارمزد بانک',
        bankBalance: 'موجودی بانک',
        cashBoxBalance: 'موجودی صندوق'
      },

      placeholder: {
        date: 'اتوماتیک'
      },

      table: {
        header: {
          price: 'مبلغ',
          description: 'مستندات',
          payee: 'دریافت کننده',
          payer: 'پرداخت کننده',
          creator: 'ایجادکننده',
          date: 'تاریخ',
          docNumber: 'شماره سند'
        }
      },

      notifications: {
        print: {
          success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
          error: 'درخواست پرینت با خطا شد'
        }
      },

      validators: {
        dateHelp: 'ورودی باید به صورت {date} وارد شود.',
        date: 'تاریخ انتقال درست وارد نشده است!',
        future: 'نمیتوان برای آینده انتقالی ثبت کرد'
      },

      insert: {
        title: 'انتقالی خزانه جدید',

        notifications: {
          invalidPrice: 'مبلغ وارد نشده است',
          invalidSourceType: 'نوع مبدا انتخاب نشده است',
          invalidSource: 'مبدا انتخاب نشده است',
          invalidDestinationType: 'نوع مقصد انتخاب نشده است',
          invalidDestination: 'مقصد انتخاب نشده است',
          equalSourceAndDestination: 'مبدا و مقصد نمی تواند یکسان باشد',
          invalidPaymentType: 'نوع پرداخت مشخص نشده است',
          lowBalance: 'موجودی یانک یا صندوق کافی نمی باشد',

          insert: {
            success: 'سند انتقال وجه داخلی با موفقیت ایجاد شد',
            error: 'ایجاد سند انتقال وجه داخلی با خطا همراه شد'
          }
        }
      },

      list: {
        title: 'لیست انتقالی خزانه'
      },

      document: {
        title: 'سند انتقالی خزانه',
        dynamicTitle: 'سند انتقالی خزانه شماره {docNumber}',

        confirmations: {
          delete: {
            title: 'تائیدیه حذف سند',
            body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
          }
        },

        notifications: {
          delete: {
            success: 'اطلاعات سند انتقالی خزانه با موفقیت حذف شد',
            error: 'حذف اطلاعات سند انتقالی خزانه با خطا همراه شد'
          }
        },

        labels: {
          payer: 'پرداخت کننده',
          destinations: 'دریافت کننده',
          details: 'سایر اطلاعات',
          creator: 'ایجادکننده',
          date: 'تاریخ ثبت',
          paymentType: 'نوع پرداخت',
          wage: 'کارمزد',
          description: 'توضیحات'
        }
      }
    },

    transactions: {
      title: 'تراکنش های مالی',

      list: {
        title: 'تراکنش های مالی'
      },

      insert: {
        title: 'افزودن تراکنش'
      },

      transaction: {
        title: 'تراکنش'
      }
    },

    actions: {
      insert: 'افزودن',
      edit: 'ویرایش',
      save: 'ثبت',
      delete: 'حذف'
    }
  },

  report: {
    title: 'گزارشات',

    userReport: {
      title: 'گزارشات اشخاص'
    },

    saleDetail: {
      title: 'گزارش فروش اشخاص',

      labels: {
        all: 'همه',
      },

      table: {
        header: {
          row: 'ردیف',
          name: 'مشتری',
          totalPrice: 'مبلغ کل',
        }
      },

      filters: {
        term: 'سانس',
        court: 'زمین',
        time: 'تایم',
      },

      notifications: {
        noData: 'در این بازه زمانی داده ای وجود ندارد، در صورت تمایل لطفا بازه گزارش را تغییر دهید!',

        print: {
          success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
          error: 'درخواست پرینت با خطا شد'
        }
      },

      invoices: {
        title: 'لیست فاکتورهای شخص',
        dynamicTitle: 'لیست فاکتورهای {name}',

        labels: {
          all: 'همه',
          until: ' تا ',
          active: 'فعال',
          passed: 'گذشته',
          reserved: 'رزرو شده',
          canceled: 'لغو شده',
          fromNow: 'امروز به بعد'
        },

        table: {
          header: {
            id: 'شماره فاکتور',
            creator: 'ایجادکننده',
            reserveDate: 'تاریخ رزرو',
            time: 'ساعت رزرو',
            courtName: 'زمین رزرو',
            finalPrice: 'مبلغ نهایی',
            status: 'وضعیت',
          }
        },

        statusTypes: {
          all: 'همه',
          active: 'رزرو فعال',
          activePassed: 'رزرو استفاده شده',
          canceled: 'لغو شده',
          canceledBySystem: 'لغو شده سیستمی'
        },

        processTypes: {
          preInvoice: 'پیش فاکتور فروش',
          invoice: 'فاکتور فروش'
        },

        financialStatusTypes: {
          debtor: 'بدهکار',
          checkout: 'تسویه'
        },

        actionTypes: {
          present: 'فروش حضوری',
          tel: 'فروش تلفنی',
          internet: 'فروش اینترنتی'
        }
      }
    },

    saleAddonDetail: {
      title: 'گزارش خدمات',
      dynamicTitle: 'گزارش {name}',

      labels: {
        all: 'همه',
        and: ' و '
      },

      table: {
        header: {
          row: 'ردیف',
          court: 'زمین',
          quantity: 'تعداد کل',
          totalPrice: 'مبلغ کل'
        }
      },

      filters: {
        term: 'سانس',
        court: 'زمین',
        time: 'تایم',
        addon: 'خدمات'
      },

      notifications: {
        noData: 'در این بازه زمانی داده ای وجود ندارد، در صورت تمایل لطفا بازه گزارش را تغییر دهید!',

        print: {
          success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
          error: 'درخواست پرینت با خطا شد'
        }
      }
    },

    charts: {
      main: {
        labels: {
          daily: 'روزانه',
          weekly: 'هفتگی',
          yearly: 'سالانه',
          today: 'امروز',
          week: 'هفته جاری',
          year: 'سال جاری',
          yesterday: 'دیروز',
          lastWeek: 'هفته گذشته',
          lastYear: 'سال گذشته',
          reserve: 'رزرو',
          default: 'پیش فرض',
          price: 'قیمت ({currency})'
        }
      }
    },

    tops: {
      reserve: {
        title: 'آخرین رزرو ها',

        labels: {
          court: 'زمین تنیس'
        },

        table: {
          header: {
            row: 'ردیف',
            name: 'نام و نام خانوادگی',
            date: 'تاریخ رزرو',
            court: 'زمین',
            time: 'تایم',
            show: 'مشاهده'
          }
        }
      },

      customer: {
        title: 'برترین مشتریان',

        labels: {
          profile: 'پروفایل'
        },

        table: {
          header: {
            row: 'ردیف',
            name: 'نام و نام خانوادگی',
            phoneNumber: 'شماره تماس',
            price: 'فروش کل',
            show: 'مشاهده'
          }
        }
      },

      court: {
        title: 'برترین زمین ها',

        table: {
          header: {
            row: 'ردیف',
            name: 'عنوان زمین',
            price: 'فروش کل',
            show: 'مشاهده'
          }
        }
      }
    }
  },

  //reports
  reports: {
    title: 'گزارشات پیشرفته',

    logs: {
      title: 'لاگ ها',

      table: {
        header: {
          rowNumber: 'ردیف',
          createdAt: 'تاریخ لاگ',
          userName: 'کاربر',
          description: 'توضیحات',
          details: 'جزییات'
        }
      },

      dialogs: {
        title: 'جزییات لاگ',
        withoutDescription: 'بدون توضیحات بیشتر'
      },

      list: {
        title: 'لیست لاگ ها'
      }
    },

    list: {
      table: {
        header: {
          rowNumber: 'ردیف',
          type: 'نوع گزارش',
          createdAt: 'تاریخ ایجاد',
          expiredAt: 'تاریخ انقضاء',
          startRange: 'شروع بازه',
          endRange: 'پایان بازه',
          creator: 'نام درخواست دهنده',
          phoneNumber: 'شماره درخواست دهنده',
          range: 'بازه گزارش',
          status: 'وضعیت',
          actions: 'اکشن ها'
        }
      }
    },

    pastTimeDebtor: {
      table: {
        header: {
          rowNumber: 'ردیف',
          userName: 'نام شخص',
          phoneNumber: 'شماره تلفن',
          debt: 'میزان بدهی'
        }
      }
    },

    usersTotalSales: {
      title: 'مجموع فروش به اشخاص'
    }
  },

  // tickets
  tickets: {
    title: 'تیکت ها',

    support: {
      title: 'تیکت پشتیبانی',

      statusTypes: {
        all: 'همه',
        created: 'ایجاد شده',
        customerAnswer: 'پاسخ مشتری',
        operatorAnswer: 'پاسخ اپراتور',
        pending: 'در انتظار بررسی',
        completed: 'بسته شده',
        canceled: 'کنسل شده'
      },

      sendStatusTypes: {
        send: 'ارسال شده',
        notSend: 'ارسال نشده'
      },

      seenStatusTypes: {
        seen: 'مشاهده شده',
        notSeen: 'مشاهده نشده'
      },

      labels: {
        all: 'همه',
        selectType: 'موضوع رو انتخاب کنید',
        service: 'سرویس',
        subject: 'موضوع تیکت',
        message: 'پیام',
        dynamicAttach: 'پیوست {number}',
        insertAttach: 'پیوست جدید',
        unknown: 'نامشخص',
        operator: 'اپراتور'
      },

      placeholder: {
        subject: 'موضوع تیکت خود را وارد کنید',
        message: 'متن پیام خود را وارد کنید'
      },

      table: {
        header: {
          row: 'ردیف',
          department: 'دپارتمان',
          service: 'نام سامانه',
          subject: 'موضوع',
          date: 'تاریخ ایجاد',
          lastUpdate: 'آخرین به روز رسانی',
          operatorName: 'اپراتور',
          creator: 'ایجاد کننده',
          status: 'وضعیت',
          sendStatus: 'وضعیت ارسال',
          seen: 'مشاهده شده'
        }
      },

      notifications: {
        attachUpload: 'فایل مورد نظر آپلود شد',
        requestSend: 'درخواست شما ارسال شد لطفا منتظر بمانید!',

        insert: {
          success: 'تیکت مورد نظر با موفقیت ایجاد شد',
          error: 'افزودن تیکت با خطا همراه شد'
        },

        sendMessage: {
          success: 'پیام مورد نظر با موفقیت ارسال شد',
          error: 'ارسال پیام مورد نظر با خطا همراه شد'
        },

        changeOperator: {
          success: 'ویرایش اپراتور تیکت با موفقیت انجام شد',
          error: 'ویرایش اپراتور تیکت با خطا همراه شد'
        },

        parseError: {
          service: 'سرویس انتخاب شده معتبر نیست!',
          subject: 'موضوع انتخاب شده معتبر نیست!',
          message: 'متن وارد شده معتبر نیست!',
          attachments: 'پیوست انتخاب شده معتبر نیست!',
          operator: 'اپراتور انتخاب شده معتبر نیست!'
        }
      },

      confirmations: {
        send: {
          title: 'تاییدیه ارسال پیام',
          body: 'آیا از ارسال پیام خود اطمینان دارید؟'
        }
      },

      validators: {
        subject: 'موضوع تیکت به صورت صحیح وارد نشده است!',
        message: 'متن پیام به صورت صحیح وارد نشده است!',
        service: 'سرویس به صورت صحیح انتخاب نشده است!'
      },

      list: {
        title: 'تیکت های پشتیبانی'
      },

      insert: {
        title: 'افزودن تیکت پشتیبانی جدید'
      },

      answer: {
        title: 'افزودن پاسخ به تیکت پشتیبانی'
      },

      detail: {
        title: 'جزئیات تیکت پشتیبانی',

        labels: {
          ticketId: 'تیکت ',
          status: 'وضعیت تیکت: ',
          createdAt: 'زمان ایجاد: ',
          lastUpdate: 'آخرین بروزرسانی: ',
          user: 'کاربر',
          operator: 'اپراتور'
        }
      }
    },

    organization: {
      title: 'تیکت درون سازمانی',

      statusTypes: {
        all: 'همه',
        created: 'ایجاد شده',
        creatorAnswer: 'پاسخ ایجاد کننده',
        receiverAnswer: 'پاسخ مسئول پیگیری',
        pending: 'در انتظار بررسی',
        completed: 'بسته شده',
        canceled: 'کنسل شده'
      },

      sendStatusTypes: {
        send: 'ارسال شده',
        notSend: 'ارسال نشده'
      },

      seenStatusTypes: {
        seen: 'مشاهده شده',
        notSeen: 'مشاهده نشده'
      },

      labels: {
        all: 'همه',
        selectType: 'موضوع رو انتخاب کنید',
        user: 'گیرنده',
        subject: 'موضوع تیکت',
        message: 'پیام',
        dynamicAttach: 'پیوست {number}',
        insertAttach: 'پیوست جدید',
        unknown: 'نامشخص',
        operator: 'اپراتور'
      },

      placeholder: {
        subject: 'موضوع تیکت خود را وارد کنید',
        message: 'متن پیام خود را وارد کنید'
      },

      table: {
        header: {
          row: 'ردیف',
          creator: 'ایجاد کننده',
          user: 'مسئول پیگیری',
          subject: 'موضوع',
          date: 'تاریخ ایجاد',
          lastUpdate: 'آخرین به روز رسانی',
          status: 'وضعیت',
        }
      },

      notifications: {
        attachUpload: 'فایل مورد نظر آپلود شد',
        requestSend: 'درخواست شما ارسال شد لطفا منتظر بمانید!',

        insert: {
          success: 'تیکت مورد نظر با موفقیت ایجاد شد',
          error: 'افزودن تیکت با خطا همراه شد'
        },

        sendMessage: {
          success: 'پیام مورد نظر با موفقیت ارسال شد',
          error: 'ارسال پیام مورد نظر با خطا همراه شد'
        },

        changeOperator: {
          success: 'ویرایش اپراتور تیکت با موفقیت انجام شد',
          error: 'ویرایش اپراتور تیکت با خطا همراه شد'
        },

        parseError: {
          user: 'گیرنده انتخاب شده معتبر نیست!',
          service: 'سرویس انتخاب شده معتبر نیست!',
          subject: 'موضوع انتخاب شده معتبر نیست!',
          message: 'متن وارد شده معتبر نیست!',
          attachments: 'پیوست انتخاب شده معتبر نیست!',
          operator: 'اپراتور انتخاب شده معتبر نیست!'
        }
      },

      confirmations: {
        send: {
          title: 'تاییدیه ارسال پیام',
          body: 'آیا از ارسال پیام خود اطمینان دارید؟'
        }
      },

      validators: {
        user: 'گیرنده تیکت به صورت صحیح وارد نشده است!',
        subject: 'موضوع تیکت به صورت صحیح وارد نشده است!',
        message: 'متن پیام به صورت صحیح وارد نشده است!',
        service: 'سرویس به صورت صحیح انتخاب نشده است!'
      },

      list: {
        title: 'تیکت های درون سازمانی'
      },

      insert: {
        title: 'افزودن تیکت درون سازمانی جدید'
      },

      answer: {
        title: 'افزودن پاسخ به تیکت درون سازمانی'
      },

      detail: {
        title: 'جزئیات تیکت درون سازمانی',

        labels: {
          ticketId: 'تیکت ',
          status: 'وضعیت تیکت: ',
          createdAt: 'زمان ایجاد: ',
          lastUpdate: 'آخرین بروزرسانی: ',
          user: 'کاربر',
          operator: 'اپراتور'
        }
      }
    },
  },

  //events
  events: {
    title: 'پیام ها',

    labels: {
      priority: 'اولویت',
      type: 'موضوع',
      subject: 'موضوع',
      content: 'متن'
    },

    eventTypes: {
      all: 'همه',
      update: 'بروزرسانی سامانه',
      activity: 'فعالیت',
      message: 'پیام',
      warning: 'هشدار',
      report: 'گزارش'
    },

    priority: {
      all: 'همه',
      veryLow: 'خیلی کم',
      low: 'کم',
      medium: 'متوسط',
      high: 'زیاد',
      veryHigh: 'خیلی زیاد'
    },

    validators: {
      subject: 'موضوع به صورت صحیح وارد نشده است',
      content: 'محتوا به صورت صحیح وارد نشده است'
    },

    notifications: {
      insert: {
        success: 'پیام با موفقیت ارسال شد',
        error: 'ارسال پیام با خطا همراه شد'
      },

      parseError: {
        type: 'نوع انتخاب نشده است',
        priority: 'اولویت انتخاب نشده است'
      }
    },

    list: {
      table: {
        header: {
          rowNumber: 'ردیف',
          sender: 'ارسال کننده',
          subject: 'موضوع',
          content: 'محتوا',
          type: 'نوع',
          priority: 'اولویت',
          sendDate: 'تاریخ ارسال'
        }
      }
    },

    insert: {
      title: 'ارسال پیام جدید'
    },

    event: {
      labels: {
        understand: 'متوجه شدم',
        showReport: 'مشاهده گزارش'
      }
    }
  },

  // draggable dynamic table
  draggableDynamicTable: {

    filters: 'فیلترها',

    activeFilters: {
      title: 'فیلترهای اعمال شده'
    },

    setting: {
      showColumns: 'نمایش ستون ها'
    },

    notifications: {
      loading: 'در حال بارگزاری اطلاعات ...',
      error: 'دریافت اطلاعات با خطا مواجه شد',
      empty: 'هیچ اطلاعاتی وجود ندارد'
    },

    actions: {
      print: 'پرینت',
      download: 'دریافت',
      setting: 'تنظیمات جدول'
    }
  },

  // accessTreeCheckBox
  accessTreeCheckBox: {
    labels: {
      chooseCategory: 'انتخاب دسترسی'
    }
  },

  // custom dialog
  customDialog: {
    confirm: 'تایید',
    cancel: 'انصراف'
  },

  customFileInput: {
    uploaded: 'عکس شما آپلود شد'
  },

  // custom date picker input
  customDatePickerInput: {
    invalidText: 'فیلد وارد شده معتبر نمی باشد'
  },

  // custom number input
  customNumberInput: {
    invalidText: 'فیلد وارد شده معتبر نمی باشد'
  },

  // custom select input
  customSelectInput: {
    invalidText: 'فیلد وارد شده معتبر نمی باشد'
  },

  // custom validate input
  customValidateInput: {
    invalidText: 'فیلد وارد شده معتبر نمی باشد'
  },

  selectTime: {
    withoutPrice: 'بدون قیمت',
    until: ' تا '
  },

  // date picker
  datePicker: {
    labels: {
      submit: 'تائید',
      cancel: 'انصراف',
      now: 'اکنون',
      nextMonth: 'ماه بعد',
      prevMonth: 'ماه قبل'
    }
  },

  //Button
  create: 'افزودن'
}
