<template>
  <vx-card class="insert-bank-page top-zero-radius" :class="{'w-full in-modal': inModal, ' main-box md:w-1/2': !inModal}">
    <vs-input class="w-full"
              :label="`${$t('treasury.banks.labels.name')}`"
              :danger="validation.name.length > 0"
              :danger-text="validation.name"
              v-model="new_bank.name"/>

    <vs-input class="w-full dir-rtl"
              :label="`${$t('treasury.banks.labels.accountNumber')}`"
              @input="new_bank.account_number = isNumber($event)"
              :danger="validation.account_number.length > 0"
              :danger-text="validation.account_number"
              v-model="new_bank.account_number"/>

    <vs-input class="w-full dir-rtl"
              :label="`${$t('treasury.banks.labels.cardNumber')}`"
              @input="new_bank.card_number = isNumber($event)"
              :danger="validation.card_number.length > 0"
              :danger-text="validation.card_number"
              v-model="new_bank.card_number"/>

    <vs-input class="w-full dir-rtl"
              :label="`${$t('treasury.banks.labels.shebaNumber')}`"
              @input="new_bank.shaba_number = isNumber($event)"
              :danger="validation.shaba_number.length > 0"
              :danger-text="validation.shaba_number"
              v-model="new_bank.shaba_number"/>

    <vs-button id="insertBankBTN"
               class="useral-action-button"
               @click="sendData()"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import {insertBank} from '@/http/requests/banks'

export default {
  name: 'insertBank',
  metaInfo () {
    return {
      title: this.$t('treasury.banks.insert.title')
    }
  },
  props: {
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      new_bank: {
        name: '',
        account_number: '',
        card_number: '',
        shaba_number: ''
      },
      validation: {
        name: '',
        account_number: '',
        card_number: '',
        shaba_number: ''
      },
      actions: [
        {
          toolbar: [
            {
              id: 'insertBankBTN',
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'treasuryBanks'},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      if (!this.inModal) {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      }
    }, 50)
  },
  methods: {
    addDash (val) {
      let sign = ''

      if (val < 0) sign = '-'

      val = val.toString()

      if (val[0] === '0') val = val.substr(1, val.length - 1)

      return val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{4})+(?!\d))/g, '-') + sign
    },
    isNumber (val) {
      return val.toString().replace(/[^0-9]/g, '')
    },
    sendData () {
      this.nameValidator(this.new_bank.name)
      this.accountNumberValidator(this.new_bank.account_number)
      this.cardNumberValidator(this.new_bank.card_number)
      this.shabaNumberValidator(this.new_bank.shaba_number)

      if (!this.validation.name &&
        !this.validation.account_number &&
        !this.validation.card_number &&
        (!this.validation.shaba_number || !this.new_bank.shaba_number)) {

        insertBank(this.new_bank).then(response => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('treasury.banks.insert.notifications.insert.success'),
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather',
            color: 'success'
          })

          this.$store.dispatch('removePageChanges')

          if (!this.inModal) {
            this.$router.push({name: 'treasuryBanks'})
          } else {
            this.$emit('inserted')
          }

        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.banks.insert.notifications.insert.error'),
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              color: 'danger'
            })
          }
        })
      }
    },
    nameValidator (value) {
      this.validation.name = value.toString().length >= 2 ? '' : this.$t('treasury.banks.validators.name')
    },
    accountNumberValidator (value) {
      this.validation.account_number = this.$validator('regex.treasury.bank.accountNumber').test(value) ? '' : this.$t('treasury.banks.validators.accountNumber')
    },
    cardNumberValidator (value) {
      const card = value.toString().replaceAll('-', '')
      if (typeof card === 'undefined'
        || card === null
        || card.length !== 16) {
        this.validation.card_number = this.$t('treasury.banks.validators.cardNumber')
      } else {
        this.validation.card_number = ''
      }
    },
    shabaNumberValidator (value) {
      this.validation.shaba_number = this.$validator('regex.treasury.bank.shabaNumber').test(value) ? '' : this.$t('treasury.banks.validators.shebaNumber')
    }
  },
  watch: {
    'new_bank': {
      handler (val, oldVal) {
        this.$store.dispatch('setPageChanges')
      },
      deep: true
    },
    'new_bank.name': {
      handler (val, oldVal) {
        this.nameValidator(val)
      }
    },
    'new_bank.account_number': {
      handler (val, oldVal) {
        this.accountNumberValidator(val)
      }
    },
    'new_bank.card_number': {
      handler (val, oldVal) {
        this.cardNumberValidator(val)

        // this.new_bank.card_number = this.addDash(this.new_bank.card_number)
        if (this.new_bank.card_number.length > 16) {
          this.new_bank.card_number = this.new_bank.card_number.toString().substr(0, 16)
        }
      }
    },
    'new_bank.shaba_number': {
      handler (val, oldVal) {
        this.shabaNumberValidator(val)

        if (this.new_bank.shaba_number.length > 24) {
          this.new_bank.shaba_number = this.new_bank.shaba_number.toString().substr(0, 24)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.insert-bank-page {

  &.in-modal {
    box-shadow: none;

    .vx-card__body {
      padding: 0.5rem;
    }
  }

  .dir-rtl {

    input {
      direction: rtl;
    }
  }
}
</style>
