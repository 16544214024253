<template>
  <div class="insert-fast-receive">

    <div class="insert-receive-fields">
      <div class="insert-receive-field">
        <custom-validate-input :value="newDocument.user.name"
                               :classes="{'w-full': true}"
                               :label="`${$t('treasury.receive.labels.user')}`"
                               align="right"
                               disabled/>
      </div>

      <div class="insert-receive-field mt-3">
        <span class="label">{{ $t('treasury.receive.labels.receiveType') }}</span>
        <custom-select :default="types[0]"
                       :options="types"
                       v-model="type"
                       @suggest:selected="handleTypeSelected"/>
      </div>

            <div class="insert-receive-field mt-3"
                 v-if="type.value === 1 && checkUserPermissions('cash.show')">
              <span class="label">{{ $t('treasury.receive.labels.cash') }}</span>
              <custom-select :default="newDocument.cash"
                             :options="cashes"
                             v-model="newDocument.cash"/>
            </div>

            <div class="insert-receive-field mt-3"
                 v-if="type.value === 2 && checkUserPermissions('bank.show')">
              <span class="label">{{ $t('treasury.receive.labels.bank') }}</span>
              <custom-select :default="newDocument.bank"
                             :options="banks"
                             v-model="newDocument.bank"/>
            </div>

            <div class="insert-receive-field mt-3"
                 v-if="type.value === 3 && checkUserPermissions('payment_gateway.show')">
              <span class="label">{{ $t('treasury.receive.labels.paymentGateway') }}</span>
              <custom-select :default="newDocument.paymentGateway"
                             :options="paymentGateways"
                             v-model="newDocument.paymentGateway"/>
            </div>
    </div>

    <div class="insert-receive-fields mt-3">
      <div class="insert-receive-field">
        <custom-price-input :label="`${$t('treasury.receive.labels.price')}`"
                            :classes="{'w-full': true}"
                            :auto-focus="true"
                            v-model="newDocument.price"
                            @pressEnter="handleMoneyFieldEnter"/>
      </div>
    </div>

    <div class="insert-receive-fields mt-3" v-if="type.value === 3">
      <div class="insert-receive-field">
        <custom-validate-input :label="`${$t('treasury.receive.labels.transactionsReferenceId')}`"
                               :classes="{'w-full': true}"
                               id="fastReceive"
                               :regex="$validator('regex.treasury.receive.transactionId')"
                               @pressEnter="insertDocument"
                               v-model="newDocument.transactionsReferenceId"/>
      </div>
    </div>

    <div class="insert-receive-fields wallet mt-2" v-if="newDocument.user.balance">
      <label class="price-label">{{ $t('treasury.receive.labels.balance') }}</label>
      <span class="content" :class="newDocument.user.balance.value > 0 ? 'text-danger' : 'text-success'">
        {{ addComma(Math.abs(newDocument.user.balance.value)) }}
        {{ $t('currency') }}
      </span>
    </div>

    <vs-button v-show="false" id="fastReceiveBTN" @click="insertDocument"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import {getPaymentGateways} from '../../../../../http/requests/paymentGateways'
  import {getBanks} from '../../../../../http/requests/banks'
  import {getCashBoxes} from '../../../../../http/requests/cashBoxes'
  import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
  import CustomSelect from '../../../../../components/customSelect/customSelect'
  import CustomPriceInput from '../../../../../components/customInput/customPriceInput'
  import {insertReceive} from '../../../../../http/requests/documents'
  import {addComma, checkUserPermissions} from '../../../../../assets/js/functions'
  import {getUser} from "../../../../../http/requests/users/users";

  export default {
    name: 'insertFastReceive',
    components: {CustomPriceInput, CustomSelect, CustomValidateInput},
    props: {
      user: {},
      price: {},
      subject: '',
      userTransactions: {}
    },
    data() {
      return {
        types: [
          {
            label: '-',
            value: 0
          },
          // {
          //   label: this.$t('treasury.receive.receiveTypes.cash'),
          //   value: 1
          // },
          // {
          //   label: this.$t('treasury.receive.receiveTypes.bank'),
          //   value: 2
          // },
          // {
          //   label: this.$t('treasury.receive.receiveTypes.paymentGateway'),
          //   value: 3
          // }
        ],
        type: {
          label: this.$t('treasury.receive.receiveTypes.paymentGateway'),
          value: 3
        },
        cashes: [],
        banks: [],
        paymentGateways: [],
        selectedPaymentGateway: {},
        newDocument: {
          price: {
            isValid: true,
            value: 0
          },
          transactionsReferenceId: {
            value: ''
          },
          user: {
            name: {},
            id: 0,
            balance: {
              value: 0
            },
            wallet: 0
          },
          cash: {
            label: '-',
            value: 1
          },
          bank: {
            label: '-',
            value: 1
          },
          paymentGateway: {
            label: '-',
            value: 1
          }
        }
      }
    },
    created() {
      if (checkUserPermissions('cash.show')) {
        this.types.push({
            label: this.$t('treasury.receive.receiveTypes.cash'),
            value: 1
          })
        this.getCashes()
      }
      if (checkUserPermissions('bank.show')) {
        this.types.push({
            label: this.$t('treasury.receive.receiveTypes.bank'),
            value: 2
          })
        this.getBanks()
      }
      if (checkUserPermissions('payment_gateway.show')) {
        this.types.push({
            label: this.$t('treasury.receive.receiveTypes.paymentGateway'),
            value: 3
          })
        this.getPaymentGateways()
      }
      // this.getCashes()
      if (this.types.length > 0) {
        this.type = this.types[0]
      }
      this.newDocument.user = {
        id: this.user.id,
        name: {
          value: this.user.name.value,
          isValid: true
        },
        balance: {
          value: '',
          isValid: ''
        },
      }

      this.newDocument.price = {
        value: this.price,
        isValid: true
      }
      if (this.userTransactions) {
        this.newDocument.user.balance = this.userTransactions
      } else {
        getUser(this.user.id).then(response => {
          this.newDocument.user.balance.value = response.data.data.balance || 0
          this.newDocument.user.wallet = response.data.data.balance
        })
      }
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      handleTypeSelected() {
        switch (this.type.value) {
        case 1:
          if (checkUserPermissions('cash.show')) {
            this.getCashes()
          }
          break

        case 2:
          if (checkUserPermissions('bank.show')) {
            this.getBanks()
          }
          break

        case 3:
          if (checkUserPermissions('payment_gateway.show')) {
            this.getPaymentGateways()
          }
          break
        }
      },
      getBanks() {
        this.banks = []
        getBanks().then(response => {
          const banks = response.data.data
          banks.forEach((bank) => {
            this.banks.push({
              label: bank.name,
              value: bank.id
            })
          })
          this.newDocument.bank = this.banks[0]
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      },
      getPaymentGateways() {
        this.paymentGateways = []
        getPaymentGateways(1, [/*'type=2'*/]).then(response => {
          const paymentGateways = response.data.data
          paymentGateways.forEach((paymentGateway) => {
            this.paymentGateways.push({
              label: paymentGateway.title,
              value: paymentGateway.id
            })
          })

          this.newDocument.paymentGateway = this.paymentGateways[0]
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      },
      getCashes() {
        this.cashes = []
        getCashBoxes().then(response => {
          const cashes = response.data.data
          cashes.forEach((cash) => {
            this.cashes.push({
              label: cash.name,
              value: cash.id
            })
          })

          this.newDocument.cash = this.cashes[0]
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      },
      addComma(value) {
        return addComma(value)
      },
      handleMoneyFieldEnter () {
        const trackingInput = window.document.getElementById('fastReceive')
        if (trackingInput) {
          trackingInput.focus()
        } else {
          this.insertDocument()
        }
      },
      insertDocument() {
        const receive = {
          user_id: this.newDocument.user.id,description: this.$t('treasury.receive.insert.suggests.description', {
            user: this.newDocument.user.name.value || this.$t('users.labels.user'),
            subject: this.subject
          }),
          price: (this.newDocument.price.value || 0).toString().split(',').join('')
        }

        switch (this.type.value) {
          case 1:
            if (this.newDocument.cash.value !== 0) receive.cash_id = this.newDocument.cash.value
            break

          case 2:
            if (this.newDocument.bank.value !== 0) receive.bank_id = this.newDocument.bank.value
            break

          case 3:
            if (this.newDocument.paymentGateway.value !== 0) {
              receive.payment_gateway_id = this.newDocument.paymentGateway.value
              receive.transaction_reference_id = this.newDocument.transactionsReferenceId.value
            }
            break
        }

      if (parseInt(receive.price) === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidPrice'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (parseInt(receive.price) < this.price) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.lessPrice', {
            price: this.addComma(this.price),
            currency: this.$t('currency')
          }),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.type.value === 3 && !this.newDocument.transactionsReferenceId.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidTransactionsReferenceId'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.type.value === 1 && this.newDocument.cash.value === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidCash'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.type.value === 2 && this.newDocument.bank.value === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidBank'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.type.value === 3 && this.newDocument.paymentGateway.value === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidPaymentGateway'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      insertReceive(receive).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.receive.insert.notifications.insert.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })

          this.$store.dispatch('helper/changeDocument')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {

            const error_mapper = {
              'name': this.$t('treasury.receive.insert.notifications.parseError.name'),
              'price': this.$t('treasury.receive.insert.notifications.parseError.price'),
              'transaction_reference_id': this.$t('treasury.receive.insert.notifications.parseError.referenceId'),
              'payment_gateway_id': this.$t('treasury.receive.insert.notifications.parseError.paymentGateway'),
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('treasury.receive.insert.notifications.insert.error'),
                  time: 2400,
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .insert-fast-receive {
    min-height: 220px;

    .insert-receive-fields {

      .insert-receive-field {
        position: relative;

        span.label {
          font-size: 12px;
          position: absolute;
          padding: 0 5px;
          left: 7px;
          top: -10px;
          z-index: 12000;
        }

        .useral-custom-element-select {

          .selected {
            height: 35px;
            line-height: 35px;
            font-size: 13px;
          }

          .items {
            z-index: 12100;

            div {
              font-size: 13px;
              height: 35px;
              line-height: 35px;
            }
          }
        }
      }

      &.wallet {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-radius: 0.5rem;
      }

      &:first-child {
        background: #cecece22;
        border: 1px solid #cecece;
        border-radius: .5rem;
        padding: 0 10px;

        span.label, .custom-validate-input .input-label {
          backdrop-filter: sepia(1);
        }
      }
    }
  }
</style>
