<template>
  <div class="insert-access-group">
    <vs-row>
      <vs-col>
        <span class="text-sm useral-text-colors text-blue--300">{{ $t('setting.access.insert.labels.name') }}<span class="text-danger">*</span></span>
      </vs-col>

      <vs-col>
        <vs-input class="w-full"
                  :danger="validation.name.length > 0"
                  :danger-text="validation.name"
                  v-model="newAccessGroup.name"/>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col>
        <span class="text-sm useral-text-colors text-blue--300">{{ $t('setting.access.insert.labels.access') }}</span>
        <span class="text-xs text-danger block w-full"> {{validation.permissions}}</span>
      </vs-col>

      <crud-tree v-for="(permission, permission_index) in permissions"
                 :key="permission_index"
                 class="w-full"
                 :first="true"
                 :options="options"
                 :tree="permission"
                 :hide-options="true"
                 :limit="limit ? limit.access : []"
                 @child:selected="removeDuplicated"
                 v-model="newAccessGroup.access"/>
      <!--<vs-col>
        <div class="access-box"
             v-for="(access, access_index) in accesses"
             :key="`access-${access_index}`">
          <vs-divider v-if="access_index > 0"
                      class="mt-2 mb-1"/>

          <accesses-tree :show-options-label="true"
                         :access="accesses[access_index]"
                         v-model="newAccessGroup.access[access.field]"/>
        </div>
      </vs-col>-->
    </vs-row>

    <vs-button id="saveBTN" class="useral-action-button" @click="insertData">{{ $t('setting.access.insert.labels.insert') }}</vs-button>
  </div>
</template>

<script>
import axios from 'axios'
import CustomSelect from '@/components/customSelect/customSelect'
import AccessesTree from '@/components/accessesTree/accessesTree'
import {editRole, getPermissions, insertRole} from '@/http/requests/roles'
import accessesList from '@/views/admin/settings/access/insert/newAccessesList.json'
import {hideLoading, showLoading} from "../../../../../assets/js/functions";

export default {
  name: 'insertAccessGroup',
  metaInfo () {
    return {
      title: this.$t('setting.access.insert.title')
    }
  },
  components: {
    AccessesTree,
    CustomSelect
  },
  props: {
    parentId: {
      type: Number,
      default: 0
    },
    accessGroup: {},
    limit: {}
  },
  data () {
    return {
      accessGroupCopy: this.accessGroup ? JSON.parse(JSON.stringify(this.accessGroup)) : {},
      newAccessGroup: {
        name: '',
        access: []
      },
      permissions: [],
      accesses: accessesList,
      options: {
        type: 'insertAccessList'
      },
      validation: {
        name: '',
        permissions: ''
      },
      actions: [
        {
          toolbar: [
            {
              id: 'saveBTN',
              i18n: 'setting.access.actions.save',
              color: 'success'
            }
          ]
        }
      ]
    }
  },
  created () {
    this.getPermissions()
  },
  mounted () {
    // this.$store.dispatch('updateNavbarActions', this.actions[0])
    /*this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')*/
    // this.newAccessGroup.access = this.access[0]


    if (this.accessGroup && Object.keys(this.accessGroup).length > 0) {
      this.newAccessGroup = this.accessGroup
      // this.selectParent(this.accesses)
      this.removeDuplicated()
    }
  },
  methods: {
    getPermissions () {
      showLoading()

      getPermissions().then(response => {
        hideLoading()

        const permissions = response.data.data
        permissions.forEach(permission => {
          if (this.limit && this.limit.access.length > 0 && this.limit.access.indexOf(permission.id) >= 0) {
            this.permissions.push(this.createTree(permission, true))
          }
        })
      }).catch(() => {
        hideLoading()
      })
    },
    createTree (permission) {
      const data = {
        parent: {
          id: permission.id,
          title: permission.name,
          slug: permission.slug
        },
        children: [],
        options: {
          insertEnable: false,
          updateEnable: false,
          deleteEnable: false,
          selectable: true
        }
      }

      if (permission.children) {
        permission.children.forEach((child) => {
          if (this.limit && this.limit.access.length > 0 && this.limit.access.indexOf(child.id) >= 0) {
            data.children.push(this.createTree(child))
          }
        })
      }

      return data
    },
    checkNameValidation () {
      this.validation.name = this.$validator('regex.accessGroup.name').test(this.newAccessGroup.name) ? '' : this.$t('setting.access.insert.validators.name')
      if (this.newAccessGroup.name.length === 0) {
        this.validation.name = this.$t('setting.access.insert.validators.nameCantEmpty')
      }
    },
    checkPermissionsValidation () {
      this.validation.permissions = this.newAccessGroup.access.length > 0 ? '' : this.$t('setting.access.insert.validators.access')
    },
    removeDuplicated () {
      const uniqueArray = this.newAccessGroup.access.filter((item, index, array) => {
        return array.indexOf(item) === index
      })
      this.newAccessGroup.access = uniqueArray
    },
    selectParent (accesses) {
      accesses.children.forEach((access) => {
        let selectedChild = 0
        accesses.children.forEach((child) => {
          if (this.newAccessGroup.access.indexOf(child.parent.id) > -1) {
            selectedChild++
          }
          if (child.children.length > 0) {
            this.selectParent(child)
          }
        })

        if (selectedChild === accesses.children.length && accesses.children.length > 0) {
          this.newAccessGroup.access.push(accesses.parent.id)
        }
      })
    },
    insertData () {
      this.checkNameValidation()
      this.checkPermissionsValidation()

      const role = {
        'name': this.newAccessGroup.name
      }

      if (this.parentId) {
        role.parent_id = this.parentId
      }

      const permissions = this.newAccessGroup.access
      /*Object.keys(this.newAccessGroup.access).forEach((key) => {
        this.newAccessGroup.access[key].access.forEach((permission) => {
          permissions.push(permission)
        })
      })*/
      if (permissions.length > 0) {
        const permissions_list = []
        permissions.forEach((permission) => {
          if (this.limit.access.indexOf(permission) > -1) {
            permissions_list.push(permission)
          }
        })
        role.permissions = permissions_list
      }

      if (this.accessGroup && Object.keys(this.accessGroup).length > 0) {
        role.id = this.accessGroup.id
        /*if (this.accessGroupCopy.name === role.name)
          delete role.name*/
        delete role.parent_id
        if (!this.validation.name && !this.validation.permissions) {

          editRole(role).then(res => {
            this.$emit('update')
            this.$store.dispatch('helper/changeRole')

            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('setting.access.notifications.edit.success'),
              time: 2400,
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather'
            })

          })
            .catch(error => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('setting.access.notifications.edit.error'),
                  color: 'danger',
                  time: 2400,
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
              }
            })
        }

      } else {
        if (!this.validation.name && !this.validation.permissions) {
          insertRole(role).then(res => {
            this.$emit('insert')
            this.$store.dispatch('helper/changeRole')

            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('setting.access.notifications.insert.success'),
              time: 2400,
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather'
            })

          })
            .catch(error => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('setting.access.notifications.insert.error'),
                  color: 'danger',
                  time: 2400,
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
              }
            })
        }
        // this.$router.push({name: 'accessGroupsList'})
      }
    }
  },
  watch: {
    'newAccessGroup.name': {
      handler (val, oldVal) {
        this.checkNameValidation()
      },
      deep: true
    },
    'newAccessGroup.access': {
      handler (val, oldVal) {
        this.checkPermissionsValidation()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
