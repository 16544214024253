<template>
  <div class="custom-input custom-number-input">
    <label :class="[{'is-not-valid': !value.isValid}, classes || {}]" :style="getStyles">
      <span class="input-label" :class="{'is-focus': number.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>
      <input type="text"
             :class="[{'disabled': disabled}]"
             @input="handleInputValue"
             inputmode="numeric"
             :placeholder="placeholder || ''"
             :disabled="disabled"
             v-model="number.value">
    </label>
    <span class="invalid-text-helper" v-if="!value.isValid && this.value.value.length > 0" >
      {{ invalidText || this.$t('customNumberInput.invalidText') }}
    </span>
  </div>
</template>

<script>
import {addComma} from '@/assets/js/functions'

export default {
  name: 'customNumberInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelPlaceholder: {
      type: String,
      default: ''
    },
    isEmpty: {
      type: Boolean,
      default: false
    },
    invalidText: {
      type: String,
      default: () => { return '' }
    },
    hasComma: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    maxLength: {
        type: Number,
        default: () => { return 0 }
    },
    classes: {
      type: Object,
      default () {
        return {}
      }
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      number: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        // styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty) {
      this.number.isValid = true
    }
  },
  methods: {
    handleInputValue () {
      if (this.hasComma) this.addComma()
      else this.number.value = this.number.value.toString().replace(/\D/g, '')
      this.validateValue()
      this.$emit('input', {value: this.number.value.toString().replaceAll(',', ''), isValid: this.number.isValid})
    },
    validateValue () {
      if (this.isEmpty) {
        this.number.isValid = true
      } else {
        this.number.isValid = this.number.value.toString().length > 0
      }
    },
    addComma () {
      this.number.value = addComma(this.number.value)
    },
    initValues () {
      this.number.value = this.hasComma ? addComma(this.value.value) : this.value.value.toString().replace(/\D/g, '')
      this.validateValue()
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    },

    'number.value': {
        handler (val, prev) {
            if (this.maxLength > 0 && val.length >= this.maxLength) {
                this.number.value = prev
                this.$emit('input', {value: this.number.value.toString().replaceAll(',', ''), isValid: this.number.isValid})
            }
        }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-number-input {
  position: relative;
  margin: 15px 0;

  label {
    display: block;
    padding: 6px 10px 6px 5px;
    border: 1px solid #cecece;
    border-radius: .5rem;
    backdrop-filter: sepia(1);
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      input {
        color: #b00000;
      }
    }

    span {
      position: absolute;
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
      top: 7px;

      &.is-focus {
        font-size: 12px;
        background: #ffffff;
        top: -10px;
      }
    }

    input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none !important;
      direction: rtl;

      &.disabled {
        opacity: 0.75;
      }
    }
  }

  .invalid-text-helper {
    font-size: 11px;
    color: #b00000;
  }
}
</style>
