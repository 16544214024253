<template>
  <div class="insert-character">
    <custom-validate-input :label="$t('characters.labels.name')"
                           :classes="{'w-full': true}"
                           align="right"
                           rtl
                           :invalid-text="$t('characters.validators.nameHelper')"
                           v-model="character.name"/>

<!--      <custom-validate-input :label="$t('characters.labels.reservableDate')"-->
<!--                             :placeholder="currentDate"-->
<!--                             :classes="{'w-full': true}"-->
<!--                             :regex="dateRegex"-->
<!--                             v-model="character.reservableDate"/>-->
<!--      <date-picker type="date" v-model="character.reservableDate.value" :min="currentDate"/>-->

    <div class="input-field-item character-input">
      <label class="input-field-label">{{ $t('characters.labels.reservableDate') }}</label>
      <div v-for="(item, key) in reservableDateOptions" class="radio-item">
        <label :for="`reservableDateRadio${key}`">{{ item.label }}</label>
        <input :id="`reservableDateRadio${key}`" type="radio" :key="key" name="role" :value="item.value"
               v-model="character.reservableDate.value"/>
      </div>
    </div>

    <vs-button v-show="false"
               id="insertCharacterBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment-jalaali'
  import DatePicker from 'vue-persian-datetime-picker'
  import CustomValidateInput from "../../../../components/customInput/customValidateInput";
  import {insertCharacter} from "../../../../http/requests/characters";
  import CustomNumberInput from "../../../../components/customInput/customNumberInput";
  import {getTimeFromServer} from "../../../../assets/js/functions";
  import CustomSelect from "../../../../components/customSelect/customSelect";

  export default {
    name: "insertCharacter",
    components: {CustomSelect, CustomNumberInput, CustomValidateInput, DatePicker},
    data() {
      return {
        nameRegex: this.$validator('regex.character.name'),
        dateRegex: this.$validator('regex.date'),
        currentDate: '',
        reservableDateOptions: [
          {
            label: this.$t('characters.labels.disable'),
            value: 0
          },
          {
            label: `1 ${this.$t('characters.labels.week')}`,
            value: 7
          },
          {
            label: `2 ${this.$t('characters.labels.week')}`,
            value: 14
          },
          {
            label: `1 ${this.$t('characters.labels.month')}`,
            value: 30
          },
        ],
        character: {
          name: {
            value: '',
            isValid: true
          },
          reservableDate: {
            value: 7,
            isValid: true
          }
        }
      }
    },
    mounted () {
      this.currentDate = moment(getTimeFromServer()).format(this.$validator('moment.date'))
    },
    methods: {
      sendData() {
        if (this.character.name.isValid && parseInt(this.character.reservableDate.value || '0') >= 0) {
          const character = {
            name: this.character.name.value,
            reservable_date: this.character.reservableDate.value

          }
          insertCharacter(character).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('characters.notifications.insert.success'),
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400,
              color: 'success'
            })
            this.$emit('insert')

          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              const error_mapper = {
                'name': this.$t('characters.notifications.parseError.name'),
                'reservable_days': this.$t('characters.notifications.parseError.reservableDays')
              }

              switch (error.response.status) {
                case 422:
                  Object.keys(error.response.data.errors).forEach((error_key) => {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('characters.notifications.insert.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
              }
            }
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('characters.notifications.parseError.name'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  .insert-character {
    .input-field-item {
      position: relative;
      border: 1px solid #cdcdcd;
      border-radius: 0.4rem;
      padding: 5px 10px;
      margin: 15px 0;
      display: flex;
      justify-content: space-around;
      min-height: 35px;
      flex-wrap: wrap;

      &.invalid {
        border-color: #b00000;

        .input-field-label {
          color: #b00000;
        }
      }

      .input-field-label {
        position: absolute;
        font-size: 12px;
        top: -10px;
        left: 8px;
        background-color: #ffffff;
        padding: 0 5px;
      }

      .radio-item {
        display: flex;
        align-items: center;
        width: max-content;
        direction: rtl;

        label {
          direction: ltr;
          margin-left: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.character-input {
        justify-content: space-between;

        .radio-item {
          justify-content: flex-end;
          width: 25%;
        }
      }
    }

    .date-picker-item {
      border: 0 !important;
      border-radius: 0.4rem;
      position: relative;

      .custom-input {
        margin: 0;
      }

      .date-picker-label {
        font-size: 12px;
        position: absolute;
        top: -10px;
        left: 5px;
        padding: 0 5px;
        background-color: #ffffff;
        z-index: 1;
      }

      .vpd-input-group {
        width: 20px !important;
        position: absolute;
        top: 6px;
        left: 2px;

        .vpd-icon-btn {
          opacity: 1 !important;
          height: 25px !important;
          width: 20px !important;
          background-color: inherit !important;

          svg {
            fill: #000000;
            overflow: visible !important;
          }
        }

        .form-control {
          border: 0;
          width: 100%;
          display: none;
          line-height: 35px;
        }
      }
    }
  }
</style>
