<template>
  <div class="users-list fixed-draggable-dynamic-table-wrapper-height semi-box-border-y">

    <draggable-dynamic-table ref="customersListTable"
                             :in-modal="roleId > 0"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getUsers()"
                             @row:clicked="showUser"
                             v-model="data"/>

    <!-- insert new user prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertNewUserPromptStatus"
      @close="insertNewUserPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertUserBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ this.$t('users.user.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertNewUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <insert-user :in-modal="true" />
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- show user details prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showUserPromptStatus"
      @close="showUserPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="editUserPromptStatus = true">
              <custom-icon icon="EDIT" color="warning"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ this.$t('users.user.profile.dynamicTitle', {name: selectedUser.group}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <user-profile :user-id="selectedUser.id" @edited="reloadTable"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- edit user prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editUserPromptStatus"
      @close="editUserPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('EditUserBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ this.$t('users.user.edit.dynamicTitle', {name: selectedUser.group}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <edit-user :user-id="selectedUser.id || this.$route.params.id"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>


    <!-- show group list prompt -->
    <vs-prompt
            class="medium-prompt p-0"
            :buttons-hidden="true"
            title=""
            :active.sync="showGroupListPromptStatus"
            @close="showGroupListPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertNewCharacter')">
              <custom-icon icon="PLUS" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('characters.select.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showGroupListPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <characters-list />
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="insertNewUser" v-show="false" @click="insertNewUserPromptStatus = true"/>
    <button id="showGroupList" v-show="false" @click="showGroupListPromptStatus = true"/>
  </div>
</template>

<script>
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {getUsers} from '@/http/requests/users/users'
import {checkUserPermissions, getAvatarUrl, getStaticFileUrl} from '../../../../assets/js/functions'
import InsertUser from '@/views/admin/customers/insert/insertUser'
import EditUser from '../edit/editUser'
import UserProfile from '../profile/userProfile'
import CustomIcon from '../../../../components/customIcon/customIcon'
import CharactersList from "../../characters/list/charactersList";
import {getCharacters} from "../../../../http/requests/characters";

export default {
  name: 'usersList',
  metaInfo () {
    return {
      title: this.$t('users.user.list.title')
    }
  },
  components: {
    CharactersList,
    CustomIcon,
    UserProfile,
    EditUser,
    InsertUser,
    DraggableDynamicTable
  },
  props: {
    roleId: 0
  },
  data () {
    return {
      requestSent: false,
      insertNewUserPromptStatus: false,
      showGroupListPromptStatus: false,
      showUserPromptStatus: false,
      editUserPromptStatus: false,
      loadingTimer: 0,
      options: {
        id: 'customersListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'status',
          i18n: 'users.table.header.status',
          width: 'calc(100% / 10)',
          minWidth: 130,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('users.genderTypes.all'),
              value: -1
            },
            /*{
              label: this.$t('users.statusTypes.noSignUp'),
              value: 0
            },
            {
              label: this.$t('users.statusTypes.signUp'),
              value: 1
            },*/
            {
              label: this.$t('users.statusTypes.confirmPhone'),
              value: 3
            },
            {
              label: this.$t('users.statusTypes.complete'),
              value: 4
            }
          ]
        },
        {
          field: 'registryDate',
          i18n: 'users.table.header.registryDate',
          width: 'calc(100% / 10)',
          minWidth: 150,
          align: 'center',
          filter: true,
          filterType: 'date',
          sortable: true,
          filterRange: true
          // locked: true
        },
        {
          field: 'group',
          i18n: 'users.table.header.group',
          width: 'calc(100% / 10)',
          minWidth: 150,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: 'همه',
              value: 0
            }
          ]
        },
        {
          field: 'creator',
          i18n: 'users.table.header.creator',
          width: 'calc((100% / 10) * 2)',
          minWidth: 200,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'maxDebt',
          i18n: 'users.table.header.maxDebt',
          width: 'calc((100% / 10))',
          minWidth: 150,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
        },
        {
          field: 'balance',
          i18n: 'users.table.header.balance',
          width: 'calc((100% / 10))',
          minWidth: 150,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 3
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 4
            }
          ],
        },
        {
          field: 'phoneNumber',
          i18n: 'users.table.header.phoneNumber',
          width: 'calc((100% / 10))',
          minWidth: 150,
          align: 'center',
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'name',
          i18n: 'users.table.header.name',
          width: 'calc((100% / 10) * 2)',
          minWidth: 200,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'gender',
          i18n: 'users.table.header.gender',
          width: 'calc(100% / 10)',
          minWidth: 100,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('users.genderTypes.all'),
              value: 0
            },
            {
              label: this.$t('users.genderTypes.man'),
              value: 1
            },
            {
              label: this.$t('users.genderTypes.woman'),
              value: 2
            }
          ]
        },
        {
          field: 'image',
          i18n: 'users.table.header.image',
          // locked: true,
          width: '90px',
          minWidth: 90,
          align: 'center'
        },
        {
          field: 'row',
          i18n: 'users.table.header.row',
          width: '50px',
          minWidth: 50,
          align: 'center',
          // locked: true,
          // sortable: true,
          footer: {
            /*type: 'auto-counter'*/
          }
        }
      ],
      data: [],
      filters: [this.roleId > 0 ? `role=${this.roleId}` : ''],
      sorts: ['order[0]=created_at,desc'],
      selectedUser: {},
      statusTypes: {
        0: this.$t('users.statusTypes.noSignUp'),
        1: this.$t('users.statusTypes.signUp'),
        3: this.$t('users.statusTypes.confirmPhone'),
        4: this.$t('users.statusTypes.complete'),
      },
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: 'insertNewUser',
              icon: 'icon-plus',
              iconPack: 'feather',
              color: 'success',
              permission: 'user.create'
            },
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: {name: 'customersTrash'},
              type: 'link',
              icon: 'icon-trash',
              iconPack: 'feather',
              permission: 'user.delete'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {

    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    const group_index = this.columnsLabel.map((e) => {
      return e.field
    }).indexOf('group')
    if (group_index > -1) {
      if (checkUserPermissions('character.show')) {
        getCharacters(1).then(response => {
          const characters = response.data.data
          characters.forEach(character => {
            this.columnsLabel[group_index].filterTypes.push({
              label: character.name,
              value: character.id
            })
          })
        })
      } else {
        // this.columnsLabel[group_index].splice(group_index, 1)
      }
    }

    this.getUsers()
  },
  methods: {
    getUsers () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.customersListTable && this.data.length === 0) {
              this.$refs.customersListTable.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.customersListTable && this.data.length > 0) {
              this.$refs.customersListTable.loadMoreStatus = 'Loading'
            }

            getUsers(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              const users = res.data.data
              users.forEach((user) => {

                this.data.push({
                  row: this.data.length + 1,
                  id: user.id,
                  registryDate: user.created_at,
                  gender: user.gender === 1 ? this.$t('users.genderTypes.man') : this.$t('users.genderTypes.woman'),
                  phoneNumber: user.phone_number ? user.phone_number : '',
                  firstName: user.name || '',
                  lastName: user.family || '',
                  name: user.name || user.family ? `${ user.name || '' } ${  user.family || '' }` : this.$t('users.user.labels.unknown'),
                  balance: {
                    type: 'price',
                    value: Math.abs(user.balance) || 0,
                    classes: user.balance === 0 ? 'text-primary' : user.balance < 0 ? 'text-success' : 'text-danger'
                  },
                  maxDebt: {
                    type: 'price',
                    value: Math.abs(user.max_debt),
                  },
                  creator: user.creator ? user.creator.role && user.creator.role.id === 1 ? this.$t('users.user.labels.bySystem') : `${user.creator.name || ''} ${user.creator.family || ''}` : this.$t('users.user.labels.bySystem'),
                  image: {
                    type: 'img',
                    src: user.avatar ? getAvatarUrl(user.avatar) : user.gender === 1 ? getStaticFileUrl('/images/man-avatar.png') : getStaticFileUrl('/images/woman-avatar.png'),
                    class: 'user-avatar'
                  },
                  group: user.character ? user.character.name : '',
                  nationalCode: user.national_code ? user.national_code : '',
                  status: this.statusTypes[user.status]
                })
              })

              this.page = res.data.pagination.current_page + 1
              if (res.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('row')
                this.columnsLabel[row_index].footer.value = res.data.pagination.total
              }

              if (this.$refs.customersListTable) this.$refs.customersListTable.loadMoreStatus = ''

              this.requestSent = false
            })
              .catch((error) => {
                if (this.$refs.customersListTable) this.$refs.customersListTable.loadMoreStatus = 'Danger'

                this.requestSent = false
              })
          } else {

            this.requestSent = false
          }
        }, 400)
      }
    },
    showUser (user) {
      this.selectedUser = user
      this.showUserPromptStatus = true
    },
    setFilters (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'accessGroup':
          if (filters[key].search !== '') filters_list.push(`role=${  filters[key].search}`)
          break

        case 'gender':
          if (filters[key].search.value > 0) filters_list.push(`gender=${  filters[key].search.value }`)
          break

        case 'group':
          if (filters[key].search.value > 0) filters_list.push(`character=${  filters[key].search.value }`)
          break

        case 'phoneNumber':
          if (filters[key].search !== '') filters_list.push(`phone_number=${  filters[key].search}`)
          break

        case 'balance':
          if (filters[key].search !== '') filters_list.push(`balance=${  parseInt(filters[key].search) * -1},${filters[key].type.id}`)
          break

        case 'maxDebt':
          if (filters[key].search !== '') filters_list.push(`max_debt=${  filters[key].search},${filters[key].type.id}`)
          break

        case 'creator':
          let search = filters[key].search
          if (search === 'سیستم' || search === 'سیستمی') {
            search = 'system'
          }
          if (filters[key].search !== '') filters_list.push(`creator=${ search}`)
          break

        case 'company':
          if (filters[key].search !== '') filters_list.push(`company=${  filters[key].search}`)
          break

        case 'name':
          if (filters[key].search !== '') filters_list.push(`full_name=${  filters[key].search}`)
          break

        case 'registryDate':
          if (filters[key].search.length > 0) filters_list.push(`created_at=${  filters[key].search.join('_')}`)
          break

          case 'status':
            if (filters[key].search.value > -1) filters_list.push(`status=${  filters[key].search.value }`)
            break
        }
      })
      if (this.roleId > 0) {
        filters_list.push(`role=${this.roleId}`)
      }

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getUsers()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'maxDebt':
          sorts_list.push(`order[0]=max_debt,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'balance':
          sorts_list.push(`order[0]=virtual_balance,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'phoneNumber':
          sorts_list.push(`order[0]=phone_number,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'company':
          sorts_list.push(`order[0]=company,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'registryDate':
          sorts_list.push(`order[0]=created_at,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'group':
          sorts_list.push(`order[0]=character_id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'gender':
          sorts_list.push(`order[0]=gender,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'status':
          sorts_list.push(`order[0]=status,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getUsers()
    },
    consoleLog () {
      // console.log(event)
    },
    reloadTable () {
      this.data = []
      this.page = 1
      this.endedList = false
      this.getUsers()
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.usersChanged': {
      handler (val) {
        if (val) {
          this.page = 1
          this.endedList = false
          // this.filters = ['characters=1']
          if (this.roleId > 0) {
            this.filters.push(`role=${this.roleId}`)
          }
          this.sorts = ['order[0]=created_at,desc']
          this.data = []
          this.insertNewUserPromptStatus = false
          this.editUserPromptStatus = false
          this.showUserPromptStatus = false
          this.getUsers()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  .users-list {
    .draggable-dynamic-table {
      .user-avatar {
        height: 50px;
        width: 50px;
        object-fit: cover;
      }
    }
  }
</style>
