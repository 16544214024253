<template>
  <div class="characters-list fixed-draggable-dynamic-table-wrapper-height"
       :class="$route.name !== 'characters' ? 'in-modal' : ''">
    <draggable-dynamic-table ref="charactersListTable"
                             :in-modal="$route.name !== 'characters'"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @row:clicked="showCharacter"
                             @load:more="getCharacters"/>


    <!-- show insert character prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showInsertCharacterPromptStatus"
      @close="showInsertCharacterPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertCharacterBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('characters.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showInsertCharacterPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-character @insert="handleReloadTable"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- show edit character prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showEditCharacterPromptStatus"
      @close="showEditCharacterPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertCharacterBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('characters.edit.dynamicTitle', {name: selectedCharacter.name}) }}
          </vs-col>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showEditCharacterPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <edit-character :selected-character="selectedCharacter" @edit="handleReloadTable"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- show character profile prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showCharacterProfilePromptStatus"
      @close="showCharacterProfilePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="checkUserPermissions('character.update')" @click="showEditCharacterPromptStatus = true">
              <custom-icon icon="EDIT" color="warning"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('characters.profile.dynamicTitle', {name: selectedCharacter.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showCharacterProfilePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <character-profile :selected-character="selectedCharacter"
                               @showUsersList="showCharacterUsersListPromptStatus = true"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- show character users prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showCharacterUsersListPromptStatus"
      @close="showCharacterUsersListPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="checkUserPermissions('user.set_character')" @click="handleClick('insertCharacterUserList')">
              <custom-icon icon="PLUS" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('characters.profile.dynamicTitle', {name: selectedCharacter.name || ''}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showCharacterUsersListPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <character-user-list :character-id="selectedCharacter.id"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>


    <button id="insertNewCharacter" v-show="false" @click="showInsertCharacterPromptStatus = true"/>
  </div>
</template>

<script>
  import moment from 'moment-jalaali'
  import {getCharacters} from "../../../../http/requests/characters"
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import InsertCharacter from "../insert/insertCharacter";
  import CharacterUserList from "../users/list/characterUserList";
  import CharacterProfile from "../profile/characterProfile";
  import EditCharacter from "../edit/editCharacter";
  import {checkUserPermissions} from "../../../../assets/js/functions";

  export default {
    name: "charactersList",
    metaInfo() {
      return {
        title: this.$t('characters.list.title')
      }
    },
    components: {EditCharacter, CharacterProfile, CharacterUserList, InsertCharacter, CustomIcon},
    data() {
      return {
        showInsertCharacterPromptStatus: false,
        showCharacterUsersListPromptStatus: false,
        showCharacterProfilePromptStatus: false,
        showEditCharacterPromptStatus: false,
        selectedCharacter: {},
        requestSent: false,
        loadingTimer: 0,
        options: {
          id: 'charactersListTable',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'reservableDate',
            i18n: 'characters.table.header.reservableDate',
            width: 'calc(100% / 4)',
            minWidth: 120,
            align: 'center',
          },
          {
            field: 'createdDate',
            i18n: 'characters.table.header.createdDate',
            width: 'calc(100% / 4)',
            minWidth: 120,
            align: 'center',
          },
          {
            field: 'name',
            i18n: 'characters.table.header.name',
            width: 'calc((100% / 4) * 2)',
            minWidth: 150,
          },
          {
            field: 'row',
            i18n: 'characters.table.header.row',
            width: '50px',
            minWidth: 50,
            align: 'center',
            // locked: true,
            // sortable: true,
            footer: {
              /*type: 'auto-counter'*/
            }
          }
        ],
        data: [],
        filters: [],
        sorts: ['order[0]=created_at,desc'],
        page: 1,
        endedList: false,
        actions: [
          {
            toolbar: [
              {
                id: 'insertNewCharacter',
                icon: 'icon-plus',
                iconPack: 'feather',
                color: 'success',
                permission: 'character.create'
              }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                // i18n: 'draggableDynamicTable.actions.print',
                icon: 'icon-printer',
                iconPack: 'feather'
              },
              {
                id: 'downloadTable',
                // i18n: 'draggableDynamicTable.actions.download',
                icon: 'icon-download',
                iconPack: 'feather'
              },
              {
                id: {name: 'charactersTrash'},
                type: 'link',
                icon: 'icon-trash',
                iconPack: 'feather',
                permission: 'character.delete'
              },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created() {
      if (this.$route.name === 'characters') {
        setTimeout(() => {
          this.$store.dispatch('updateNavbarActions', this.actions[0])
        }, 100)
      }
      this.getCharacters()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getCharacters() {
        if (!this.requestSent) {
          this.requestSent = true

          clearTimeout(this.loadingTimer)
          this.loadingTimer = setTimeout(() => {
            if (!this.endedList) {
              if (this.$refs.charactersListTable && this.data.length === 0) {
                this.$refs.charactersListTable.loadMoreStatus = 'FirstLoad'
              } else if (this.$refs.charactersListTable && this.data.length > 0) {
                this.$refs.charactersListTable.loadMoreStatus = 'Loading'
              }

              getCharacters(this.page, this.filters, this.sorts).then((res) => {
                this.endedList = res.data.data.length === 0
                const characters = res.data.data
                characters.forEach((character) => {
                  this.data.push({
                    row: this.data.length + 1,
                    id: character.id,
                    name: character.name || '',
                    createdDate: character.created_at || '',
                    usersCount: character.users_count || 0,
                    reservableDate: {
                      value: character.reservable_date || '0',
                      type: 'price',
                      currency: this.$t('characters.labels.day')
                    },
                  })
                })

                this.page = res.data.pagination.current_page + 1
                if (res.data.pagination.current_page === 1) {
                  const row_index = this.columnsLabel.map((e) => {
                    return e.field
                  }).indexOf('row')
                  this.columnsLabel[row_index].footer.value = res.data.pagination.total
                }

                if (this.$refs.charactersListTable) this.$refs.charactersListTable.loadMoreStatus = ''

                this.requestSent = false
              })
                .catch(() => {
                  if (this.$refs.charactersListTable) this.$refs.charactersListTable.loadMoreStatus = 'Danger'

                  this.requestSent = false
                })
            } else {

              this.requestSent = false
            }
          }, 400)
        }
      },
      showCharacter(row) {
        this.selectedCharacter = row

        if (this.$route.name === 'characters') {
          this.showCharacterProfilePromptStatus = true
        } else {
          this.showCharacterUsersListPromptStatus = true
        }
      },
      handleReloadTable() {
        this.data = []
        this.showInsertCharacterPromptStatus = false
        this.showEditCharacterPromptStatus = false
        this.showCharacterProfilePromptStatus = false
        this.endedList = false
        this.page = 1
        setTimeout(() => {
          this.getCharacters()
        }, 100)
      },

      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .characters-list {
    &.in-modal {
      height: 100%;
    }
  }
</style>
